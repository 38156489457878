import { Component, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngxs/store';

import { RedirectToSignedDocument, ViewSignedDocument } from 'src/modules/dashboard/ngxs/dashboard.actions';
import { Spinner } from 'src/modules/shared/classes/spinner.class';

@Component({
  selector: 'rs-resume-terms-and-agreement',
  templateUrl: 'resume-terms-and-agreement.component.html',
  styleUrls: ['resume-terms-and-agreement.component.scss']
})
export class ResumeTermsAndAgreementComponent extends Spinner implements OnInit, OnDestroy {

  constructor(
    public store: Store
  ) {
    super(store);
  }

  public ngOnInit(): void {
    this.dispatchActions();
  }

  public goToDocuSign(): void {
    this.store.dispatch(new RedirectToSignedDocument())
  }

  private dispatchActions(): void {
    this.store.dispatch(new ViewSignedDocument());
  }

  public ngOnDestroy(): void {
    this.hideSpinner();
  }

}
