import { BusinessEntityStatuses } from '../enums';
import { Address } from './address.model';
import { BankingInformation } from './banking-information.model';
import { CompanyOwner } from './company-owner.model';
import { LegalEntityInformationData } from './forms/legal-entity-information.model';
import { IndividualOwner } from './individual-owner.model';
import { SalesInformation } from './sales-information.model';

export class BusinessEntity {

  public businessInformation: BusinessInformation;
  public name: string;
  public url: string;

  constructor(legalEntityInformationData: LegalEntityInformationData, legalAddress: Address, businessAddress: Address, legalIsDifferentWithBusiness: boolean) {
    this.businessInformation = new BusinessInformation(legalEntityInformationData, legalAddress, businessAddress, legalIsDifferentWithBusiness);
    this.name = legalEntityInformationData.name;
    this.url = legalEntityInformationData.url;
  }

}

export class BusinessInformation {

  public businessAddress: Address;
  public legalAddress: Address;
  public category: string;
  public legalEntityName: string;
  public sein: string;
  public tin: string;

  constructor(legalEntityInformationData: LegalEntityInformationData, legalAddress: Address, businessAddress: Address, legalIsDifferentWithBusiness: boolean) {
    this.businessAddress = new Address(legalIsDifferentWithBusiness ? businessAddress : legalAddress);
    this.category = legalEntityInformationData.category;
    this.legalAddress = new Address(legalAddress);
    this.legalEntityName = legalEntityInformationData.legalEntityName;
    this.sein = legalEntityInformationData.sein;
    this.tin = legalEntityInformationData.tin;
  }

}

export interface BusinessEntityReduced {
  id: string;
  name: string;
  status: string;
}

export interface BusinessEntityExtended {
  id: string;
  bankingInformation: BankingInformation;
  businessInformation: BusinessInformation;
  createdAt: string;
  hubspot: Hubspot;
  leaderContact: Contact;
  name: string;
  paymentInAdvance: boolean;
  phoneNumber: string;
  principals: Principals;
  salesInformation: SalesInformation;
  servicePackage: ServicePackage;
  status: BusinessEntityStatuses;
  url: string;
}

export interface Hubspot {
  dealFields: any;
  dealStage: string;
}

export interface Contact {
  email: string;
  firstName: string;
  jobPosition: string;
  lastName: string;
  legalAddress: Address;
  password: string;
  phone: string;
}

export interface Principals {
  individual: IndividualOwner[];
  companies: CompanyOwner[];
}

export interface ServicePackage {
  baseFees: any;
  cost: string;
  hasMenuRSGo: boolean;
  includesAnalytics: boolean;
  includesDeliveryAndTakeout: boolean;
  includesHumanResources: boolean;
  includesInventoryManagement: boolean;
  includesPOSSolution: boolean;
  includesPayments: boolean;
  includesRSGoOrders: boolean;
  includesReservationsWaitlist: boolean;
  includesSalesReport: boolean;
  isVisibleRSGo: boolean;
  name: string;
  products: any;
}
