import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Authentication } from 'src/modules/sign-in/models/authentication.model';
import { ENDPOINTS } from '../constants/endpoints';
import { AuthenticationData } from '../models/forms/authentication.model';
import { IdResponse } from '../models/id-response.model';
import { SignUpData } from '../models/forms/sign-up.model';
import { ApiService } from './api.service';
import { RecoverPasswordData } from '../models/reset-password.model';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private apiService: ApiService
  ) {}

  public recoverPassword(recoverPassword: RecoverPasswordData): Observable<void> {
    return this.apiService.post(ENDPOINTS.RECOVER_PASSWORD, recoverPassword);
  }

  public resetPassword(username: string): Observable<void> {
    return this.apiService.post(ENDPOINTS.RESET_PASSWORD, {username , client: 'REGP'});
  }

  public refreshToken(refreshToken: string): Observable<AuthenticationData> {
    return this.apiService.post(ENDPOINTS.REFRESH_TOKEN, {refreshToken});
  }

  public sendActivationLink(email: string): Observable<void> {
    return this.apiService.post(ENDPOINTS.SEND_ACTIVATION_LINK, {email});
  }

  public signIn(authentication: Authentication): Observable<AuthenticationData> {
    return this.apiService.post(ENDPOINTS.SIGN_IN, authentication);
  }

  public signUp(signUpData: SignUpData): Observable<IdResponse> {
    return this.apiService.post(ENDPOINTS.SIGN_UP, signUpData);
  }

}
