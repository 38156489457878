<div class="rs-set-up-home__wrapper" *ngIf="!started">
  <div class="rs-set-up-home">
    <div class="rs-set-up-home__logo">
      <img src="assets/img/rockspoon-title-white.svg">
    </div>
    <div class="rs-set-up-home__middle">
      <div class="rs-set-up-home__middle-name">{{'devices.main.hello' | translate}} Mario,</div>
      <div class="rs-set-up-home__middle-title">{{'devices.main.congrats' | translate}}</div>
    </div>
    <div class="rs-set-up-home__footer">
      <div class="rs-set-up-home__footer-title">{{'devices.main.set-up' | translate}}</div>
      <div class="rs-set-up-home__footer-actions">
        <button mat-raised-button class="app__button app__button_mobile" color="primary" (click)="startGuide()">{{'buttons.start' | translate}}</button>
      </div>
    </div>
  </div>
</div>
<rs-set-up-guide *ngIf="started"></rs-set-up-guide>
