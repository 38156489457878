import { environment } from 'src/environments/environment';
import { DocumentType } from 'src/modules/owners/components/models/documentType.model';

export const COMMON_CONSTANTS = {
  API_URL: environment.apiUrl,
  DEFAULT_LANGUAGE: 'en'
};

export const HEADERS = {
  KEY: 'key',
  ACCESS_TOKEN: 'access_token'
};

export const BUSINESS_CATEGORIES = [
  'Fine dining',
  'Casual dining',
  'Fast casual',
  'Quick Service',
  'Bar and lounges',
  'Cafe and Bakery'
];

export const DOCUMENT_TYPES: DocumentType[] = [
  {
    key: 'State Driver’s License',
    value: 'DriversLicence',
    requireState: true
  },
  {
    key: 'USA Military ID',
    value: 'UsaMilitaryId',
    requireState: true
  },
  {
    key: 'USA State ID',
    value: 'UsaStateId',
    requireState: true
  },
  {
    key: 'USA Passport',
    value: 'PassportUsa',
    requireState: true
  },
  {
    key: 'Foreign Passport',
    value: 'PassportForeign',
    requireState: false
  },
  {
    key: 'USA Green Card',
    value: 'UsaResidentAlienId',
    requireState: true
  },
  {
    key: 'Student ID',
    value: 'StudentId',
    requireState: true
  },
  {
    key: 'Tribal ID',
    value: 'TribalId',
    requireState: true
  },
  {
    key: 'Canadian Driver`s License',
    value: 'DlCanada',
    requireState: false
  },
  {
    key: 'Mexican Driver`s License',
    value: 'DlMexico',
    requireState: false
  },
  {
    key: 'Other Foreign ID',
    value: 'OtherIdForeign',
    requireState: false
  }
];

export const USControlDialCode = '1';

export const ROUTE_PARAMS = {
  SIGNING_COMPLETE: 'signing_complete'
};

export const ACCOUNT_TYPES = [
  'Checking',
  'Savings'
];

export const DIALOG_SIZES = {
  MIDDLE: '1000px',
  SMALL: '700px'
};

export const CONTACTS = {
  PHONE: '+1 650 898 0221',
  EMAIL: 'support@rockspoon.com'
};
