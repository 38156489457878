import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ENDPOINTS } from '../shared/constants/endpoints';
import { DocuSignModes } from '../shared/enums';
import { BusinessLeader } from '../shared/models/business-leader';
import { DocuSign } from '../shared/models/docu-sign.model';
import { ApiService } from '../shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicantInformationService {

  constructor(
    private apiService: ApiService
  ) {}

  public createBusinessLeader(businessEntityId: string, businessLeader: BusinessLeader): Observable<void> {
    return this.apiService.post(ENDPOINTS.CREATE_BUSINESS_LEADER(businessEntityId), businessLeader);
  }

  public getDocumentSignUrl(businessEntityId: string, mode: DocuSignModes): Observable<DocuSign> {
    return this.apiService.post(ENDPOINTS.DOCUMENT_SIGN(businessEntityId), {mode});
  }

}
