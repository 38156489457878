import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

import { Subject } from 'rxjs';

import { Unsubscribe } from '../../classes/unsubscribe.class';
import { PasswordStrengths } from '../../enums';
import { REGEX } from '../../regular-expressions';

@Component({
  selector: 'rs-password-strength',
  templateUrl: 'password-strength.component.html',
  styleUrls: ['password-strength.component.scss']
})
export class PasswordStrengthComponent extends Unsubscribe implements OnInit {

  @Input() passwordSubject: Subject<string>;

  public passwordStrength: PasswordStrengths;
  public lowerCaseCheckCompleted: boolean;
  public upperCaseCheckCompleted: boolean;
  public numberCheckCompleted: boolean;
  public nineCharactersCheckCompleted: boolean;
  public specialCharacterCompleted: boolean;

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.subscribeTo = this.passwordSubject
      .subscribe((password: string) => {
        this.checkPasswordStrength(password);
        this.changeDetectorRef.markForCheck();
      });
  }

  public isPasswordPresented(): boolean {
    return !!this.passwordStrength;
  }

  public isPasswordWeak(): boolean {
    return this.passwordStrength === PasswordStrengths.Weak;
  }

  public isPasswordModerate(): boolean {
    return this.passwordStrength === PasswordStrengths.Moderate;
  }

  public isPasswordStrong(): boolean {
    return this.passwordStrength === PasswordStrengths.Strong;
  }

  public isPasswordVeryStrong(): boolean {
    return this.passwordStrength === PasswordStrengths.VeryStrong;
  }

  public isPasswordComplete(): boolean {
    return this.passwordStrength === PasswordStrengths.Complete;
  }

  private checkPasswordStrength(password: string): void {
    this.passwordStrength =
      Number(this.lowerCaseCheck(password)) +
      Number(this.upperCaseCheck(password)) +
      Number(this.numberCheck(password)) +
      Number(this.nineCharactersCheck(password)) +
      Number(this.specialCharacterCheck(password));
  }

  private upperCaseCheck(password: string): boolean {
    this.upperCaseCheckCompleted = !!password.match(REGEX.UPPER_CASE);

    return this.upperCaseCheckCompleted;
  }

  private lowerCaseCheck(password: string): boolean {
    this.lowerCaseCheckCompleted = !!password.match(REGEX.LOWER_CASE);

    return this.lowerCaseCheckCompleted;
  }

  private nineCharactersCheck(password: string): boolean {
    this.nineCharactersCheckCompleted = !!password.match(REGEX.NINE_CHARACTERS);

    return this.nineCharactersCheckCompleted;
  }

  private numberCheck(password: string): boolean {
    this.numberCheckCompleted = !!password.match(REGEX.NUMBER);

    return this.numberCheckCompleted;
  }

  private specialCharacterCheck(password: string): boolean {
    this.specialCharacterCompleted = !!password.match(REGEX.SPECIAL_SYMBOL);

    return this.specialCharacterCompleted;
  }

}
