export const MICRO_SERVICES = {
  AUTHENTICATION: 'authentication',
  USER_PROFILE: 'user-profile',
  MERCHANT_PROFILE: 'merchant-profile'
}

export const ENDPOINTS = {
  SIGN_IN: `${MICRO_SERVICES.AUTHENTICATION}/v1/user/login`,
  SIGN_UP: `${MICRO_SERVICES.USER_PROFILE}/v1/profile/join`,
  SEND_ACTIVATION_LINK: `${MICRO_SERVICES.USER_PROFILE}/v1/profile/validate`,
  BUSINESS_ENTITY: `${MICRO_SERVICES.MERCHANT_PROFILE}/v1/business-entity`,
  REFRESH_TOKEN: `${MICRO_SERVICES.AUTHENTICATION}/v1/user/login/refresh`,
  INDIVIDUALS: businessEntityId => `${MICRO_SERVICES.MERCHANT_PROFILE}/v1/business-entity/${businessEntityId}/principal/individual`,
  COMPANIES: businessEntityId => `${MICRO_SERVICES.MERCHANT_PROFILE}/v1/business-entity/${businessEntityId}/principal/company`,
  BANKING_INFORMATION: businessEntityId => `${MICRO_SERVICES.MERCHANT_PROFILE}/v1/business-entity/${businessEntityId}/banking-information`,
  SALES_INFORMATION: businessEntityId => `${MICRO_SERVICES.MERCHANT_PROFILE}/v1/business-entity/${businessEntityId}/sales-information`,
  USER_PROFILE_DATA: `${MICRO_SERVICES.USER_PROFILE}/v1/profile/me`,
  RESET_PASSWORD: `${MICRO_SERVICES.AUTHENTICATION}/v1/recovery/password`,
  CONFIRM_PASSWORD: `${MICRO_SERVICES.AUTHENTICATION}/v1/recovery/password/confirm`,
  DOCUMENT_SIGN: businessEntityId => `${MICRO_SERVICES.MERCHANT_PROFILE}/v1/business-entity/${businessEntityId}/sign`,
  CREATE_BUSINESS_LEADER: businessEntityId => `${MICRO_SERVICES.MERCHANT_PROFILE}/v1/business-entity/${businessEntityId}/business-leader`,
  VALIDATE: businessEntityId => `${MICRO_SERVICES.MERCHANT_PROFILE}/v1/business-entity/${businessEntityId}/validate`,
  PROFILE_ACTIVATION: `${MICRO_SERVICES.USER_PROFILE}/v1/profile/join/validate`,
  RECOVER_PASSWORD: `${MICRO_SERVICES.AUTHENTICATION}/v1/recovery/password/confirm`
};

export const ENDPOINTS_WITHOUT_ACCESS_TOKEN = [
  '/profile/join',
  '/user/login',
  '/profile/validate',
  'recovery/password',
  'assets'
];
