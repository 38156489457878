import { HttpClient } from '@angular/common/http';
import { FormControl, FormGroup } from '@angular/forms';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';


import countries from '../../assets/files/countries.json';
import { ERRORS } from './constants/errors';
import { Error } from './models/error.model';
import { SalesInformation } from './models/sales-information.model';

export const isFormTouched = (form: FormGroup): boolean => {
  return Object.keys(form.controls)
    .map((controlName: string) => {
      if (controlName !== 'country') {
        return form.controls[controlName];
      }
    })
    .some((formControl: FormControl) => {
      if (formControl) {
        return formControl.value;
      }
    });
};

export const getStateByCode = (countryCode: string, stateCode: string) => {
  if (countryCode && stateCode) {
    const country = countries.find(currentCountry => currentCountry.code3 === countryCode);
    const state = country.states.find(currentState => currentState.code === stateCode);

    return state.name;
  }
};

export const getCountryByCode = (code: string) => {
  if (code) {
    return countries.find(country => country.code3 === code).name;
  }
};

export const HttpLoaderFactory = (http: HttpClient) => {
  return new TranslateHttpLoader(http);
};

export const copy = (obj: any) => JSON.parse(JSON.stringify(obj));

export const getErrorTitleByCode = (errorCode: number) => {
  const error: Error = ERRORS.find((currentError: Error) => currentError.code === errorCode);

  return error?.errorTitle;
};

export const getErrorContentByCode = (error: {code: number, message: string}) => {
  const errorDescription: Error = ERRORS.find((currentError: Error) => currentError.code === error.code);

  return errorDescription?.errorContent ? errorDescription?.errorContent : error.message;
};

export const getValidProneNumber = (phoneNumber: string): string => {
  const from = phoneNumber.lastIndexOf('+');

  return phoneNumber.substr(from);
};

export const markFormControlsAsTouched = (forms: FormGroup[]): void => {
  forms.forEach( form => {
    Object.keys(form.controls).forEach(key => {
      form.get(key).markAsTouched();
    });
  })
}

export const convertPriceToApiFormat = (price: number): number => {
  return Number(Number(price).toFixed(2).toString().replace('.', ''));
}

export const convertPriceToStandardView = (price: number): number => {
  return Number(price) / 100 || 0;
}

export const convertSalesInfoToApiFormat= (salesInfo: SalesInformation): SalesInformation => {

  return {
    ...salesInfo,
    monthlyTotalCardRevenue: convertPriceToApiFormat(salesInfo.monthlyTotalCardRevenue),
    monthlyTotalRevenue: convertPriceToApiFormat(salesInfo.monthlyTotalRevenue),
    highestCardTransactionYear: convertPriceToApiFormat(salesInfo.highestCardTransactionYear),
    averageCheck: convertPriceToApiFormat(salesInfo.averageCheck)
  }
}
