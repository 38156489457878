import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { DIALOG_SIZES } from 'src/modules/shared/constants/common';
import { GoToDocusignPopupComponent } from './go-to-docusign.component';

@Directive({
  selector: '[rsOpenGoToDocusign]'
})
export class OpenGoToDocusignPopupDirective {

  @Output() accepted = new EventEmitter<void>();

  @Input() view: boolean;

  constructor(
    private dialog: MatDialog
  ) {}

  @HostListener('click')
  openGoToDocusignPopup(): void {
    const dialogRef = this.dialog.open(GoToDocusignPopupComponent, {
      width: DIALOG_SIZES.MIDDLE,
      data: this.view
    });

    dialogRef.afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.accepted.emit();
        }
      });
  }

}
