import { Injectable } from '@angular/core';

import { Action, State, StateContext } from '@ngxs/store';

import { SetSpinnerVisibility } from './root.actions';

interface RootStateModel {
  spinnerVisibility: boolean;
}

@State<RootStateModel>({
  name: 'root',
  defaults: {
    spinnerVisibility: false
  }
})
@Injectable()
export class RootState {

  @Action(SetSpinnerVisibility)
  setSpinnerVisibility({patchState}: StateContext<RootStateModel>, {spinnerVisibility}: SetSpinnerVisibility) {
    patchState({
      spinnerVisibility
    });
  }

}
