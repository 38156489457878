import { NgModule } from '@angular/core';

import { MatCarouselModule } from '@ngbmodule/material-carousel';

import { MaterialModule } from 'src/modules/shared/modules/material.module';
import { SharedModule } from '../../shared/modules/shared.module';
import { ClickAppComponent } from '../components/click-app/click-app.component';
import { ConnectISPComponent } from '../components/connect-isp/connect-isp.component';
import { QRCodeComponent } from '../components/qr-code/qr-code.component';
import { SetUpGuideComponent } from '../components/set-up-guide/set-up-guide.component';
import { SetUpHomeComponent } from '../components/set-up-home/set-up-home.component';
import { TurnOnMobileComponent } from '../components/turn-on-mobile/turn-on-mobile.component';
import { TurnOnRouterComponent } from '../components/turn-on-router/turn-on-router.component';

@NgModule({
  imports: [
    SharedModule,
    MaterialModule,
    MatCarouselModule.forRoot()
  ],
  declarations: [
    ClickAppComponent,
    ConnectISPComponent,
    QRCodeComponent,
    TurnOnMobileComponent,
    TurnOnRouterComponent,
    SetUpHomeComponent,
    SetUpGuideComponent
  ],
  exports: [
    SetUpHomeComponent
  ]
})
export class SetUpDevicesModule {}
