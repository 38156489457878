import { Component } from '@angular/core';

import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Unsubscribe } from 'src/modules/shared/classes/unsubscribe.class';
import { CONTACTS } from 'src/modules/shared/constants/common';
import { BusinessEntityStatuses } from 'src/modules/shared/enums';
import { SharedDataService } from 'src/modules/shared/services/shared-data.service';
import { GetBusinessEntityStatusById } from '../../ngxs/result.actions';

@Component({
  selector: 'rs-success',
  templateUrl: 'success.component.html'
})
export class SuccessComponent extends Unsubscribe {

  @Select(state => state.result.businessEntityStatus) businessEntityStatus$: Observable<BusinessEntityStatuses>;

  public phone: string = CONTACTS.PHONE;
  public email: string = CONTACTS.EMAIL;
  public backOfficeButtonVisibility: boolean;

  constructor(
    private shredDataService: SharedDataService,
    private store: Store
  ) {
    super();
  }

  ngOnInit() {
    this.dispatchActions();
    this.initSubscriptions();
  }

  public goToBackOffice(): void {
    document.location.replace(environment.backOfficeUrl);
  }

  private dispatchActions(): void {
    this.store.dispatch(new GetBusinessEntityStatusById(this.shredDataService.businessEntityId));
  }

  private initSubscriptions(): void {
    this.subscribeTo = this.businessEntityStatus$
      .subscribe((businessEntityStatus: BusinessEntityStatuses) => {
        if (businessEntityStatus === BusinessEntityStatuses.valid) {
          this.backOfficeButtonVisibility = true;
        }
      })
  }

}
