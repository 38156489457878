import { Injectable } from '@angular/core';

import { Action, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { ClearStepStatuses } from 'src/modules/dashboard/ngxs/dashboard.actions';

import { Spinner } from '../../classes/spinner.class';
import { PAGES } from '../../constants/pages';
import { AuthenticationData } from '../../models/forms/authentication.model';
import { AuthenticationService } from '../../services/authentication.service';
import { RouterService } from '../../services/router.service';
import { SharedDataService } from '../../services/shared-data.service';
import { ShowNotificationsService } from '../../services/show-notifications.service';
import { Logout, RecoverPassword, RefreshSession, ResetPassword, SendActivationLink, SignIn, SignUp } from './authentication.actions';

@State<any>({
  name: 'authentication'
})
@Injectable()
export class AuthenticationState extends Spinner {

  constructor(
    private authenticationService: AuthenticationService,
    private sharedDataService: SharedDataService,
    private routerService: RouterService,
    private showNotificationsService: ShowNotificationsService,
    protected store: Store
  ) {
    super(store);
  }

  @Action(RecoverPassword)
  recoverPassword(stateContext, {recoverPassword}: RecoverPassword) {
    this.showSpinner();

    return this.authenticationService.recoverPassword(recoverPassword)
      .pipe(
        tap({
          next: () => {
            this.showNotificationsService.showSuccessPasswordRecovering();
            this.routerService.navigateToPage(PAGES.SIGN_IN);
          },
          complete: () => this.hideSpinner()
        })
      );
  }

  @Action(ResetPassword)
  resetPassword(stateContext: StateContext<any>, {email}: ResetPassword) {
    this.showSpinner();

    return this.authenticationService.resetPassword(email)
      .pipe(
        tap({
          next: () => this.showNotificationsService.showFollowPasswordLink(),
          complete: () => this.hideSpinner()
        })
      );
  }

  @Action(RefreshSession)
  refreshSession() {
    this.showSpinner();

    return this.authenticationService.refreshToken(this.sharedDataService.authenticationData.refreshToken)
      .pipe(
        tap({
          next: (authenticationData: AuthenticationData) => this.sharedDataService.authenticationData = authenticationData,
          complete: () => this.hideSpinner()
        })
      );
  }

  @Action(Logout)
  logout() {
    this.sharedDataService.clearUserData();
    this.routerService.navigateToPage(PAGES.SIGN_IN);
    this.sharedDataService.clearUserData();
    this.store.dispatch(new ClearStepStatuses());
  }

  @Action(SendActivationLink)
  sendActivationLink(stateContext, {email}: SendActivationLink) {
    this.showSpinner();

    return this.authenticationService.sendActivationLink(email)
      .pipe(
        tap({
          next: () => this.showNotificationsService.showFollowResendActivationLink(),
          complete: () => this.hideSpinner()
        })
      );
  }

  @Action(SignIn)
  signIn(stateContext, {authentication}: SignIn) {
    this.showSpinner();

    return this.authenticationService.signIn(authentication)
      .pipe(
        tap({
          next: (authenticationData: AuthenticationData) => {
            this.sharedDataService.authenticationData = authenticationData;
            this.routerService.navigateToPage(PAGES.DASHBOARD);
          },
          complete: () => this.hideSpinner()
        })
      );
  }

  @Action(SignUp)
  signUp(stateContext, {signUpData}: SignUp) {
    this.showSpinner();

    return this.authenticationService.signUp(signUpData)
      .pipe(
        tap({
          next: () => {
            this.showNotificationsService.showAuthenticationLink();
            this.sharedDataService.signUpEmail = signUpData.email;
            this.routerService.navigateToPage(PAGES.ACTIVATE_YOUR_ACCOUNT);
          },
          complete: () => this.hideSpinner()
        })
      );
  }


}
