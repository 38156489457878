import { NgModule } from '@angular/core';

import { NgxsModule } from '@ngxs/store';

import { AddressFormModule } from '../shared/components/address-form/address-form.module';
import { ControlWithMaskModule } from '../shared/components/control-with-mask/control-with-mask.module';
import { HintModule } from '../shared/components/hint/hint.module';
import { NavigationButtonsModule } from '../shared/components/navigation-buttons/navigation-buttons.module';
import { OwnerModule } from '../shared/components/owner/owner.module';
import { PercentControlModule } from '../shared/components/controls/percent-control/percent-control.module';
import { PhoneControlModule } from '../shared/components/controls/phone-control/phone-control.module';
import { MaterialModule } from '../shared/modules/material.module';
import { SharedModule } from '../shared/modules/shared.module';
import { PipesModule } from '../shared/pipes/pipes.module';
import { CompanyComponent } from './components/company/company.component';
import { IndividualComponent } from './components/individual/individual.component';
import { OwnerActionsComponent } from './components/owner-actions/owner-actions.component';
import { OwnersComponent } from './components/owners/owners.component';
import { OwnersState } from './ngxs/owners.state';
import { ShareWarningPopupModule } from '../shared/components/popups/show-share-warning/share-warning.module';

@NgModule({
  imports: [
    SharedModule,
    MaterialModule,
    NavigationButtonsModule,
    PhoneControlModule,
    HintModule,
    AddressFormModule,
    PipesModule,
    OwnerModule,
    ControlWithMaskModule,
    PercentControlModule,
    ShareWarningPopupModule,
    NgxsModule.forFeature([OwnersState])
  ],
  declarations: [
    OwnersComponent,
    IndividualComponent,
    CompanyComponent,
    OwnerActionsComponent
  ],
  exports: [
    OwnersComponent
  ]
})
export class OwnersModule {

}
