import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { ENDPOINTS } from '../../shared/constants/endpoints';
import { BusinessEntityExtended, BusinessEntityReduced } from '../../shared/models/business-entity.model';
import { UserProfile } from '../../shared/models/user-profile.model';
import { ApiService } from '../../shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private apiService: ApiService
  ) {}

  public activateProfile(token: string): Observable<void> {
    return this.apiService.post(ENDPOINTS.PROFILE_ACTIVATION, {token});
  }

  public getBusinessEntities(): Observable<BusinessEntityReduced[]> {
    // return of([]);
    return this.apiService.get(ENDPOINTS.BUSINESS_ENTITY);
  }

  public submit(businessEntityId: string): Observable<void> {
    return this.apiService.get(ENDPOINTS.VALIDATE(businessEntityId));
  }

  public getBusinessEntityById(businessEntityId: string): Observable<BusinessEntityExtended> {
    return this.apiService.get(`${ENDPOINTS.BUSINESS_ENTITY}/${businessEntityId}`);
  }

  public getUserInformation(): Observable<UserProfile> {
    return this.apiService.get(ENDPOINTS.USER_PROFILE_DATA);
  }

}
