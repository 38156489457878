import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ApplicantInformationComponent } from 'src/modules/applicant-information/components/applicant-information/applicant-information.component';
import { BankingInformationComponent } from 'src/modules/banking-information/component/banking-information.component';
import { DashboardComponent } from 'src/modules/dashboard/components/dashboard/dashboard.component';
import { LegalEntityInformationComponent } from 'src/modules/legal-entity-information/component/legal-entity-information.component';
import { OwnersComponent } from 'src/modules/owners/components/owners/owners.component';
import { SuccessComponent } from 'src/modules/result/components/success/success.component';
import { ResumeComponent } from 'src/modules/resume/components/resume/resume.component';
import { SalesInformationComponent } from 'src/modules/sales-information/component/sales-information.component';
import { AuthenticationGuard } from 'src/modules/shared/guards/auth.guard';
import { BusinessEntityCreatedGuard } from 'src/modules/shared/guards/business-entity-created.guard';
import { BusinessEntityIsReadyGuard } from 'src/modules/shared/guards/business-entity-is-ready.guard';
import { ValidationCompletedGuard } from 'src/modules/shared/guards/validation-completed.guard';
import { LayoutComponent } from '../components/layout/layout.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthenticationGuard],
        children: [
          { path: 'dashboard', component: DashboardComponent, canActivate: [ValidationCompletedGuard] },
          { path: 'legal-entity-information', component: LegalEntityInformationComponent, canActivate: [ValidationCompletedGuard] },
          { path: 'owners-information', component: OwnersComponent, canActivate: [ValidationCompletedGuard, BusinessEntityCreatedGuard] },
          { path: 'banking-information', component: BankingInformationComponent, canActivate: [ValidationCompletedGuard, BusinessEntityCreatedGuard] },
          { path: 'sales-information', component: SalesInformationComponent, canActivate: [ValidationCompletedGuard, BusinessEntityCreatedGuard] },
          { path: 'applicant-information', component: ApplicantInformationComponent, canActivate: [ValidationCompletedGuard, BusinessEntityCreatedGuard] },
          { path: 'resume', component: ResumeComponent, canActivate: [BusinessEntityIsReadyGuard] },
          { path: 'success', component: SuccessComponent, canActivate: [BusinessEntityIsReadyGuard] }
        ]
      }
    ])
  ],
  exports: [
    RouterModule
  ]
})
export class LayoutRoutingModule {}
