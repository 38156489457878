import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/modules/shared.module';
import { EmailComponent } from './component/email.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    EmailComponent
  ],
  exports: [
    EmailComponent
  ]
})
export class EmailModule {}
