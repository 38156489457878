import { Injectable } from '@angular/core';

import { CACHE } from '../constants/cache';
import { Address } from '../models/address.model';
import { BankingInformation } from '../models/banking-information.model';
import { AuthenticationData } from '../models/forms/authentication.model';
import { LegalEntityInformationData } from '../models/forms/legal-entity-information.model';
import { IndividualData } from '../models/forms/individual.model';
import { SalesInformation } from '../models/sales-information.model';
import { Steps } from '../models/steps.model';
import { UserProfile } from '../models/user-profile.model';
import { LocalStorageService } from './local-storage.service';
import { CompanyData } from '../models/forms/company.model';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  constructor(
    private localStorageService: LocalStorageService
  ) {}

  get newRestaurant(): boolean {
    return this.localStorageService.getCachedData(CACHE.NEW_RESTAURANT);
  }

  set newRestaurant(newRestaurant: boolean) {
    this.localStorageService.cacheData(CACHE.NEW_RESTAURANT, newRestaurant);
  }

  get validationCompleted(): boolean {
    return this.localStorageService.getCachedData(CACHE.VALIDATION_COMPLETED);
  }

  set validationCompleted(validationCompleted: boolean) {
    this.localStorageService.cacheData(CACHE.VALIDATION_COMPLETED, validationCompleted);
  }

  get canISignDocuments(): boolean {
    return this.localStorageService.getCachedData(CACHE.CAN_I_SIGN_DOCUMENTS);
  }

  set canISignDocuments(canISignDocuments: boolean) {
    this.localStorageService.cacheData(CACHE.CAN_I_SIGN_DOCUMENTS, canISignDocuments);
  }

  get userProfile(): UserProfile {
    return this.localStorageService.getCachedData(CACHE.USER_PROFILE);
  }

  set userProfile(userProfile: UserProfile) {
    this.localStorageService.cacheData(CACHE.USER_PROFILE, userProfile);
  }

  get salesInformation(): SalesInformation {
    return this.localStorageService.getCachedData(CACHE.SALES_INFORMATION);
  }

  set salesInformation(salesInformation: SalesInformation) {
    this.localStorageService.cacheData(CACHE.SALES_INFORMATION, salesInformation);
  }

  get bankingInformation(): BankingInformation {
    return this.localStorageService.getCachedData(CACHE.BANKING_INFORMATION);
  }

  set bankingInformation(bankingInformation: BankingInformation) {
    this.localStorageService.cacheData(CACHE.BANKING_INFORMATION, bankingInformation);
  }

  get individualOwners(): IndividualData[] {
    return this.localStorageService.getCachedData(CACHE.INDIVIDUAL_OWNERS);
  }

  set individualOwners(individualOwners: IndividualData[]) {
    this.localStorageService.cacheData(CACHE.INDIVIDUAL_OWNERS, individualOwners);
  }

  get companyOwners(): CompanyData[] {
    return this.localStorageService.getCachedData(CACHE.COMPANY_OWNERS);
  }

  set companyOwners(companyOwners: CompanyData[]) {
    this.localStorageService.cacheData(CACHE.COMPANY_OWNERS, companyOwners);
  }

  get businessEntityId(): string {
    return this.localStorageService.getCachedData(CACHE.BUSINESS_ENTITY_ID);
  }

  set businessEntityId(businessEntityId: string) {
    this.localStorageService.cacheData(CACHE.BUSINESS_ENTITY_ID, businessEntityId);
  }

  get legalAddress(): Address {
    return this.localStorageService.getCachedData(CACHE.LEGAL_ADDRESS);
  }

  set legalAddress(legalAddress: Address) {
    this.localStorageService.cacheData(CACHE.LEGAL_ADDRESS, legalAddress);
  }

  get businessAddress(): Address {
    return this.localStorageService.getCachedData(CACHE.BUSINESS_ADDRESS);
  }

  set businessAddress(businessAddress: Address) {
    this.localStorageService.cacheData(CACHE.BUSINESS_ADDRESS, businessAddress);
  }

  get legalEntityInformation(): LegalEntityInformationData {
    return this.localStorageService.getCachedData(CACHE.LEGAL_ENTITY_INFORMATION);
  }

  set legalEntityInformation(legalEntityInformation: LegalEntityInformationData) {
    this.localStorageService.cacheData(CACHE.LEGAL_ENTITY_INFORMATION, legalEntityInformation);
  }

  get signUpEmail(): string {
    return this.localStorageService.getCachedData(CACHE.SIGN_UP_EMAIL);
  }

  set signUpEmail(email: string) {
    this.localStorageService.cacheData(CACHE.SIGN_UP_EMAIL, email);
  }

  get authenticationData(): AuthenticationData {
    return this.localStorageService.getCachedData(CACHE.AUTHENTICATION_DATA);
  }

  set authenticationData(authenticationData: AuthenticationData) {
    this.localStorageService.cacheData(CACHE.AUTHENTICATION_DATA, authenticationData);
  }

  get steps(): Steps {
    return this.localStorageService.getCachedData(CACHE.STEPS);
  }

  set steps(steps: Steps) {
    this.localStorageService.cacheData(CACHE.STEPS, steps);
  }

  public addOwner<OwnerType>(owner: OwnerType, isIndividual: boolean) {
    let owners: OwnerType[] = this.localStorageService.getCachedData(isIndividual ? CACHE.INDIVIDUAL_OWNERS : CACHE.COMPANY_OWNERS);

    if (owners?.length) {
      owners.push(owner);
    } else {
      owners = [owner];
    }

    isIndividual ? this.individualOwners = owners as any[] : this.companyOwners = owners as any[];
  }

  public clearUserData(): void {
    this.localStorageService.clearLocalStorage();
  }

}
