import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import countries from '../../../../assets/files/countries.json';
import { copy } from '../../helpers';
import { Country, State } from '../../models/country.model';

@Component({
  selector: 'rs-address-form',
  templateUrl: 'address-form.component.html',
  styleUrls: ['address-form.component.scss']
})
export class AddressFormComponent implements OnInit {

  @Input() rsFormGroup: FormGroup;

  public countries: Country[] = copy(countries);
  public states: State[];

  ngOnInit() {
    this.reactOnCountryChange();
  }

  public reactOnCountryChange(): void {
    const { country, state } = this.rsFormGroup.controls;
    const countryName = country.value;

    if (countryName) {
      const { states } = this.countries.find(currentCountry => currentCountry.name === countryName);

      if (states.length) {
        state.enable();
        state.setValidators(Validators.required);
      } else {
        state.disable();
        state.clearValidators();
      }

      this.states = copy(states.sort((firstState: State, secondState: State) => firstState.name.localeCompare(secondState.name)));
    }
  }

}
