<div class="rs-resume-terms-and-agreement">
  {{ 'term-of-use.document-was-signed' | translate }}
  <button
    mat-button
    color="primary"
    class="app__link"
    rsOpenGoToDocusign
    (accepted)="goToDocuSign()"
    [view]="true"
  >
    {{'common.here' | translate}}
  </button>
  {{ 'term-of-use.document-was-signed_rest' | translate }}
</div>
