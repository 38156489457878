import { NgModule } from '@angular/core';

import { NgxCurrencyModule } from 'ngx-currency';
import { NgxsModule } from '@ngxs/store';

import { ControlWithMaskModule } from '../shared/components/control-with-mask/control-with-mask.module';
import { HintModule } from '../shared/components/hint/hint.module';
import { NavigationButtonsModule } from '../shared/components/navigation-buttons/navigation-buttons.module';
import { PercentControlModule } from '../shared/components/controls/percent-control/percent-control.module';
import { currencyConfig } from '../shared/configs/currency';
import { DirectivesModule } from '../shared/directives/directives.module';
import { MaterialModule } from '../shared/modules/material.module';
import { SharedModule } from '../shared/modules/shared.module';
import { SalesInformationComponent } from './component/sales-information.component';
import { SalesInformationState } from './ngxs/sales-information.state';

@NgModule({
  imports: [
    SharedModule,
    MaterialModule,
    NavigationButtonsModule,
    HintModule,
    NgxCurrencyModule.forRoot(currencyConfig),
    ControlWithMaskModule,
    PercentControlModule,
    DirectivesModule,
    NgxsModule.forFeature([SalesInformationState])
  ],
  declarations: [
    SalesInformationComponent
  ],
  exports: [
    SalesInformationComponent
  ]
})
export class SalesInformationModule {}
