import { Directive, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[rsClickAndHold]'
})
export class ClickAndHoldDirective {

  @Output() holding = new EventEmitter<boolean>();

  public value: boolean;

  constructor(
    private elementRef: ElementRef
  ) {}

  @HostListener('mousedown')
  reactOnClick(): void {
    this.value = true;
    this.emit();
  }

  @HostListener('mouseup')
  reactOnMouseUp(): void {
    this.value = false;
    this.emit();
  }

  @HostListener('mouseout')
  reactOnMouseLeave(): void {
    this.value = false;
    this.emit();
  }

  private emit(): void {
    this.holding.emit(this.value);
  }

}
