import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PasswordControlModule } from '../shared/components/controls/password-control/password-control.module';
import { FooterModule } from '../shared/components/footer/footer.module';

import { MaterialModule } from '../shared/modules/material.module';
import { SharedModule } from '../shared/modules/shared.module';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SignInComponent } from './components/sign-in/sign-in.component';

@NgModule({
  imports: [
    SharedModule,
    MaterialModule,
    RouterModule,
    PasswordControlModule,
    FooterModule
  ],
  declarations: [
    SignInComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent
  ],
  exports: [
    SignInComponent
  ]
})
export class SignInModule {}
