import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Observable } from 'rxjs';

import { Select, Store } from '@ngxs/store';

import { Unsubscribe } from './../../../shared/classes/unsubscribe.class';
import { copy, getValidProneNumber } from 'src/modules/shared/helpers';
import { Country, State } from 'src/modules/shared/models/country.model';
import nationalities from '../../../../assets/files/nationalities.json';
import countries from '../../../../assets/files/countries.json';
import { DOCUMENT_TYPES } from 'src/modules/shared/constants/common';
import { SharedDataService } from 'src/modules/shared/services/shared-data.service';
import { IndividualOwner } from 'src/modules/shared/models/individual-owner.model';
import { REGEX } from 'src/modules/shared/regular-expressions';
import { IndividualData } from 'src/modules/shared/models/forms/individual.model';
import { AddOwner, RemoveNewOwner } from '../../ngxs/owners.actions';
import { OwnerTypes } from 'src/modules/shared/enums';
import { MatSelectChange } from '@angular/material/select';
import { DocumentType } from '../models/documentType.model';
import { mustBeInFuture, mustBeInPast } from 'src/modules/shared/constants/datepicker-filters';

@Component({
  selector: 'rs-individual',
  templateUrl: 'individual.component.html',
  styleUrls: ['individual.component.scss']
})
export class IndividualComponent extends Unsubscribe implements OnInit {

  @Select(state => state.owners.newOwner) newOwner$: Observable<IndividualData>;

  @Output() cancelClick = new EventEmitter<void>();

  @Input() addMyselfAsOwner: boolean;
  @Input() selectedOwner: IndividualOwner;
  @Input() index: number;

  public nationalities: string[] = copy(nationalities);
  public countries: Country[] = copy(countries);
  public individualForm: FormGroup;
  public documentTypes: DocumentType[] = copy(DOCUMENT_TYPES);
  public states: State[] = copy(countries[0].states);
  public mustBeInPast = mustBeInPast;
  public mustBeInFuture = mustBeInFuture;

  constructor(
    private formBuilder: FormBuilder,
    private sharedDataService: SharedDataService,
    private store: Store
  ) {
    super();
  }

  public ngOnInit(): void {
    this.initForm();
    this.preFillUserInformation();
    this.initSubscriptions();
  }

  public reactOnChooseDocumentType(event: MatSelectChange): void {
    const documentType = this.documentTypes.find(documentType => documentType.value === event.value);

    if (documentType.requireState) {
      (this.individualForm.controls.documentDetails as FormGroup).controls.issuingState.setValidators(Validators.required);
    } else {
      (this.individualForm.controls.documentDetails as FormGroup).controls.issuingState.clearValidators();
    }
    (this.individualForm.controls.documentDetails as FormGroup).controls.issuingState.updateValueAndValidity();
  }

  public initSubscriptions(): void {
    this.subscribeTo = this.newOwner$
      .subscribe( newOwner =>  {
        if (newOwner) {
          this.cancelClick.emit();
          this.store.dispatch(new RemoveNewOwner());
        }
      });
  }

  public isAddButtonDisabled(): boolean {
    return this.individualForm.invalid;
  }

  public accept(): void {
    if (this.selectedOwner) {
      this.save();
    } else {
      this.add();
    }
  }

  public reactOnCancelClick(): void {
    this.cancelClick.emit();
  }

  private initForm(): void {
    this.individualForm = this.formBuilder.group({
      dateOfBirth: [this.selectedOwner ? this.selectedOwner.dateOfBirth : '', Validators.required],
      email: [this.selectedOwner ? this.selectedOwner.email : '', [Validators.required, Validators.email, Validators.pattern(REGEX.EMAIL)]],
      firstName: [this.selectedOwner ? this.selectedOwner.firstName : '', Validators.required],
      lastName: [this.selectedOwner ? this.selectedOwner.lastName : '', Validators.required],
      nationality: [this.selectedOwner ? this.selectedOwner.nationality : 'American', Validators.required],
      phoneNumber: [this.selectedOwner ? getValidProneNumber(this.selectedOwner.phoneNumber) : '', Validators.required],
      residingCountry: [this.selectedOwner ? this.selectedOwner.residingCountry : '', Validators.required],
      secondaryNationality: this.selectedOwner ? this.selectedOwner.secondaryNationality : '',
      share: [this.selectedOwner ? this.selectedOwner.share : '', [Validators.min(0), Validators.max(100)]],
      socialSecurityNumber: [this.selectedOwner ? this.selectedOwner.socialSecurityNumber : '', [Validators.required]],
      documentDetails: this.formBuilder.group({
        documentIssuer: [this.selectedOwner ? this.selectedOwner.documentDetails.documentIssuer : '', Validators.required],
        documentType: [this.selectedOwner ? this.selectedOwner.documentDetails.documentType : '', Validators.required],
        expireDate: [this.selectedOwner ? this.selectedOwner.documentDetails.expireDate : '', Validators.required],
        idNumber: [this.selectedOwner ? this.selectedOwner.documentDetails.idNumber : '', Validators.required],
        issueDate: [this.selectedOwner ? this.selectedOwner.documentDetails.issueDate : '', Validators.required],
        issuingState: [this.selectedOwner ? this.selectedOwner.documentDetails.issuingState : '']
      }),
      legalAddress:  this.formBuilder.group({
        address1: [this.selectedOwner ? this.selectedOwner.legalAddress.address1 : '', [Validators.required, Validators.maxLength(31)]],
        address2: [this.selectedOwner ? this.selectedOwner.legalAddress.address2 : '', Validators.maxLength(31)],
        city: [this.selectedOwner ? this.selectedOwner.legalAddress.city : '', Validators.required],
        country: [{value: this.selectedOwner ? this.selectedOwner.legalAddress.country : 'United States', disabled: true}, Validators.required],
        state: [{value: this.selectedOwner ? this.selectedOwner.legalAddress.state : '', disabled: true}],
        zipcode: [this.selectedOwner ? this.selectedOwner.legalAddress.zipcode : '', Validators.required]
      })
    });
  }

  private preFillUserInformation(): void {
    if (this.addMyselfAsOwner) {
      const { firstName, lastName, email, phoneNumber } = this.individualForm.controls;

      firstName.reset(this.sharedDataService.userProfile.firstName);
      lastName.reset(this.sharedDataService.userProfile.lastName);
      email.reset(this.sharedDataService.userProfile.email);
      phoneNumber.reset(this.sharedDataService.userProfile.phone.phoneNumber);
    }
  }

  private createOwner(): IndividualData {
    const individualOwnerInfo = this.individualForm.getRawValue();

    return {
      ...individualOwnerInfo,
      phoneNumber: individualOwnerInfo.phoneNumber.number
    };
  }

  private save(): void {
    const owners = this.sharedDataService.individualOwners;

    owners.splice(this.index, 1, this.createOwner());
    this.sharedDataService.individualOwners = owners;
    this.cancelClick.emit();
  }

  private add(): void {
    const currentOwner = this.createOwner();
    this.store.dispatch(new AddOwner(OwnerTypes.INDIVIDUAL, currentOwner));
  }

}
