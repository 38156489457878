export const CACHE = {
  AUTHENTICATION_DATA: 'authenticationData',
  SIGN_UP_EMAIL: 'signUpEmail',
  LEGAL_ENTITY_INFORMATION: 'legalEntityInformation',
  BUSINESS_ADDRESS: 'businessAddress',
  LEGAL_ADDRESS: 'legalAddress',
  BUSINESS_ENTITY_ID: 'businessEntityId',
  STEPS: 'steps',
  COMPANY_OWNERS: 'companyOwners',
  INDIVIDUAL_OWNERS: 'individualOwners',
  BANKING_INFORMATION: 'bankingInformation',
  SALES_INFORMATION: 'salesInformation',
  USER_PROFILE: 'userProfile',
  CAN_I_SIGN_DOCUMENTS: 'canISignDocuments',
  VALIDATION_COMPLETED: 'validationCompleted',
  NEW_RESTAURANT: 'newRestaurant'
};
