import { Injectable } from '@angular/core';

import { Action, State, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { Spinner } from 'src/modules/shared/classes/spinner.class';
import { SharedDataService } from 'src/modules/shared/services/shared-data.service';
import { BankingInformationService } from '../banking-information.service';
import { UpdateBankingInformation } from './banking-information.actions';

@State({
  name: 'bankingInformation'
})
@Injectable()
export class BankingInformationState extends Spinner {

  constructor(
    private bankingInformationService: BankingInformationService,
    private sharedDataService: SharedDataService,
    protected store: Store
  ) {
    super(store);
  }

  @Action(UpdateBankingInformation)
  updateBankingInformation(stateContext, {bankingInformation}: UpdateBankingInformation) {
    this.showSpinner();

    return this.bankingInformationService.updateBankingInformation(this.sharedDataService.businessEntityId, bankingInformation)
      .pipe(
        tap({
          complete: () => this.hideSpinner()
        })
      );
  }

}
