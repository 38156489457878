export class Phone {

  public countryCode?: string;
  public isDefault?: boolean;
  public isValidated?: boolean;
  public phoneNumber?: string;
  public status?: string;
  public type?: string;

  constructor(phone: PhoneControl) {
    this.countryCode = phone.countryCode;
    if (phone.number.includes(phone.countryCode)) {
      this.phoneNumber = phone.number.substr(phone.number.indexOf(phone.countryCode) + phone.countryCode.length);
    } else {
      this.phoneNumber = phone.number;
    }
    this.type = PhoneTypes.Phone;
  }

}

export interface PhoneControl {
  countryCode: string;
  number: string;
}

export enum PhoneTypes {
  Phone = 'phone'
}
