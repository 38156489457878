import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { SharedDataService } from '../services/shared-data.service';
import { RouterService } from '../services/router.service';
import { PAGES } from '../constants/pages';

@Injectable({
  providedIn: 'root'
})
export class NotAuthenticationGuard implements CanActivate {

  constructor(
    private sharedDataService: SharedDataService,
    private routerService: RouterService
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const authenticationData = this.sharedDataService.authenticationData;

    if (authenticationData) {
      this.redirectToHomePage();

      return false;
    } else {
      return true;
    }
  }

  private redirectToHomePage(): void {
    this.routerService.navigateToPage(PAGES.DASHBOARD);
  }

}
