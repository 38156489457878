import { Address } from './address.model';
import { DocumentDetails } from './document-details.model';
import { IndividualData } from './forms/individual.model';

import * as moment from 'moment';
import { getValidProneNumber } from '../helpers';

export class IndividualOwner {

  public dateOfBirth: string;
  public documentDetails: DocumentDetails;
  public email: string;
  public firstName: string;
  public lastName: string;
  public legalAddress: Address;
  public nationality: string;
  public phoneNumber: string;
  public residingCountry: string;
  public secondaryNationality: string;
  public share: number;
  public socialSecurityNumber: string;
  public socialSecurityType?: string;

  constructor(individualData: IndividualData) {
    this.dateOfBirth = moment(individualData.dateOfBirth).format('YYYY-MM-DD');
    this.documentDetails = new DocumentDetails(individualData.documentDetails);
    this.email = individualData.email;
    this.firstName = individualData.firstName;
    this.lastName = individualData.lastName;
    this.legalAddress = new Address(individualData.legalAddress);
    this.nationality = individualData.nationality;
    this.phoneNumber = getValidProneNumber(individualData.phoneNumber);
    this.residingCountry = individualData.residingCountry;
    this.secondaryNationality = individualData.secondaryNationality;
    this.share = Number(individualData.share);
    this.socialSecurityNumber = individualData.socialSecurityNumber;
    this.socialSecurityType = 'temp';
  }

}
