import { NgModule } from '@angular/core';

import { MaterialModule } from 'src/modules/shared/modules/material.module';
import { SharedModule } from 'src/modules/shared/modules/shared.module';
import { PopupModule } from '../base-popup/popup.module';
import { GoToDocusignPopupComponent } from './go-to-docusign.component';
import { OpenGoToDocusignPopupDirective } from './open-go-to-docusign-popup.directive';

@NgModule({
  imports: [
    SharedModule,
    MaterialModule,
    PopupModule
  ],
  declarations: [
    GoToDocusignPopupComponent,
    OpenGoToDocusignPopupDirective
  ],
  exports: [
    GoToDocusignPopupComponent,
    OpenGoToDocusignPopupDirective
  ]
})
export class GoToDocusignPopupModule {}
