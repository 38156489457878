import { Injectable } from '@angular/core';

import { Action, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { DashboardService } from 'src/modules/dashboard/services/dashboard.service';
import { Spinner } from 'src/modules/shared/classes/spinner.class';
import { BusinessEntityStatuses } from 'src/modules/shared/enums';
import { BusinessEntityExtended } from 'src/modules/shared/models/business-entity.model';
import { GetBusinessEntityStatusById } from './result.actions';

interface ResultStateModel {
  businessEntityStatus: BusinessEntityStatuses;
}

@State<ResultStateModel>({
  name: 'result',
  defaults: {
    businessEntityStatus: null
  }
})
@Injectable()
export class ResultState extends Spinner {

  constructor(
    private dashboardService: DashboardService,
    protected store: Store
  ) {
    super(store);
  }

  @Action(GetBusinessEntityStatusById)
  getBusinessEntityStatusById({patchState}: StateContext<ResultStateModel>, {businessEntityId}: GetBusinessEntityStatusById) {
    this.showSpinner();

    return this.dashboardService.getBusinessEntityById(businessEntityId)
      .pipe(
        tap({
          next: (businessEntity: BusinessEntityExtended) => {
            patchState({
              businessEntityStatus: businessEntity.status
            });
          },
          complete: () => this.hideSpinner()
        })
      )
  }

}
