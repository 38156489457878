<div class="rs-password-control">
  <mat-form-field>
    <mat-label>{{placeholder}}</mat-label>
    <input
      [formControl]="rsPasswordControl"
      (focus)="setPasswordStrengthVisibility(true)"
      (blur)="setPasswordStrengthVisibility(false)"
      matInput
      [placeholder]="placeholder"
      (input)="reactOnPasswordInput()"
      [type]="passwordVisibility ? 'text' : 'password'"
      (keydown.enter)="reactOnPressEnter()"
    >
    <button tabindex="-1" [class.rs-password-control__highlighted-icon]="passwordVisibility" rsClickAndHold (holding)="reactOnChangePasswordVisibility($event)" mat-icon-button matSuffix>
      <mat-icon>visibility</mat-icon>
    </button>
    <rs-password-strength [passwordSubject]="passwordSubject" @scale *ngIf="passwordStrengthVisibility"></rs-password-strength>
  </mat-form-field>
</div>
