<rs-popup>
  <div class="rs-profile-not-active">
    <div class="rs-profile-not-active__title">
      {{'popups.profile-not-active.title' |  translate}} <button tabindex="-1" mat-button color="primary" class="app__link" rsEmail>support@rockspoon.com</button>.
    </div>
    <div class="rs-profile-not-active__actions">
      <button tabindex="-1" mat-raised-button color="primary" class="app__button" mat-dialog-close>{{'buttons.ok' | translate}}</button>
    </div>
  </div>
</rs-popup>
