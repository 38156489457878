import { NgModule } from '@angular/core';

import { MaterialModule } from 'src/modules/shared/modules/material.module';
import { SharedModule } from 'src/modules/shared/modules/shared.module';
import { PopupModule } from '../base-popup/popup.module';
import { DeleteOwnerPopupComponent } from './delete-owner.component';
import { DeleteOwnerPopupDirective } from './delete-owner.directive';

@NgModule({
  imports: [
    SharedModule,
    MaterialModule,
    PopupModule
  ],
  declarations: [
    DeleteOwnerPopupComponent,
    DeleteOwnerPopupDirective
  ],
  exports: [
    DeleteOwnerPopupComponent,
    DeleteOwnerPopupDirective
  ]
})
export class DeleteOwnerPopupModule {}
