<div class="rs-company">
  <div class="app__section" [formGroup]="companyForm">
    <div class="app__controls">
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.legal-entity-name' | translate}}*</mat-label>
        <input formControlName="businessName" matInput placeholder="{{'placeholders.legal-entity-name' | translate}}*">
      </mat-form-field>
    </div>
    <div class="app__controls">
      <rs-phone-control [rsPhoneControl]="companyForm.controls.phone"></rs-phone-control>
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.email' | translate}}*</mat-label>
        <input formControlName="email" matInput placeholder="{{'placeholders.email' | translate}}*">
      </mat-form-field>
    </div>
    <div class="app__controls">
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.owners-first-name' | translate}}*</mat-label>
        <input formControlName="firstName" matInput placeholder="{{'placeholders.owners-first-name' | translate}}*">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.owners-last-name' | translate}}*</mat-label>
        <input formControlName="lastName" matInput placeholder="{{'placeholders.owners-last-name' | translate}}*">
      </mat-form-field>
    </div>
    <div class="app__controls">
      <rs-control-with-mask [rsFormControl]="companyForm.controls.tin" [placeholder]="'placeholders.tin'" mask="00-0000000"></rs-control-with-mask>
      <rs-percent-control placeholder="{{'placeholders.share' | translate}}" [rsFormControl]="companyForm.controls.share"></rs-percent-control>
    </div>
  </div>
  <div class="app__section">
    <div class="app__section-title">{{'legal-entity-information.legal-address' | translate}}</div>
    <rs-address-form [rsFormGroup]="companyForm.controls.address"></rs-address-form>
    <div class="app__section-mandatory">*{{'common.mandatory-information' | translate}}</div>
  </div>
  <rs-owner-actions [isEdit]="!!selectedOwner" [isAddButtonDisabled]="isAddButtonDisabled()" (cancelClick)="reactOnCancelClick()" (addClick)="accept()"></rs-owner-actions>
</div>
