export enum PasswordStrengths {
  Weak = 1,
  Moderate = 2,
  Strong = 3,
  VeryStrong = 4,
  Complete = 5
}

export enum StepStatuses {
  Untouched,
  InProgress,
  Done,
  Blocked
}

export enum DocuSignModes {
  view = 'view',
  sign = 'sign'
}

export enum ProfileRoles {
  leader = 'leader',
  normal = 'normal'
}

export enum BusinessEntityStatuses {
  validationFailedKyc = 'validation_failed_kyc',
  validationFailedOfac = 'validation_failed_ofac',
  validationFailedCredit = 'validation_failed_credit',
  validationFailedKycCredit = 'validation_failed_kyc_credit',
  notValidated = 'not_validated',
  valid = 'valid'
}

export enum OwnerTypes {
  INDIVIDUAL = 'individual',
  COMPANY = 'company'
}
