import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngxs/store';

import { CheckStepStatus } from 'src/modules/dashboard/ngxs/dashboard.actions';
import { DashboardState } from 'src/modules/dashboard/ngxs/dashboard.state';
import { Unsubscribe } from 'src/modules/shared/classes/unsubscribe.class';
import { ACCOUNT_TYPES } from 'src/modules/shared/constants/common';
import { PAGES } from 'src/modules/shared/constants/pages';
import { STEPS } from 'src/modules/shared/constants/steps';
import { StepStatuses } from 'src/modules/shared/enums';
import { copy, markFormControlsAsTouched } from 'src/modules/shared/helpers';
import { BankingInformation } from 'src/modules/shared/models/banking-information.model';
import { RouterService } from 'src/modules/shared/services/router.service';
import { SharedDataService } from 'src/modules/shared/services/shared-data.service';
import { UpdateBankingInformation } from '../ngxs/banking-information.actions';

@Component({
  selector: 'rs-banking-information',
  templateUrl: 'banking-information.component.html',
  styleUrls: ['banking-information.component.scss']
})
export class BankingInformationComponent extends Unsubscribe implements OnInit, OnDestroy {

  public bankingInformationForm: FormGroup;
  public accountTypes: string[] = copy(ACCOUNT_TYPES);

  private cachedBankingInformation: BankingInformation;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private routerService: RouterService,
    private sharedDataService: SharedDataService
  ) {
    super();
  }

  ngOnInit() {
    this.getCachedData();
    this.initForm();
    this.subscribeToForm();
  }

  public goToDashboard(): void {
    this.updateBankingInformation(PAGES.DASHBOARD);
  }

  public goToNext(): void {
    this.updateBankingInformation(PAGES.SALES_INFORMATION);
  }

  private getCachedData(): void {
    this.cachedBankingInformation = this.sharedDataService.bankingInformation;
  }

  private updateBankingInformation(page: string): void {
    if (this.bankingInformationForm.valid) {
      this.subscribeTo = this.store.dispatch(new UpdateBankingInformation(new BankingInformation(this.bankingInformationForm.getRawValue())))
      .subscribe(() => this.routerService.navigateToPage(page));
    } else {
      this.routerService.navigateToPage(page);
    }
  }

  private subscribeToForm(): void {
    const isFormTouched = this.store.selectSnapshot(DashboardState.bankingInformationStatus) === StepStatuses.InProgress;

    this.subscribeTo = this.bankingInformationForm.statusChanges
      .subscribe(() => this.store.dispatch(new CheckStepStatus(STEPS.BANKING_INFORMATION, [this.bankingInformationForm])));

    if (isFormTouched) {
      markFormControlsAsTouched([this.bankingInformationForm]);
    }
  }

  private initForm(): void {
    this.bankingInformationForm = this.formBuilder.group({
      accountNumber: [this.cachedBankingInformation ? this.cachedBankingInformation.accountNumber : '', Validators.required],
      accountType: [this.cachedBankingInformation ? this.cachedBankingInformation.accountType : '', Validators.required],
      routingNumber: [this.cachedBankingInformation ? this.cachedBankingInformation.routingNumber : '', Validators.required],
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.sharedDataService.bankingInformation = this.bankingInformationForm.getRawValue();
  }

}
