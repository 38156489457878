import { BusinessLeader } from 'src/modules/shared/models/business-leader';

export class SendInviteToBusinessLeader {
  static readonly type = '[Invite To Business Leader] Send';

  constructor(
    public businessLeader: BusinessLeader
  ) {}
}

export class MakeMeAsBusinessLeader {
  static readonly type = '[Me] Make As Business Leader';
}

export class GetDocumentSignUrl {
  static readonly type = '[Document Sign Url] Get';
}
