import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { PAGES } from '../constants/pages';
import { RouterService } from '../services/router.service';
import { SharedDataService } from '../services/shared-data.service';

@Injectable({
  providedIn: 'root'
})
export class BusinessEntityCreatedGuard implements CanActivate {

  constructor(
    private sharedDataService: SharedDataService,
    private routerService: RouterService
  ) {}

  public canActivate(): boolean {
    const isBusinessEntityCreated = !!this.sharedDataService.businessEntityId;

    if (!isBusinessEntityCreated) {
      this.routerService.navigateToPage(PAGES.LEGAL_ENTITY_INFORMATION);
    }

    return isBusinessEntityCreated;
  }

}
