import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MaterialModule } from 'src/modules/shared/modules/material.module';
import { SharedModule } from 'src/modules/shared/modules/shared.module';
import { ResumeBankingInformationComponent } from './components/resume-banking-information/resume-banking-information.component';
import { ResumeLeadershipInformationComponent } from './components/resume-leadership-information/resume-leadership-information.component';
import { ResumeLegalInformationComponent } from './components/resume-legal-information/resume-legal-information.component';
import { ResumeOwnersInformationComponent } from './components/resume-owners-information/resume-owners-information.component';
import { ResumeSalesInformationComponent } from './components/resume-sales-information/resume-sales-information.component';
import { ResumeTermsAndAgreementComponent } from './components/resume-terms-and-agreement/resume-terms-and-agreement.component';
import { ResumeComponent } from './components/resume/resume.component';
import { OwnerModule } from 'src/modules/shared/components/owner/owner.module';
import { PipesModule } from '../shared/pipes/pipes.module';
import { GoToDocusignPopupModule } from '../shared/components/popups/go-to-docusign/go-to-docusign.module';

@NgModule({
  imports: [
    SharedModule,
    MaterialModule,
    OwnerModule,
    RouterModule,
    PipesModule,
    GoToDocusignPopupModule
  ],
  declarations: [
    ResumeComponent,
    ResumeBankingInformationComponent,
    ResumeLeadershipInformationComponent,
    ResumeLegalInformationComponent,
    ResumeOwnersInformationComponent,
    ResumeSalesInformationComponent,
    ResumeTermsAndAgreementComponent
  ],
  exports: [
    ResumeComponent
  ]
})
export class ResumeModule {}
