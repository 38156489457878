<div class="rs-owners">
  <div class="rs-owners__title app__step-title">{{'titles.owners-information' | translate}}</div>
  <div class="rs-owners__list" *ngIf="!ownersTabsVisibility()">
    <div class="rs-owners__description app__step-subtitle">
      <div>{{'owners.to-comply' | translate}}</div>
      <div class="rs-owners__hint">
        <rs-hint content="{{'owners.if-no-owner' | translate}}"></rs-hint>
      </div>
    </div>
    <div class="rs-owners__content">
      <div class="rs-owners__content_no-content" *ngIf="noContentVisibility()">{{'owners.no-owner' | translate}}</div>
      <div class="rs-owners__content-items" *ngFor="let owner of cachedIndividualOwners;let i = index;">
        <div class="rs-owners__content-item">
          <rs-owner (selectOwner)="selectOwner(owner, i, true)" (deleteClick)="deleteOwner(i, true)" [owner]="owner" [isIndividual]="true"></rs-owner>
        </div>
      </div>
      <div class="rs-owners__content-items" *ngFor="let owner of cachedCompanyOwners;let i = index;">
        <div class="rs-owners__content-item">
          <rs-owner (selectOwner)="selectOwner(owner, i, false)" (deleteClick)="deleteOwner(i, false)" [owner]="owner"></rs-owner>
        </div>
      </div>
      <div class="rs-owners__actions">
        <button mat-raised-button class="app__button app__button_white" (click)="addNewOwner()">{{'owners.add-new' | translate}}</button>
        <button mat-raised-button class="app__button app__button_white" (click)="addNewOwner(true)">{{'owners.add-myself' | translate}}</button>
      </div>
    </div>
    <div class="rs-owners__navigation">
      <rs-navigation-buttons (clickDashboard)="goDashboard()" (clickNext)="goNext()"></rs-navigation-buttons>
    </div>
  </div>
  <div class="rs-owners__tabs" *ngIf="ownersTabsVisibility()">
    <mat-tab-group mat-stretch-tabs>
      <mat-tab *ngIf="individualTabVisibility()" label="{{'owners.individual-owner' | translate}}">
        <rs-individual
          [index]="selectedOwnerIndex"
          [selectedOwner]="selectedOwner"
          (cancelClick)="reactOnCancelClick(false)"
          [addMyselfAsOwner]="addMyselfAsOwner"
        ></rs-individual>
      </mat-tab>
      <mat-tab *ngIf="companyTabVisibility()" label="{{'owners.company-owner' | translate}}">
        <rs-company
          [index]="selectedOwnerIndex"
          [selectedOwner]="selectedOwner"
          (cancelClick)="reactOnCancelClick(false)"
          [addMyselfAsOwner]="addMyselfAsOwner"
        ></rs-company>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
