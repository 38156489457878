import { Injectable } from '@angular/core';

import { Action, State, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { Spinner } from 'src/modules/shared/classes/spinner.class';
import { convertSalesInfoToApiFormat } from 'src/modules/shared/helpers';
import { SharedDataService } from 'src/modules/shared/services/shared-data.service';
import { SalesInformationService } from '../sales-information.service';
import { UpdateSalesInformation } from './sales-information.actions';

@State({
  name: 'salesInformation'
})
@Injectable()
export class SalesInformationState extends Spinner {

  constructor(
    private salesInformationService: SalesInformationService,
    private sharedDataService: SharedDataService,
    protected store: Store
  ) {
    super(store);
  }

  @Action(UpdateSalesInformation)
  updateSalesInformation(stateContext, {salesInformation}: UpdateSalesInformation) {
    this.showSpinner();

    const convertedSalesInformation = convertSalesInfoToApiFormat(salesInformation);
    convertedSalesInformation.newRestaurant = this.sharedDataService.newRestaurant || false;

    return this.salesInformationService.updateSalesInformation(this.sharedDataService.businessEntityId, convertedSalesInformation)
      .pipe(
        tap({
          complete: () => this.hideSpinner()
        })
      );
  }

}
