<div class="rs-sign-in">
  <div class="rs-sign-in__logo">
    <img src="assets/img/rockspoon-title.svg">
  </div>
  <div class="rs-sign-in__title">{{'authentication.login' | translate}}</div>
  <div class="rs-sign-in__form" [formGroup]="signInForm" (keydown.enter)="signIn()">
    <mat-form-field>
      <mat-label>{{'placeholders.email' | translate}}</mat-label>
      <input type="email" formControlName="username" matInput placeholder="{{'placeholders.email' | translate}}">
    </mat-form-field>
    <rs-password-control
      [rsPasswordControl]="signInForm.controls.password"
      placeholder="{{'placeholders.password' | translate}}"
      [passwordStrength]="false"
    ></rs-password-control>
    <button [disabled]="signInForm.invalid" mat-raised-button class="app__button" color="primary" (click)="signIn()">{{'authentication.login' | translate}}</button>
    <button mat-button color="primary" class="app__link" routerLink="/forgot-password">{{'authentication.forgot-your-password' | translate}}</button>
  </div>
  <div class="rs-sign-in__sign-up-link">
    <div class="rs-sign-in__sign-up-link-title">{{'authentication.dont-have-an-account' | translate}}</div>
    <div class="rs-sign-in__sign-up-link-button">
      <button mat-raised-button class="app__button" color="primary" routerLink="/sign-up">{{'authentication.sign-up' | translate}}</button>
    </div>
  </div>
</div>
<rs-footer></rs-footer>
