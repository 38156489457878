import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ENDPOINTS } from '../shared/constants/endpoints';
import { BusinessEntity } from '../shared/models/business-entity.model';
import { IdResponse } from '../shared/models/id-response.model';
import { ApiService } from '../shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class LegalEntityInformationService {

  constructor(
    private apiService: ApiService
  ) {}

  public updateBusinessEntity(businessEntity: BusinessEntity, businessEntityId: string): Observable<IdResponse> {
    return this.apiService.put(`${ENDPOINTS.BUSINESS_ENTITY}/${businessEntityId}`, businessEntity);
  }

  public createBusinessEntity(businessEntity: BusinessEntity): Observable<IdResponse> {
    return this.apiService.post(ENDPOINTS.BUSINESS_ENTITY, businessEntity);
  }

}
