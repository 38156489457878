export const PAGES = {
  ACTIVATE_YOUR_ACCOUNT: '/activate-account',
  SIGN_IN: '/sign-in',
  DASHBOARD: '/dashboard',
  LEGAL_ENTITY_INFORMATION: '/legal-entity-information',
  OWNERS_INFORMATION: '/owners-information',
  BANKING_INFORMATION: '/banking-information',
  SALES_INFORMATION: '/sales-information',
  APPLICANT_INFORMATION: '/applicant-information',
  TERMS_AND_AGREEMENT: '/terms-and-agreement',
  RESUME: '/resume',
  SUCCESS: '/success'
};
