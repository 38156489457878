import { Component, OnInit } from '@angular/core';

import { Store } from '@ngxs/store';

import { GetUserInformation } from 'src/modules/dashboard/ngxs/dashboard.actions';

@Component({
  selector: 'rs-layout',
  templateUrl: 'layout.component.html',
  styleUrls: ['layout.component.scss']
})
export class LayoutComponent implements OnInit {

  constructor(
    private store: Store
  ) {}

  ngOnInit() {
    this.dispatchActions();
  }

  private dispatchActions(): void {
    this.store.dispatch(new GetUserInformation());
  }

}
