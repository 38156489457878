import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngxs/store';
import * as deepEqual from 'deep-equal';

import { BUSINESS_CATEGORIES } from 'src/modules/shared/constants/common';
import { copy, markFormControlsAsTouched } from 'src/modules/shared/helpers';
import { BusinessEntity } from 'src/modules/shared/models/business-entity.model';
import { Unsubscribe } from 'src/modules/shared/classes/unsubscribe.class';
import { CheckStepStatus } from 'src/modules/dashboard/ngxs/dashboard.actions';
import { STEPS } from 'src/modules/shared/constants/steps';
import { SharedDataService } from 'src/modules/shared/services/shared-data.service';
import { LegalEntityInformationData } from 'src/modules/shared/models/forms/legal-entity-information.model';
import { Address } from 'src/modules/shared/models/address.model';
import { RouterService } from 'src/modules/shared/services/router.service';
import { PAGES } from 'src/modules/shared/constants/pages';
import { REGEX } from 'src/modules/shared/regular-expressions';
import { SaveLegalEntityInformation } from '../ngxs/legal-entity-information.actions';
import { DashboardState } from 'src/modules/dashboard/ngxs/dashboard.state';
import { StepStatuses } from 'src/modules/shared/enums';

@Component({
  selector: 'rs-legal-entity-information',
  templateUrl: 'legal-entity-information.component.html',
  styleUrls: ['legal-entity-information.component.scss']
})
export class LegalEntityInformationComponent extends Unsubscribe implements OnInit, OnDestroy {

  public businessCategories: string[] = copy(BUSINESS_CATEGORIES);
  public legalEntityForm: FormGroup;
  public businessAddressForm: FormGroup;
  public legalAddressForm: FormGroup;
  public legalAddressIsDifferentFromBusinessAddress: boolean;

  private cachedLegalEntityInformation: LegalEntityInformationData;
  private cachedLegalAddress: Address;
  private cachedBusinessAddress: Address;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly store: Store,
    private readonly sharedDataService: SharedDataService,
    private readonly routerService: RouterService
  ) {
    super();
  }

  ngOnInit() {
    this.getCache();
    this.checkDoesBusinessAddressDifferentFromLegalAddress();
    this.initForms();
    this.subscribeToForms();
  }

  public doFormsValid(): boolean {
    return this.legalEntityForm.valid && this.legalAddressForm.valid;
  }

  public goToDashboard(): void {
    this.saveChanges(PAGES.DASHBOARD);
  }

  public next(): void {
    this.saveChanges(PAGES.OWNERS_INFORMATION);
  }

  private saveChanges(page: string): void {
    if (this.doFormsValid()) {
      this.subscribeTo = this.store.dispatch(new SaveLegalEntityInformation(new BusinessEntity(
        this.legalEntityForm.getRawValue(),
        this.legalAddressForm.getRawValue(),
        this.businessAddressForm.getRawValue(),
        this.legalAddressIsDifferentFromBusinessAddress
      )))
        .subscribe(() => this.routerService.navigateToPage(page));
    } else {
      this.routerService.navigateToPage(page);
    }
  }

  private getCache(): void {
    this.cachedLegalEntityInformation = this.sharedDataService.legalEntityInformation;
    this.cachedLegalAddress = this.sharedDataService.legalAddress;
    this.cachedBusinessAddress = this.sharedDataService.businessAddress;
  }

  private subscribeToForms(): void {
    const isFormTouched = this.store.selectSnapshot(DashboardState.legalEntityInformationStatus) === StepStatuses.InProgress;

    if (isFormTouched) {
      markFormControlsAsTouched([this.legalAddressForm, this.legalEntityForm, this.businessAddressForm]);
    }

    this.subscribeTo = this.legalEntityForm.statusChanges
      .subscribe(() => this.formStateChangeCallback());
    this.subscribeTo = this.legalAddressForm.statusChanges
      .subscribe(() => this.formStateChangeCallback());
    this.subscribeTo = this.businessAddressForm.statusChanges
      .subscribe(() => this.formStateChangeCallback());
  }

  private formStateChangeCallback(): void {
    const forms: FormGroup[] = [this.legalEntityForm, this.legalAddressForm];

    if (this.legalAddressIsDifferentFromBusinessAddress) {
      forms.push(this.businessAddressForm);
    }

    this.sharedDataService.legalEntityInformation = this.legalEntityForm.getRawValue();
    this.sharedDataService.legalAddress = this.legalAddressForm.getRawValue();
    this.sharedDataService.businessAddress = this.legalAddressIsDifferentFromBusinessAddress ? this.businessAddressForm.getRawValue() : this.legalAddressForm.getRawValue();
    this.store.dispatch(new CheckStepStatus(STEPS.LEGAL_ENTITY_INFORMATION, forms));
  }

  private initForms(): void {
    this.businessAddressForm = this.formBuilder.group({
      country: [{value: 'United States', disabled: true}, Validators.required],
      address1: [
        this.cachedBusinessAddress ? this.cachedBusinessAddress.address1 : '',
        this.legalAddressIsDifferentFromBusinessAddress ? [Validators.required, Validators.maxLength(31)] : null
      ],
      address2: [this.cachedBusinessAddress ? this.cachedBusinessAddress.address2 : '', Validators.maxLength(31)],
      city: [this.cachedBusinessAddress ? this.cachedBusinessAddress.city : '', this.legalAddressIsDifferentFromBusinessAddress ? Validators.required : null],
      state: [{value: this.cachedBusinessAddress ? this.cachedBusinessAddress.state : '', disabled: this.isStateDisabled(this.cachedBusinessAddress)}],
      zipcode: [this.cachedBusinessAddress ? this.cachedBusinessAddress.zipcode : '', this.legalAddressIsDifferentFromBusinessAddress ? Validators.required : null]
    });
    this.legalAddressForm = this.formBuilder.group({
      country: [{value: 'United States', disabled: true}, Validators.required],
      address1: [this.cachedLegalEntityInformation ? this.cachedLegalAddress.address1 : '', [Validators.required, Validators.maxLength(31)]],
      address2: [this.cachedLegalEntityInformation ? this.cachedLegalAddress.address2 : '', Validators.maxLength(31)],
      city: [this.cachedLegalEntityInformation ? this.cachedLegalAddress.city : '', Validators.required],
      state: [{value: this.cachedLegalEntityInformation ? this.cachedLegalAddress.state : '', disabled: this.isStateDisabled(this.cachedLegalAddress)}],
      zipcode: [this.cachedLegalEntityInformation ? this.cachedLegalAddress.zipcode : '', Validators.required]
    });
    this.legalEntityForm = this.formBuilder.group({
      name: [this.cachedLegalEntityInformation ? this.cachedLegalEntityInformation.name : '', Validators.required],
      legalEntityName: [this.cachedLegalEntityInformation ? this.cachedLegalEntityInformation.legalEntityName : '', Validators.required],
      sein: [this.cachedLegalEntityInformation ? this.cachedLegalEntityInformation.sein : '', [Validators.required]],
      tin: [this.cachedLegalEntityInformation ? this.cachedLegalEntityInformation.tin : '', [Validators.required]],
      url: [this.cachedLegalEntityInformation ? this.cachedLegalEntityInformation.url : '', Validators.pattern(REGEX.LINK)],
      category: [this.cachedLegalEntityInformation ? this.cachedLegalEntityInformation.category : '', Validators.required]
    });
  }

  private isStateDisabled(address: Address): boolean {
    return !address || !(address && address.state);
  }

  private checkDoesBusinessAddressDifferentFromLegalAddress(): void {
    this.legalAddressIsDifferentFromBusinessAddress = !deepEqual(this.cachedLegalAddress, this.cachedBusinessAddress);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

}
