<div class="rs-resume-sales-information">
  <div class="rs-resume-sales-information__content">
    <div class="app__resume-title">{{'titles.sales-information' | translate}}</div>
    <div class="app__resume-sales-information-checkbox" *ngIf="isRestaurantNew()">
      <mat-checkbox
        [checked]="true"
        color="primary"
        [disabled]="true"
      >
        {{'sales-information.new-restaurant' | translate}}
      </mat-checkbox>
    </div>
    <div class="app__resume-wrapper" *ngIf="!isRestaurantNew()">
      <div class="app__resume-row">
        <div class="app__resume-row-item">
          <div class="app__resume-row-key">{{'placeholders.monthly-total-revenue' | translate}}*</div>
          <div class="app__resume-row-value">{{cachedSalesInformation.monthlyTotalRevenue | currency}}</div>
        </div>
        <div class="app__resume-row-item">
          <div class="app__resume-row-key">{{'placeholders.monthly-card-revenue' | translate}}*</div>
          <div class="app__resume-row-value">{{cachedSalesInformation.monthlyTotalCardRevenue | currency}}</div>
        </div>
      </div>
      <div class="app__resume-row">
        <div class="app__resume-row-item">
          <div class="app__resume-row-key">{{'placeholders.average-check' | translate}}*</div>
          <div class="app__resume-row-value">{{cachedSalesInformation.averageCheck | currency}}</div>
        </div>
        <div class="app__resume-row-item">
          <div class="app__resume-row-key">{{'placeholders.percentage-of-phone' | translate}}*</div>
          <div class="app__resume-row-value">{{cachedSalesInformation.mailPhoneTransactions}}</div>
        </div>
      </div>
      <div class="app__resume-row">
        <div class="app__resume-row-item">
          <div class="app__resume-row-key">{{'placeholders.percentage-of-internet' | translate}}*</div>
          <div class="app__resume-row-value">{{cachedSalesInformation.internetTransactions}}</div>
        </div>
        <div class="app__resume-row-item">
          <div class="app__resume-row-key">{{'placeholders.percentage-of-card' | translate}}*</div>
          <div class="app__resume-row-value">{{cachedSalesInformation.cardPresentTransactions}}</div>
        </div>
      </div>
      <div class="app__resume-row">
        <div class="app__resume-row-item">
          <div class="app__resume-row-key">{{'placeholders.highest-card' | translate}}*</div>
          <div class="app__resume-row-value">{{cachedSalesInformation.highestCardTransactionYear | currency}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="app__resume-title">{{'sales-information.restaurant-set-up' | translate}}</div>
  <div class="app__resume-row">
    <div class="app__resume-row-item">
      <div class="app__resume-row-key">{{'placeholders.number-of-tables' | translate}}*</div>
      <div class="app__resume-row-value">{{cachedSalesInformation.tablesCount}}</div>
    </div>
    <div class="app__resume-row-item">
      <div class="app__resume-row-key">{{'placeholders.maximum-number' | translate}}* </div>
      <div class="app__resume-row-value">{{cachedSalesInformation.floorServersAmount}}</div>
    </div>
  </div>
  <div class="app__resume-row">
    <div class="app__resume-row-item">
      <div class="app__resume-row-key">{{'placeholders.number-of-kitchen' | translate}}*</div>
      <div class="app__resume-row-value">{{cachedSalesInformation.kitchenStationsCount}}</div>
    </div>
  </div>
</div>
