import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Store } from '@ngxs/store';

import { ActivateProfile } from 'src/modules/dashboard/ngxs/dashboard.actions';

@Component({
  selector: 'rs-profile-activation',
  template: ''
})
export class ProfileActivationComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private store: Store
  ) {}

  ngOnInit() {
    const { token } = this.route.snapshot.queryParams;

    this.store.dispatch(new ActivateProfile(token));
  }

}
