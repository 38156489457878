import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { Store } from '@ngxs/store';

import { SetStepStatus } from 'src/modules/dashboard/ngxs/dashboard.actions';
import { PAGES } from '../constants/pages';
import { STEPS } from '../constants/steps';
import { StepStatuses } from '../enums';
import { RouterService } from '../services/router.service';
import { SharedDataService } from '../services/shared-data.service';

@Injectable({
  providedIn: 'root'
})
export class BusinessEntityIsReadyGuard implements CanActivate {

  constructor(
    private sharedDataService: SharedDataService,
    private routerService: RouterService,
    private store: Store
  ) {}

  public canActivate(): boolean {

    if (!this.sharedDataService.steps) {
      this.store.dispatch(new SetStepStatus(STEPS.LEGAL_ENTITY_INFORMATION, StepStatuses.Untouched));
    }

    const isBusinessEntityReady =
      this.sharedDataService.steps.legalEntityInformationStatus === StepStatuses.Done &&
      this.sharedDataService.steps.ownersInformationStatus === StepStatuses.Done &&
      this.sharedDataService.steps.bankingInformationStatus === StepStatuses.Done &&
      this.sharedDataService.steps.salesInformationStatus === StepStatuses.Done &&
      this.sharedDataService.steps.applicantInformationStatus === StepStatuses.Done &&
      this.sharedDataService.steps.termsAndAgreementStatus === StepStatuses.Done;

    if (!isBusinessEntityReady) {
      this.routerService.navigateToPage(PAGES.DASHBOARD);
    }

    return isBusinessEntityReady;
  }

}
