import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngxs/store';

import { BusinessLeader } from 'src/modules/shared/models/business-leader';
import { REGEX } from 'src/modules/shared/regular-expressions';
import { SendInviteToBusinessLeader } from '../../ngxs/applicant-information.actions';

@Component({
  selector: 'rs-leader-email',
  templateUrl: 'leader-email.component.html',
  styleUrls: ['leader-email.component.scss']
})
export class LeaderEmailComponent implements OnInit {

  public leadInvitationForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store
  ) {}

  ngOnInit() {
    this.initForm();
  }

  public sendInvite(): void {
    this.store.dispatch(new SendInviteToBusinessLeader(new BusinessLeader(this.leadInvitationForm.getRawValue())));
  }

  private initForm(): void {
    this.leadInvitationForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(REGEX.EMAIL)]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required]
    });
  }

}
