import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { Unsubscribe } from 'src/modules/shared/classes/unsubscribe.class';
import { OwnerTypes } from 'src/modules/shared/enums';
import { CompanyOwner } from 'src/modules/shared/models/company-owner.model';
import { CompanyData } from 'src/modules/shared/models/forms/company.model';
import { REGEX } from 'src/modules/shared/regular-expressions';
import { SharedDataService } from 'src/modules/shared/services/shared-data.service';
import { AddOwner, RemoveNewOwner } from '../../ngxs/owners.actions';

@Component({
  selector: 'rs-company',
  templateUrl: 'company.component.html',
  styleUrls: ['company.component.scss']
})
export class CompanyComponent extends Unsubscribe implements OnInit {

  @Select(state => state.owners.newOwner) newOwner$: Observable<CompanyData>;

  @Output() cancelClick = new EventEmitter<void>();

  @Input() addMyselfAsOwner: boolean;
  @Input() selectedOwner: CompanyOwner;
  @Input() index: number;

  public companyForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private sharedDataService: SharedDataService,
    private store: Store
  ) {
    super();
  }

  ngOnInit() {
    this.initForm();
    this.preFillUserInformation();
    this.initSubscriptions();
  }

  public initSubscriptions(): void {
    this.subscribeTo = this.newOwner$
      .subscribe( newOwner =>  {
        if (newOwner) {
          this.cancelClick.emit();
          this.store.dispatch(new RemoveNewOwner());
        }
      });
  }

  public isAddButtonDisabled(): boolean {
    return this.companyForm.invalid;
  }

  public accept(): void {
    if (this.selectedOwner) {
      this.save();
    } else {
      this.add();
    }
  }

  public reactOnCancelClick(): void {
    this.cancelClick.emit();
  }

  private initForm(): void {
    this.companyForm = this.formBuilder.group({
      businessName: [this.selectedOwner ? this.selectedOwner.businessName : '', Validators.required],
      email: [this.selectedOwner ? this.selectedOwner.email : '', [Validators.required, Validators.email, Validators.pattern(REGEX.EMAIL)]],
      firstName: [this.selectedOwner ? this.selectedOwner.firstName : '', Validators.required],
      lastName: [this.selectedOwner ? this.selectedOwner.lastName : '', Validators.required],
      phone: [this.selectedOwner ? this.selectedOwner.phone : '', Validators.required],
      share: [this.selectedOwner ? this.selectedOwner.share : '', Validators.required],
      tin: [this.selectedOwner ? this.selectedOwner.tin : '', [Validators.required]],
      address: this.formBuilder.group({
        address1: [this.selectedOwner ? this.selectedOwner.address.address1 : '', [Validators.required, Validators.maxLength(31)]],
        address2: [this.selectedOwner ? this.selectedOwner.address.address2 : '', Validators.maxLength(31)],
        city: [this.selectedOwner ? this.selectedOwner.address.city : '', Validators.required],
        country: [{value: this.selectedOwner ? this.selectedOwner.address.country : 'United States', disabled: true}, Validators.required],
        state: [{value: this.selectedOwner ? this.selectedOwner.address.state : '', disabled: true}],
        zipcode: [this.selectedOwner ? this.selectedOwner.address.zipcode : '', Validators.required]
      })
    });
  }

  private preFillUserInformation(): void {
    if (this.addMyselfAsOwner) {
      const { firstName, lastName, email, phone } = this.companyForm.controls;

      firstName.reset(this.sharedDataService.userProfile.firstName);
      lastName.reset(this.sharedDataService.userProfile.lastName);
      email.reset(this.sharedDataService.userProfile.email);
      phone.reset(this.sharedDataService.userProfile.phone.phoneNumber);
    }
  }

  private createOwner(): CompanyData {
    const companyOwnerInfo = this.companyForm.getRawValue();

    return {
      ...companyOwnerInfo,
      phone: companyOwnerInfo.phone.number
    };
  }

  private save(): void {
    const owners = this.sharedDataService.companyOwners;

    owners.splice(this.index, 1, this.createOwner());
    this.sharedDataService.companyOwners = owners;
    this.cancelClick.emit();
  }

  private add(): void {
    const currentOwner = this.createOwner();
    this.store.dispatch(new AddOwner(OwnerTypes.COMPANY, currentOwner));
  }

}
