import { Component } from '@angular/core';

import { height } from 'src/modules/shared/animations';

@Component({
  selector: 'rs-need-any-help',
  templateUrl: 'need-any-help.component.html',
  styleUrls: ['need-any-help.component.scss'],
  animations: [
    height
  ]
})
export class NeedAnyHelpComponent {

  public needAnyHelpVisibility: boolean;


  public toggleNeedAnyHelpVisibility(): void {
    this.needAnyHelpVisibility = !this.needAnyHelpVisibility;
  }

}
