import { Component } from '@angular/core';

import { Store } from '@ngxs/store';

import { DataRecovering, Submit } from 'src/modules/dashboard/ngxs/dashboard.actions';
import { Unsubscribe } from 'src/modules/shared/classes/unsubscribe.class';
import { CACHE } from 'src/modules/shared/constants/cache';
import { StepStatuses } from 'src/modules/shared/enums';
import { LocalStorageService } from 'src/modules/shared/services/local-storage.service';
import { SharedDataService } from 'src/modules/shared/services/shared-data.service';

@Component({
  selector: 'rs-resume',
  templateUrl: 'resume.component.html',
  styleUrls: ['resume.component.scss']
})
export class ResumeComponent extends Unsubscribe {

  public contentVisibility: boolean;

  constructor(
    private sharedDataService: SharedDataService,
    private store: Store,
    private localStorageService: LocalStorageService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.clearBusinessEntityCachedData();
    this.subscribeTo = this.store.dispatch(new DataRecovering())
      .subscribe(() => this.contentVisibility = true);
  }

  public isDashboardDisabled(): boolean {
    return this.sharedDataService.validationCompleted;
  }

  public submit(): void {
    this.store.dispatch(new Submit());
  }

  public termsAndAgreementVisibility(): boolean {
    return this.sharedDataService.steps.termsAndAgreementStatus === StepStatuses.Done;
  }

  private clearBusinessEntityCachedData(): void {
    this.localStorageService.clearCachedData(CACHE.CAN_I_SIGN_DOCUMENTS);
    this.localStorageService.clearCachedData(CACHE.LEGAL_ADDRESS);
    this.localStorageService.clearCachedData(CACHE.BUSINESS_ADDRESS);
    this.localStorageService.clearCachedData(CACHE.BUSINESS_ENTITY_ID);
    this.localStorageService.clearCachedData(CACHE.BANKING_INFORMATION);
    this.localStorageService.clearCachedData(CACHE.COMPANY_OWNERS);
    this.localStorageService.clearCachedData(CACHE.SALES_INFORMATION);
    this.localStorageService.clearCachedData(CACHE.LEGAL_ENTITY_INFORMATION);
  }

}
