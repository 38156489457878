import { Injectable } from '@angular/core';

import { NotificationService } from './notification.service';
import { TranslationsService } from './translations.service';

@Injectable({
  providedIn: 'root'
})
export class ShowNotificationsService {

  constructor(
    private notificationService: NotificationService,
    private translationsService: TranslationsService
  ) {}

  public showYouAreBusinessLeader(): void {
    this.notificationService.showSuccessMessage(
      '',
      this.translationsService.get('successes.you-are-business-leader.content')
    );
  }

  public showPasswordLinkIsIncorrect(): void {
    this.notificationService.showErrorMessage(
      this.translationsService.get('errors.password-recovering.title'),
      this.translationsService.get('errors.password-recovering.content')
    );
  }

  public showAuthenticationLink(): void {
    this.notificationService.showSuccessMessage(
      this.translationsService.get('successes.authentication-link.title'),
      this.translationsService.get('successes.authentication-link.content')
    );
  }

  public showFollowResendActivationLink(): void {
    this.notificationService.showSuccessMessage(
      this.translationsService.get('successes.activation-link-was-resend.title'),
      this.translationsService.get('successes.activation-link-was-resend.content')
    );
  }

  public showFollowPasswordLink(): void {
    this.notificationService.showSuccessMessage(
      this.translationsService.get('successes.follow-password-link.title'),
      this.translationsService.get('successes.follow-password-link.content')
    );
  }

  public showSuccessPasswordRecovering(): void {
    this.notificationService.showSuccessMessage(
      this.translationsService.get('successes.password-recovering.title'),
      this.translationsService.get('successes.password-recovering.content')
    );
  }

  public showEmailWasCopied(): void {
    this.notificationService.showSuccessMessage(
      '',
      this.translationsService.get('successes.email-was-copied.content')
    );
  }

  public showProfileWasActivated(): void {
    this.notificationService.showSuccessMessage(
      this.translationsService.get('successes.profile-was-activated.title'),
      this.translationsService.get('successes.profile-was-activated.content')
    );
  }

  public showYouAreNotAllowedError(): void {
    this.notificationService.showErrorMessage(
      '',
      this.translationsService.get('errors.you-are-not-allowed.content')
    );
  }

  public showTermsAndAgreementAlreadySigned(): void {
    this.notificationService.showSuccessMessage(
      '',
      this.translationsService.get('successes.terms-and-agreement-already-signed.content')
    );
  }

}
