<div class="rs-address-form" [formGroup]="rsFormGroup">
  <div class="app__controls">
    <mat-form-field appearance="outline">
      <mat-label>{{'placeholders.country' | translate}}*</mat-label>
      <mat-select formControlName="country" (selectionChange)="reactOnCountryChange()">
        <mat-option *ngFor="let country of countries" [value]="country.name">{{country.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <div></div>
  </div>
  <div class="app__controls">
    <mat-form-field appearance="outline">
      <mat-label>{{'placeholders.street-name' | translate}}*</mat-label>
      <input formControlName="address1" matInput placeholder="{{'placeholders.street-name' | translate}}*" maxlength="32">
      <mat-error *ngIf="rsFormGroup.get('address1').hasError('maxlength')">{{ "placeholders.max-inputs-length" | translate }}</mat-error>
    </mat-form-field>
  </div>
  <div class="app__controls">
    <mat-form-field appearance="outline">
      <mat-label>{{'placeholders.apartment' | translate}}</mat-label>
      <input formControlName="address2" matInput placeholder="{{'placeholders.apartment' | translate}}" maxlength="32">
      <mat-error *ngIf="rsFormGroup.get('address2').hasError('maxlength')">{{ "placeholders.max-inputs-length" | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{'placeholders.city' | translate}}*</mat-label>
      <input formControlName="city" matInput placeholder="{{'placeholders.city' | translate}}*">
    </mat-form-field>
  </div>
  <div class="app__controls">
    <mat-form-field appearance="outline">
      <mat-label>{{'placeholders.state' | translate}}*</mat-label>
      <mat-select formControlName="state">
        <mat-option *ngFor="let state of states" [value]="state.code">{{state.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <rs-control-with-mask [rsFormControl]="rsFormGroup.controls.zipcode" [placeholder]="'placeholders.zip-code'" mask="00000"></rs-control-with-mask>
  </div>
</div>
