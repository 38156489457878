import * as moment from 'moment';

export class DocumentDetails {
  documentIssuer: string;
  documentType: string;
  expireDate: string;
  idNumber: string;
  issueDate: string;
  issuingState: string;

  constructor(documentDetails: DocumentDetails) {
    this.documentIssuer = documentDetails.documentIssuer;
    this.documentType = documentDetails.documentType;
    this.expireDate = moment(documentDetails.expireDate).utc().format();
    this.idNumber = documentDetails.idNumber;
    this.issueDate = moment(documentDetails.issueDate).utc().format();
    this.issuingState = documentDetails.issuingState ? `US-${documentDetails.issuingState}` : '';
  }
}
