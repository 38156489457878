import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { MaterialModule } from '../shared/modules/material.module';
import { SharedModule } from '../shared/modules/shared.module';
import { SuccessComponent } from './components/success/success.component';
import { ResultState } from './ngxs/result.state';

@NgModule({
  imports: [
    SharedModule,
    MaterialModule,
    NgxsModule.forFeature([ResultState])
  ],
  declarations: [
    SuccessComponent
  ],
  exports: [
    SuccessComponent
  ]
})
export class ResultModule {}
