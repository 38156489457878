import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

import { Observable } from 'rxjs';

import { MICRO_SERVICES } from '../constants/endpoints';

@Injectable()
export class ClientInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let finalRequest;

    if (request.url.includes(MICRO_SERVICES.USER_PROFILE)) {
      finalRequest = request.clone({
        body: {
          ...request.body,
          client: 'REGP'
        }
      });
    } else {
      finalRequest = request;
    }

    return next.handle(finalRequest);
  }

}
