import { FormGroup } from '@angular/forms';

import { StepStatuses } from 'src/modules/shared/enums';

export class ActivateProfile {
  static readonly type = '[Profile] Activate';

  constructor(
    public token: string
  ) {}
}

export class DataRecovering {
  static readonly type = '[Business Entity] Data Recovering';
}

export class ViewSignedDocument {
  static readonly type = '[Signed Document] View';
}

export class RedirectToSignedDocument {
  static readonly type = '[Signed Document] Redirect';
}

export class Submit {
  static readonly type = 'Submit';
}

export class CheckSigningState {
  static readonly type = '[Signing State] Check';
}

export class GetUserInformation {
  static readonly type = '[User Information] Get';
}

export class InitCachedSteps {
  static readonly type = '[Cached Steps] Init';
}

export class SetStepStatus {
  static readonly type = '[Step Status] Set';

  constructor(
    public stepName: string,
    public stepStatus: StepStatuses
  ) {}
}

export class CheckStepStatus {
  static readonly type = '[Step Status] Check';

  constructor(
    public stepName: string,
    public forms: FormGroup[]
  ) {}
}

export class ClearStepStatuses {
  static readonly type = '[Step Status] Clear';
}
