import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'rs-owner-actions',
  templateUrl: 'owner-actions.component.html',
  styleUrls: ['owner-actions.component.scss']
})
export class OwnerActionsComponent {

  @Output() cancelClick = new EventEmitter();
  @Output() addClick = new EventEmitter();

  @Input() isAddButtonDisabled: boolean;
  @Input() isEdit: boolean;

  public getAcceptButtonTitle(): string {
    return this.isEdit ? 'buttons.save' : 'buttons.add';
  }

  public cancel(): void {
    this.cancelClick.emit();
  }

  public add(): void {
    this.addClick.emit();
  }

}
