import { FormGroup } from '@angular/forms';

export const totalSumValidator = (form: FormGroup) => {
  const { cardPresentTransactions, mailPhoneTransactions, internetTransactions } = form.controls;

  if (
    (parseInt(cardPresentTransactions.value, 10) + parseInt(mailPhoneTransactions.value, 10) + parseInt(internetTransactions.value, 10)) !== 100 &&
    cardPresentTransactions.value &&
    mailPhoneTransactions.value &&
    internetTransactions.value
  ) {
    cardPresentTransactions.markAsTouched();
    mailPhoneTransactions.markAsTouched();
    internetTransactions.markAsTouched();
    cardPresentTransactions.setErrors({
      totalSum: 'Percentages must add up to 100%'
    });
    mailPhoneTransactions.setErrors({
      totalSum: 'Percentages must add up to 100%'
    });
    internetTransactions.setErrors({
      totalSum: 'Percentages must add up to 100%'
    });
  } else {
    if (cardPresentTransactions.value) {
      cardPresentTransactions.setErrors(null);
    }
    if (mailPhoneTransactions.value) {
      mailPhoneTransactions.setErrors(null);
    }
    if (internetTransactions.value) {
      internetTransactions.setErrors(null);
    }
  }

  return;
};
