import { Component, Inject } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'rs-go-to-docusign',
  templateUrl: 'go-to-docusign.component.html',
  styleUrls: ['go-to-docusign.component.scss']
})
export class GoToDocusignPopupComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public view: boolean
  ) {}

}
