<div class="rs-dashboard">
  <div class="rs-dashboard__title">{{'dashboard.welcome' | translate}}</div>
  <div class="rs-dashboard__subtitle">{{'dashboard.filling-out' | translate}}</div>
  <div class="rs-dashboard__content">
    <div class="rs-dashboard__content-item">
      <rs-step [status]="legalEntityInformationStatus" caption="{{'titles.legal-entity-information' | translate}}" link="/legal-entity-information"></rs-step>
    </div>
    <div class="rs-dashboard__content-item">
      <rs-step [disabled]="!isBusinessEntityCreated" [status]="ownersInformationStatus" caption="{{'titles.owners-information' | translate}}" link="/owners-information"></rs-step>
    </div>
    <div class="rs-dashboard__content-item">
      <rs-step [disabled]="!isBusinessEntityCreated" [status]="bankingInformationStatus" caption="{{'titles.banking-information' | translate}}" link="/banking-information"></rs-step>
    </div>
    <div class="rs-dashboard__content-item">
      <rs-step [disabled]="!isBusinessEntityCreated" [status]="salesInformationStatus" caption="{{'titles.sales-information' | translate}}" link="/sales-information"></rs-step>
    </div>
    <div class="rs-dashboard__content-item">
      <rs-step [disabled]="!isBusinessEntityCreated" [status]="applicantInformationStatus" caption="{{'titles.leadership-information' | translate}}" (click)="goToApplicantInformation()"></rs-step>
    </div>
    <div class="rs-dashboard__content-item">
      <rs-step [disabled]="!isBusinessEntityCreated" [status]="termsAndAgreementStatus" caption="{{'titles.terms-and-agreement' | translate}}" (click)="goToTermsAndAgreement()"></rs-step>
    </div>
  </div>
  <div class="rs-dashboard__actions">
    <button routerLink="/resume" [disabled]="isSubmitButtonDisabled()" mat-raised-button color="primary" class="app__button app__button_huge">{{'buttons.submit' | translate}}</button>
  </div>
</div>
