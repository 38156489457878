<div class="rs-owner" matRippleColor="#00a7ef10" (click)="select()">
  <div class="rs-owner__delete" rsDeleteOwner (accepted)="acceptDeleteOwner()" *ngIf="!hideDelete">
    <button mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div>
    <div class="rs-owner__title">{{owner | rsFullName}}</div>
    <div class="rs-owner__info">
      <div class="rs-owner__info-item">
        <div class="rs-owner__info-item-key">{{(isIndividual ? 'sales-information.social-security' : 'placeholders.tin') | translate}}</div>
        <div class="rs-owner__info-item-value">{{isIndividual ? owner.socialSecurityNumber : owner.tin}}</div>
      </div>
      <div class="rs-owner__info-item">
        <div class="rs-owner__info-item-key">{{'placeholders.email' | translate}}</div>
        <div class="rs-owner__info-item-value">{{owner.email}}</div>
      </div>
      <div class="rs-owner__info-item">
        <div class="rs-owner__info-item-key">{{'placeholders.share' | translate}}</div>
        <div class="rs-owner__info-item-value">{{owner.share}}%</div>
      </div>
    </div>
  </div>
</div>
