export class BankingInformation {

  public accountName: string;
  public accountNumber: string;
  public accountType: string;
  public routingNumber: string;

  constructor(bankingInformationData: BankingInformation) {
    this.accountName = 'Default';
    this.accountNumber = bankingInformationData.accountNumber;
    this.accountType = bankingInformationData.accountType;
    this.routingNumber = bankingInformationData.routingNumber;
  }

}
