import { CompanyData } from 'src/modules/shared/models/forms/company.model';
import { IndividualData } from 'src/modules/shared/models/forms/individual.model';

export class UpdateOwnersInformation {
  static readonly type = '[Owners Information] Update';
}

export class AddOwner {
  static readonly type = '[Owners Information] Add owner';

  constructor(
    public ownerType: string,
    public owner: IndividualData | CompanyData
  ) {}
}

export class RemoveNewOwner {
  static readonly type = '[Owners Information] Remove New Owner';
}
