<div class="rs-activate-your-account">
  <div class="rs-activate-your-account__logo">
    <img src="assets/img/rockspoon-title.svg">
  </div>
  <div class="rs-activate-your-account__title">{{'activate-account.title' | translate}}</div>
  <div class="rs-activate-your-account__description">
    <div>{{'activate-account.we-sent' | translate}} <b>{{email}}</b>.</div>
    <div>{{'activate-account.click-link' | translate}}</div>
  </div>
  <div class="rs-activate-your-account__resend">
    <div class="rs-activate-your-account__resend-title">{{'activate-account.did-receive' | translate}}</div>
    <div class="rs-activate-your-account__resend-button">
      <button mat-button color="primary" class="app__link app__link_huge" (click)="resend()">{{'buttons.send-again' | translate}}</button>
    </div>
  </div>
  <div class="rs-activate-your-account__login">
    <button mat-raised-button class="app__button" color="primary" routerLink="/sign-in">{{'buttons.login' | translate}}</button>
  </div>
</div>
<rs-footer></rs-footer>
