import { Component, OnInit } from '@angular/core';

import { Store } from '@ngxs/store';

import { SetStepStatus } from 'src/modules/dashboard/ngxs/dashboard.actions';
import { Unsubscribe } from 'src/modules/shared/classes/unsubscribe.class';
import { PAGES } from 'src/modules/shared/constants/pages';
import { STEPS } from 'src/modules/shared/constants/steps';
import { StepStatuses } from 'src/modules/shared/enums';
import { CompanyOwner } from 'src/modules/shared/models/company-owner.model';
import { CompanyData } from 'src/modules/shared/models/forms/company.model';
import { IndividualData } from 'src/modules/shared/models/forms/individual.model';
import { IndividualOwner } from 'src/modules/shared/models/individual-owner.model';
import { RouterService } from 'src/modules/shared/services/router.service';
import { SharedDataService } from 'src/modules/shared/services/shared-data.service';
import { UpdateOwnersInformation } from '../../ngxs/owners.actions';

@Component({
  selector: 'rs-owners',
  templateUrl: 'owners.component.html',
  styleUrls: ['owners.component.scss']
})
export class OwnersComponent extends Unsubscribe implements OnInit {

  public isAdding: boolean;
  public isEditingIndividual: boolean;
  public isEditingCompany: boolean;
  public cachedCompanyOwners: CompanyData[];
  public cachedIndividualOwners: IndividualData[];
  public addMyselfAsOwner: boolean;
  public selectedOwner: CompanyOwner | IndividualOwner;
  public selectedOwnerIndex: number;

  constructor(
    private routerService: RouterService,
    private sharedDataService: SharedDataService,
    private store: Store
  ) {
    super();
  }

  public ngOnInit(): void {
    this.getCachedOwners();
    this.markThisStepAsInProgress();
  }

  public noContentVisibility(): boolean {
    return !this.cachedIndividualOwners?.length && !this.cachedCompanyOwners?.length;
  }

  public individualTabVisibility(): boolean {
    return this.isAdding || this.isEditingIndividual;
  }

  public companyTabVisibility(): boolean {
    return this.isAdding || this.isEditingCompany;
  }

  public ownersTabsVisibility(): boolean {
    return this.isAdding || this.isEditingCompany || this.isEditingIndividual;
  }

  public reactOnCancelClick(): void {
    this.setAddingState(false);
    this.selectedOwner = null;
    this.selectedOwnerIndex = null;
    this.isEditingCompany = false;
    this.isEditingIndividual = false;
  }

  public selectOwner(owner: CompanyOwner | IndividualOwner, index: number, isIndividual: boolean): void {
    this.selectedOwner = owner;
    this.selectedOwnerIndex = index;
    isIndividual ? this.isEditingIndividual = true : this.isEditingCompany = true;
  }

  public deleteOwner(index: number, isIndividual: boolean): void {
    const owners: any[] = isIndividual ? this.sharedDataService.individualOwners : this.sharedDataService.companyOwners;

    owners.splice(index, 1);
    isIndividual ? this.sharedDataService.individualOwners = owners : this.sharedDataService.companyOwners = owners;
    this.getCachedOwners();
  }

  public setAddingState(isAdding: boolean): void {
    this.isAdding = isAdding;
    this.getCachedOwners();
  }

  public addNewOwner(addMyselfAsOwner?: boolean): void {
    this.addMyselfAsOwner = addMyselfAsOwner;
    this.isAdding = true;
  }

  public goNext(): void {
    this.saveChanges(PAGES.BANKING_INFORMATION);
  }

  public goDashboard(): void {
    this.saveChanges(PAGES.DASHBOARD);
  }

  private markThisStepAsInProgress(): void {
    if (this.cachedCompanyOwners?.length || this.cachedIndividualOwners?.length) {
      this.store.dispatch(new SetStepStatus(STEPS.OWNERS_INFORMATION, StepStatuses.Done));
    } else {
      this.store.dispatch(new SetStepStatus(STEPS.OWNERS_INFORMATION, StepStatuses.InProgress));
    }
  }

  private saveChanges(page: string): void {
    this.subscribeTo = this.store.dispatch(new UpdateOwnersInformation())
      .subscribe(() => this.routerService.navigateToPage(page));
  }

  private getCachedOwners(): void {
    this.cachedCompanyOwners = this.sharedDataService.companyOwners || [];
    this.cachedIndividualOwners = this.sharedDataService.individualOwners || [];
    this.markThisStepAsInProgress();
  }

}
