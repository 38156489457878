<div
  class="rs-step"
  matRipple
  matRippleColor="#00a7ef10"
  (click)="reactOnClick()"
  [ngClass]="{
    'rs-step_done': isStepDone(),
    'rs-step_in-progress': isStepInProgress(),
    'rs-step_blocked': isStepBlocked(),
    'rs-step_disabled': disabled
  }"
>
  <div class="rs-step__status"></div>
  {{caption}}
</div>
