import { Component, OnInit } from '@angular/core';

import { Store } from '@ngxs/store';

import { SendActivationLink } from 'src/modules/shared/ngxs/authentication/authentication.actions';
import { SharedDataService } from 'src/modules/shared/services/shared-data.service';

@Component({
  selector: 'rs-activate-your-account',
  templateUrl: 'activate-your-account.component.html',
  styleUrls: ['activate-your-account.component.scss']
})
export class ActivateYourAccountComponent implements OnInit {

  public email: string;

  constructor(
    private sharedDataService: SharedDataService,
    private store: Store
  ) {}

  ngOnInit() {
    this.email = this.sharedDataService.signUpEmail;
  }

  public resend(): void {
    this.store.dispatch(new SendActivationLink(this.email));
  }

}
