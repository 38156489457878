import { NgModule } from '@angular/core';

import { EmailModule } from './email/email.module';
import { LayoutModule } from './layout/modules/layout.module';
import { SetUpDevicesModule } from './set-up-devices/modules/set-up-devices.module';
import { SignInModule } from './sign-in/sign-in.module';
import { SignUpModule } from './sign-up/sign-up.module';

@NgModule({
  exports: [
    LayoutModule,
    SignUpModule,
    SignInModule,
    SetUpDevicesModule,
    EmailModule
  ]
})
export class MainModulesModule {}
