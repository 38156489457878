<div
  class="rs-password-strength"
  [ngClass]="{
    'rs-password-strength_weak': isPasswordWeak(),
    'rs-password-strength_moderate': isPasswordModerate(),
    'rs-password-strength_strong': isPasswordStrong(),
    'rs-password-strength_very-strong': isPasswordVeryStrong(),
    'rs-password-strength_complete': isPasswordComplete()
  }"
>
  <div class="rs-password-strength__title">{{'password-strength.your-password-needs' | translate}}</div>
  <div class="rs-password-strength__section">
    <div class="rs-password-strength__section-item">
      <span [class.rs-password-strength__section-item_completed]="nineCharactersCheckCompleted">{{'password-strength.characters' | translate}}</span>
      <span>{{'password-strength.of-which' | translate}}:</span>
    </div>
  <div class="rs-password-strength__section">
    <div class="rs-password-strength__section-item" [class.rs-password-strength__section-item_completed]="lowerCaseCheckCompleted">{{'password-strength.lower-case' | translate}}</div>
    <div class="rs-password-strength__section-item" [class.rs-password-strength__section-item_completed]="upperCaseCheckCompleted">{{'password-strength.upper-case' | translate}}</div>
    <div class="rs-password-strength__section-item" [class.rs-password-strength__section-item_completed]="numberCheckCompleted">{{'password-strength.number' | translate}}</div>
    <div class="rs-password-strength__section-item" [class.rs-password-strength__section-item_completed]="specialCharacterCompleted">{{'password-strength.special-character' | translate}}</div>
  </div>
  <div class="rs-password-strength__title">{{'password-strength.password-strength' | translate}}:</div>
  <div class="rs-password-strength__value">
    <div class="rs-password-strength__value-line">
      <div class="rs-password-strength__value-line-filler"></div>
    </div>
    <div class="rs-password-strength__value-title" *ngIf="isPasswordWeak()">{{'password-strength.weak' | translate}}</div>
    <div class="rs-password-strength__value-title" *ngIf="isPasswordModerate()">{{'password-strength.moderate' | translate}}</div>
    <div class="rs-password-strength__value-title" *ngIf="isPasswordStrong()">{{'password-strength.strong' | translate}}</div>
    <div class="rs-password-strength__value-title" *ngIf="isPasswordVeryStrong()">{{'password-strength.very-strong' | translate}}</div>
    <div class="rs-password-strength__value-title" *ngIf="isPasswordComplete()">{{'password-strength.complete' | translate}}</div>
  </div>
  <div class="rs-password-strength__arrow"></div>
</div>
