<div class="rs-turn-on-router rs-guide">
  <div class="rs-guide__header">
    <div class="rs-guide__image">
      <img src="assets/img/tablet-hand.png">
    </div>
  </div>
  <div class="rs-guide__footer">
    <div class="rs-guide__number">4</div>
    <div class="rs-guide__title">{{'devices.click-app' | translate}}</div>
  </div>
</div>
