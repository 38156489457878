import { Phone, PhoneControl } from '../phone.model';

export interface SignUpFormData {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  phone: PhoneControl;
  jobPosition: string;
  restaurantName: string;
}

export class SignUpData {
  public email: string;
  public firstName: string;
  public lastName: string;
  public password: string;
  public phone: Phone;
  public jobPosition: string;
  public restaurantName: string;

  constructor(signUpFormData: SignUpFormData) {
    this.email = signUpFormData.email;
    this.firstName = signUpFormData.firstName;
    this.lastName = signUpFormData.lastName;
    this.password = signUpFormData.password;
    this.restaurantName = signUpFormData.restaurantName;
    this.jobPosition = signUpFormData.jobPosition;
    this.phone = new Phone(signUpFormData.phone);
  }

}
