<div class="rs-connect-isp rs-guide">
  <div class="rs-guide__header">
    <div class="rs-guide__image">
      <img src="assets/img/routers.png">
    </div>
  </div>
  <div class="rs-guide__footer">
    <div class="rs-guide__number">2</div>
    <div class="rs-guide__title">{{'devices.connect-isp.connect' | translate}}</div>
    <div class="rs-guide__subtitle">{{'devices.connect-isp.subtitle' | translate}}</div>
  </div>
</div>
