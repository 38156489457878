import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { Select, Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { Unsubscribe } from 'src/modules/shared/classes/unsubscribe.class';
import { GoToDocusignPopupComponent } from 'src/modules/shared/components/popups/go-to-docusign/go-to-docusign.component';
import { DIALOG_SIZES, ROUTE_PARAMS } from 'src/modules/shared/constants/common';
import { PAGES } from 'src/modules/shared/constants/pages';
import { STEPS } from 'src/modules/shared/constants/steps';
import { StepStatuses } from 'src/modules/shared/enums';
import { RouterService } from 'src/modules/shared/services/router.service';
import { SharedDataService } from 'src/modules/shared/services/shared-data.service';
import { ShowNotificationsService } from 'src/modules/shared/services/show-notifications.service';
import { CheckSigningState, DataRecovering, InitCachedSteps, SetStepStatus, ViewSignedDocument } from '../../ngxs/dashboard.actions';
import { SetSpinnerVisibility } from '../../../root/ngxs/root.actions';

@Component({
  selector: 'rs-dashboard',
  templateUrl: 'dashboard.component.html',
  styleUrls: ['dashboard.component.scss']
})
export class DashboardComponent extends Unsubscribe implements OnInit {

  @Select(state => state.dashboard.legalEntityInformationStatus) legalEntityInformationStatus$: Observable<StepStatuses>;
  @Select(state => state.dashboard.ownersInformationStatus) ownersInformationStatus$: Observable<StepStatuses>;
  @Select(state => state.dashboard.bankingInformationStatus) bankingInformationStatus$: Observable<StepStatuses>;
  @Select(state => state.dashboard.salesInformationStatus) salesInformationStatus$: Observable<StepStatuses>;
  @Select(state => state.dashboard.applicantInformationStatus) applicantInformationStatus$: Observable<StepStatuses>;
  @Select(state => state.dashboard.termsAndAgreementStatus) termsAndAgreementStatus$: Observable<StepStatuses>;
  @Select(state => state.dashboard.signUrl) signUrl$: Observable<string>;

  public legalEntityInformationStatus: StepStatuses;
  public ownersInformationStatus: StepStatuses;
  public bankingInformationStatus: StepStatuses;
  public salesInformationStatus: StepStatuses;
  public applicantInformationStatus: StepStatuses;
  public termsAndAgreementStatus: StepStatuses;
  public isBusinessEntityCreated: boolean;

  private signUrl: string;

  constructor(
    private store: Store,
    private sharedDataService: SharedDataService,
    private changeDetectorRef: ChangeDetectorRef,
    private routerService: RouterService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private showNotificationsService: ShowNotificationsService
  ) {
    super();
  }

  ngOnInit() {
    this.checkSigningState();
    this.dispatchActions();
    this.initSubscriptions();
  }

  public goToApplicantInformation(): void {
    if (this.sharedDataService.businessEntityId) {
      if (!this.isDocumentSigned()) {
        this.routerService.navigateToPage(PAGES.APPLICANT_INFORMATION);
      }
    }
  }

  public goToTermsAndAgreement(): void {
    if (this.sharedDataService.businessEntityId) {
      if (!this.signUrl) {
        this.store.dispatch(new CheckSigningState());
      }
      if (this.termsAndAgreementStatus === StepStatuses.Done) {
        this.showNotificationsService.showTermsAndAgreementAlreadySigned();
      } else
      if (this.sharedDataService.canISignDocuments === null) {
        this.routerService.navigateToPage(PAGES.APPLICANT_INFORMATION);
      } else
      if (this.sharedDataService.canISignDocuments === false) {
        this.showNotificationsService.showYouAreNotAllowedError();
      } else
      if (!this.isDocumentSigned()) {
        this.subscribeTo = this.signUrl$
          .pipe(
            switchMap(signUrl => {
              this.signUrl = signUrl;
  
              if (signUrl) {
                return this.dialog.open(GoToDocusignPopupComponent, {
                  width: DIALOG_SIZES.MIDDLE
                }).afterClosed();
              } else {
                return of();
              }
            })
          )
          .subscribe(confirmation => {
            if (confirmation) {
              this.store.dispatch(new SetSpinnerVisibility(true));
              location.replace(this.signUrl);
            }
          });
      }
    }
  }

  public isSubmitButtonDisabled(): boolean {
    return this.legalEntityInformationStatus !== StepStatuses.Done ||
           this.ownersInformationStatus !== StepStatuses.Done ||
           this.bankingInformationStatus !== StepStatuses.Done ||
           this.salesInformationStatus !== StepStatuses.Done ||
           this.applicantInformationStatus !== StepStatuses.Done ||
           this.termsAndAgreementStatus !== StepStatuses.Done;
  }

  private isDocumentSigned(): boolean {
    if (this.applicantInformationStatus === StepStatuses.Done && this.termsAndAgreementStatus === StepStatuses.Done) {
      this.showNotificationsService.showTermsAndAgreementAlreadySigned();

      return true;
    }

    return false;
  }

  private checkSigningState(): void {
    if (this.route.snapshot.queryParams?.event === ROUTE_PARAMS.SIGNING_COMPLETE) {
      this.store.dispatch(new SetStepStatus(STEPS.TERMS_AND_AGREEMENT, StepStatuses.Done));
    }
  }

  private dispatchActions(): void {
    this.store.dispatch(new InitCachedSteps());
    this.subscribeTo = this.store.dispatch(new DataRecovering())
      .subscribe(() => {
        this.checkBusinessEntity();
        this.store.dispatch(new ViewSignedDocument());
      });
  }

  private initSubscriptions() {
    this.subscribeTo = this.legalEntityInformationStatus$
      .subscribe(legalEntityInformationStatus => this.legalEntityInformationStatus = legalEntityInformationStatus);
    this.subscribeTo = this.ownersInformationStatus$
      .subscribe(ownersInformationStatus => this.ownersInformationStatus = ownersInformationStatus);
    this.subscribeTo = this.bankingInformationStatus$
      .subscribe(bankingInformationStatus => this.bankingInformationStatus = bankingInformationStatus);
    this.subscribeTo = this.salesInformationStatus$
      .subscribe(salesInformationStatus => this.salesInformationStatus = salesInformationStatus);
    this.subscribeTo = this.applicantInformationStatus$
      .subscribe(applicantInformationStatus => this.applicantInformationStatus = applicantInformationStatus);
    this.subscribeTo = this.termsAndAgreementStatus$
      .subscribe(termsAndAgreementStatus => {
        this.termsAndAgreementStatus = termsAndAgreementStatus;
        this.changeDetectorRef.markForCheck();
      });
  }

  private checkBusinessEntity(): void {
    this.isBusinessEntityCreated = !!this.sharedDataService.businessEntityId;
  }

}
