import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { Unsubscribe } from 'src/modules/shared/classes/unsubscribe.class';

@Component({
  selector: 'rs-root',
  templateUrl: 'root.component.html',
  styleUrls: ['root.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RootComponent extends Unsubscribe implements OnInit {

  @Select(state => state.root.spinnerVisibility) spinnerVisibility$: Observable<boolean>;

  public spinnerVisibility: boolean;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.setDefaultBrowserLanguage();
    this.subscribeToSpinner();
  }

  private subscribeToSpinner(): void {
    this.subscribeTo = this.spinnerVisibility$.subscribe(spinnerVisibility => {
      setTimeout(() => {
        this.spinnerVisibility = spinnerVisibility;
        this.changeDetectorRef.markForCheck();
      });
    });
  }

  private setDefaultBrowserLanguage(): void {
    this.translateService.use(this.translateService.getBrowserLang());
  }

}
