import { Component, OnInit } from '@angular/core';

import { getCountryByCode, getStateByCode } from 'src/modules/shared/helpers';
import { BusinessEntity } from 'src/modules/shared/models/business-entity.model';
import { SharedDataService } from 'src/modules/shared/services/shared-data.service';

@Component({
  selector: 'rs-resume-legal-information',
  templateUrl: 'resume-legal-information.component.html',
  styleUrls: ['resume-legal-information.component.scss']
})
export class ResumeLegalInformationComponent implements OnInit {

  public legalEntityInformation: BusinessEntity;

  constructor(
    private sharedDataService: SharedDataService
  ) {}

  ngOnInit() {
    this.getCachedLegalInformation();
  }

  private getCachedLegalInformation(): void {
    this.legalEntityInformation = new BusinessEntity(this.sharedDataService.legalEntityInformation, this.sharedDataService.legalAddress, this.sharedDataService.businessAddress, true);
    this.legalEntityInformation.businessInformation.legalAddress.state = getStateByCode(this.sharedDataService.legalAddress.country, this.sharedDataService.legalAddress.state);
    this.legalEntityInformation.businessInformation.businessAddress.state = getStateByCode(this.sharedDataService.businessAddress.country, this.sharedDataService.businessAddress.state);
    this.legalEntityInformation.businessInformation.legalAddress.country = getCountryByCode(this.sharedDataService.legalAddress.country);
    this.legalEntityInformation.businessInformation.businessAddress.country = getCountryByCode(this.sharedDataService.businessAddress.country);
  }

}
