import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { PAGES } from '../constants/pages';
import { RouterService } from '../services/router.service';

import { SharedDataService } from '../services/shared-data.service';

@Injectable({
  providedIn: 'root'
})
export class ValidationCompletedGuard implements CanActivate {

  constructor(
    private sharedDataService: SharedDataService,
    private routerService: RouterService
  ) {}

  public canActivate(): boolean {
    if (this.sharedDataService.validationCompleted) {
      this.routerService.navigateToPage(PAGES.RESUME);
    }

    return !this.sharedDataService.validationCompleted;
  }

}
