<div class="rs-forgot-password">
  <div class="rs-forgot-password__logo">
    <img src="assets/img/rockspoon-title.svg">
  </div>
  <div class="rs-forgot-password__title">{{'forgot-password.title' | translate}}</div>
  <div class="rs-forgot-password__description">{{'forgot-password.description' | translate}}</div>
  <div class="rs-forgot-password__control">
    <mat-form-field>
      <mat-label>{{'placeholders.email' | translate}}</mat-label>
      <input (keydown.enter)="sendEmail()" [formControl]="emailControl" matInput placeholder="{{'placeholders.email' | translate}}">
    </mat-form-field>
  </div>
  <div class="rs-forgot-password__button">
    <button [disabled]="emailControl.invalid" (click)="sendEmail()" mat-raised-button color="primary" class="app__button">{{'forgot-password.reset-my-password' | translate}}</button>
  </div>
  <div class="rs-forgot-password__resend">
    <div class="rs-forgot-password__resend-title">{{'forgot-password.did-receive' | translate}}</div>
    <div class="rs-forgot-password__resend-button">
      <button [disabled]="emailControl.invalid" (click)="sendEmail()" mat-button color="primary" class="app__link app__link_huge">{{'buttons.send-again' | translate}}</button>
    </div>
  </div>
</div>
<rs-footer></rs-footer>
