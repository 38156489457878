<div class="rs-banking-information">
  <div class="rs-banking-information__title app__step-title">{{'titles.banking-information' | translate}}</div>
  <div class="app__section" [formGroup]="bankingInformationForm">
    <div class="app__controls">
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.account-type' | translate}}*</mat-label>
        <mat-select formControlName="accountType">
          <mat-option *ngFor="let accountType of accountTypes" [value]="accountType">{{accountType}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="app__controls">
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.routing-number' | translate}}*</mat-label>
        <input rsNumericControl formControlName="routingNumber" matInput placeholder="{{'placeholders.routing-number' | translate}}">
      </mat-form-field>
    </div>
    <div class="app__controls">
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.account-number' | translate}}*</mat-label>
        <input rsNumericControl formControlName="accountNumber" matInput placeholder="{{'placeholders.account-number' | translate}}">
      </mat-form-field>
    </div>
  </div>
  <div class="rs-banking-information__navigation">
    <rs-navigation-buttons (clickDashboard)="goToDashboard()" (clickNext)="goToNext()"></rs-navigation-buttons>
  </div>
</div>
