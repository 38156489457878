import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'rs-owner',
  templateUrl: 'owner.component.html',
  styleUrls: ['owner.component.scss']
})
export class OwnerComponent {

  @Output() deleteClick = new EventEmitter<void>();
  @Output() selectOwner = new EventEmitter<void>();

  @Input() owner: any;
  @Input() isIndividual: boolean;
  @Input() hideDelete: boolean;

  public select(): void {
    this.selectOwner.emit();
  }

  public acceptDeleteOwner(): void {
    this.deleteClick.emit();
  }

}
