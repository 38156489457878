import { animate, style, transition, trigger } from '@angular/animations';

export const scale =
trigger('scale', [
  transition(':enter', [
    style({transform: 'scale(0)', opacity: 0}),
    animate(100, style({transform: 'scale(1)', opacity: 1}))
  ]),
  transition(':leave', [
    style({transform: 'scale(1)', opacity: 1}),
    animate(100, style({transform: 'scale(0)', opacity: 0}))
  ])
]);

export const darkness =
trigger('darkness', [
  transition(':enter', [
    style({backgroundColor: 'transparent'}),
    animate(300, style({backgroundColor: 'rgba(0,0,0,.7)'}))
  ]),
  transition(':leave', [
    style({backgroundColor: 'rgba(0,0,0,.7)'}),
    animate(300, style({backgroundColor: 'transparent'}))
  ])
]);

export const height =
trigger('height', [
  transition(':enter', [
    style({height: '0px'}),
    animate(100, style({height: '*'}))
  ]),
  transition(':leave', [
    style({height: '*'}),
    animate(100, style({height: '0'}))
  ])
]);

export const width =
trigger('width', [
  transition(':enter', [
    style({width: '0px'}),
    animate(100, style({width: '*'}))
  ]),
  transition(':leave', [
    style({width: '*'}),
    animate(100, style({width: '0'}))
  ])
]);
