import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngxs/store';

import { SignUpData } from 'src/modules/shared/models/forms/sign-up.model';
import { SignUp } from 'src/modules/shared/ngxs/authentication/authentication.actions';
import { REGEX } from 'src/modules/shared/regular-expressions';
import { confirmPasswordValidator } from 'src/modules/shared/validators/confirm-password.validation';
import { scale } from '../../../shared/animations';

@Component({
  selector: 'rs-sign-up',
  templateUrl: 'sign-up.component.html',
  styleUrls: ['sign-up.component.scss'],
  animations: [
    scale
  ]
})
export class SignUpComponent implements OnInit {

  public signUpForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store
  ) {}

  ngOnInit() {
    this.initForm();
  }

  public signUp(): void {
    const signUpData = new SignUpData(this.signUpForm.getRawValue());
    this.store.dispatch(new SignUp(signUpData));
  }

  public openFile(fileName: string) {
    window.open(`../../assets/files/${fileName}.pdf`);
  }

  private initForm(): void {
    this.signUpForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email, Validators.pattern(REGEX.EMAIL)]],
      restaurantName: ['', Validators.required],
      jobPosition: ['', Validators.required],
      phone: ['', Validators.required],
      password: ['', [Validators.required, Validators.pattern(REGEX.PASSWORD)]],
      confirm: ['', Validators.required]
    }, {
      validators: confirmPasswordValidator
    });
  }

}
