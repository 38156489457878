import { Component, Input } from '@angular/core';

import { StepStatuses } from 'src/modules/shared/enums';
import { RouterService } from 'src/modules/shared/services/router.service';

@Component({
  selector: 'rs-step',
  templateUrl: 'step.component.html',
  styleUrls: ['step.component.scss']
})
export class StepComponent {

  @Input() caption: string;
  @Input() link: string;
  @Input() status: StepStatuses;
  @Input() disabled: boolean;

  constructor(
    private routerService: RouterService
  ) {}

  public reactOnClick(): void {
    if (!this.disabled && this.link) {
      this.routerService.navigateToPage(this.link);
    }
  }

  public isStepDone(): boolean {
    return this.status === StepStatuses.Done;
  }

  public isStepInProgress(): boolean {
    return this.status === StepStatuses.InProgress;
  }

  public isStepBlocked(): boolean {
    return this.status === StepStatuses.Blocked;
  }

  public isStepUntouched(): boolean {
    return this.status === StepStatuses.Untouched;
  }

}
