<div class="rs-turn-on-router rs-guide">
  <div class="rs-guide__header">
    <div class="rs-guide__image">
      <img src="assets/img/router.svg">
    </div>
  </div>
  <div class="rs-guide__footer">
    <div class="rs-guide__number">1</div>
    <div class="rs-guide__title">{{'devices.turn-on-router.title' | translate}}</div>
    <div class="rs-guide__subtitle">{{'devices.turn-on-router.content' | translate}}</div>
  </div>
</div>
