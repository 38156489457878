import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PasswordControlModule } from '../shared/components/controls/password-control/password-control.module';
import { PasswordStrengthModule } from '../shared/components/password-strength/password-strength.module';
import { PhoneControlModule } from '../shared/components/controls/phone-control/phone-control.module';
import { MaterialModule } from '../shared/modules/material.module';
import { SharedModule } from '../shared/modules/shared.module';
import { ActivateYourAccountComponent } from './components/activate-your-account/activate-your-account.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { FooterModule } from '../shared/components/footer/footer.module';

@NgModule({
  imports: [
    SharedModule,
    RouterModule,
    MaterialModule,
    PasswordStrengthModule,
    PhoneControlModule,
    PasswordControlModule,
    FooterModule
  ],
  declarations: [
    SignUpComponent,
    ActivateYourAccountComponent
  ],
  exports: [
    SignUpComponent
  ]
})
export class SignUpModule {}
