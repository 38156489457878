import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngxs/store';

import { SignIn } from 'src/modules/shared/ngxs/authentication/authentication.actions';
import { REGEX } from 'src/modules/shared/regular-expressions';

@Component({
  selector: 'rs-sign-in',
  templateUrl: 'sign-in.component.html',
  styleUrls: ['sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  public signInForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store
  ) {}

  ngOnInit() {
    this.initForm();
  }

  public signIn(): void {
    if (this.signInForm.valid) {
      this.store.dispatch(new SignIn(this.signInForm.getRawValue()));
    }
  }

  private initForm(): void {
    this.signInForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email, Validators.pattern(REGEX.EMAIL)]],
      password: ['', Validators.required]
    });
  }

}
