import { Component } from '@angular/core';

import { Store } from '@ngxs/store';

import { scale } from 'src/modules/shared/animations';
import { Logout } from 'src/modules/shared/ngxs/authentication/authentication.actions';

@Component({
  selector: 'rs-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
  animations: [
    scale
  ]
})
export class HeaderComponent {

  public dropdownVisibility: boolean;

  constructor(
    private store: Store
  ) {}

  public toggleDropdownVisibility(visibility: boolean): void {
    this.dropdownVisibility = visibility;
  }

  public logout(): void {
    this.toggleDropdownVisibility(false);
    this.store.dispatch(new Logout());
  }

}
