import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MatCheckboxChange } from '@angular/material/checkbox';
import { Store } from '@ngxs/store';

import { CheckStepStatus } from 'src/modules/dashboard/ngxs/dashboard.actions';
import { DashboardState } from 'src/modules/dashboard/ngxs/dashboard.state';
import { Unsubscribe } from 'src/modules/shared/classes/unsubscribe.class';
import { PAGES } from 'src/modules/shared/constants/pages';
import { STEPS } from 'src/modules/shared/constants/steps';
import { StepStatuses } from 'src/modules/shared/enums';
import { markFormControlsAsTouched } from 'src/modules/shared/helpers';
import { SalesInformation } from 'src/modules/shared/models/sales-information.model';
import { RouterService } from 'src/modules/shared/services/router.service';
import { SharedDataService } from 'src/modules/shared/services/shared-data.service';
import { totalSumValidator } from 'src/modules/shared/validators/total-sum.validator';
import { UpdateSalesInformation } from '../ngxs/sales-information.actions';

@Component({
  selector: 'rs-sales-information',
  templateUrl: 'sales-information.component.html',
  styleUrls: ['sales-information.component.scss']
})
export class SalesInformationComponent extends Unsubscribe implements OnInit, OnDestroy {

  public salesInformationForm: FormGroup;
  public restaurantSetUpForm: FormGroup;
  public newRestaurant: boolean;
  public isTouched: boolean = false;

  private cachedSalesInformation: SalesInformation;

  constructor(
    private formBuilder: FormBuilder,
    private sharedDataService: SharedDataService,
    private store: Store,
    private routerService: RouterService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.getCachedData();
    this.initForm();
    this.subscribeToForm();
    this.checkFormState();
  }

  public reactOnNewRestaurantChange(event: MatCheckboxChange): void {
    if (event.checked) {
      this.disableForm();
    } else {
      this.enableForm();
    }
  }

  public goToDashboard(): void {
    this.saveInformation(PAGES.DASHBOARD);
  }

  public goNext(): void {
    this.saveInformation(PAGES.APPLICANT_INFORMATION);
  }

  private checkFormState(): void {
    if (this.sharedDataService.newRestaurant) {
      this.newRestaurant = true;
      this.disableForm();
    }
  }

  private disableForm(): void {
    this.sharedDataService.newRestaurant = true;
    this.salesInformationForm.reset();
    this.salesInformationForm.disable();
  }

  private enableForm(): void {
    this.sharedDataService.newRestaurant = false;
    this.salesInformationForm.enable();
    this.salesInformationForm.setValue({
      monthlyTotalRevenue: this.cachedSalesInformation.monthlyTotalRevenue,
      monthlyTotalCardRevenue: this.cachedSalesInformation.monthlyTotalCardRevenue,
      cardPresentTransactions: this.cachedSalesInformation.cardPresentTransactions,
      highestCardTransactionYear: this.cachedSalesInformation.highestCardTransactionYear,
      internetTransactions: this.cachedSalesInformation.internetTransactions,
      mailPhoneTransactions: this.cachedSalesInformation.mailPhoneTransactions,
      averageCheck: this.cachedSalesInformation.averageCheck
    });
  }

  private saveInformation(page: string): void {
    if ((this.sharedDataService.newRestaurant || this.salesInformationForm.valid) && this.restaurantSetUpForm.valid) {
      this.subscribeTo = this.store.dispatch(new UpdateSalesInformation(new SalesInformation(this.getSalesFormData())))
        .subscribe(() => this.routerService.navigateToPage(page));
    } else {
      this.routerService.navigateToPage(page);
    }
    this.routerService.navigateToPage(page);
  }

  private subscribeToForm(): void {
    const isFormTouched = this.store.selectSnapshot(DashboardState.salesInformationStatus) === StepStatuses.InProgress;

    this.subscribeTo = this.salesInformationForm.statusChanges
      .subscribe(() => this.checkStatusDispatcher());

    this.subscribeTo = this.restaurantSetUpForm.valueChanges
      .subscribe(() => this.checkStatusDispatcher());

    if (isFormTouched) {
      markFormControlsAsTouched([this.salesInformationForm]);
      this.isTouched = true;
    }
  }

  private checkStatusDispatcher(): void {
    this.sharedDataService.newRestaurant
      ? this.store.dispatch(new CheckStepStatus(STEPS.SALES_INFORMATION, [this.restaurantSetUpForm]))
      : this.store.dispatch(new CheckStepStatus(STEPS.SALES_INFORMATION, [this.salesInformationForm, this.restaurantSetUpForm]));
  }

  private initForm(): void {
    this.salesInformationForm = this.formBuilder.group({
      monthlyTotalRevenue: [this.cachedSalesInformation ? this.cachedSalesInformation.monthlyTotalRevenue : null, Validators.required],
      monthlyTotalCardRevenue: [this.cachedSalesInformation ? this.cachedSalesInformation.monthlyTotalCardRevenue : null, Validators.required],
      averageCheck: [this.cachedSalesInformation ? this.cachedSalesInformation.averageCheck : null, Validators.required],
      cardPresentTransactions: [this.cachedSalesInformation ? this.cachedSalesInformation.cardPresentTransactions : '', Validators.required],
      highestCardTransactionYear: [this.cachedSalesInformation ? this.cachedSalesInformation.highestCardTransactionYear : null, Validators.required],
      internetTransactions: [this.cachedSalesInformation ? this.cachedSalesInformation.internetTransactions : '', Validators.required],
      mailPhoneTransactions: [this.cachedSalesInformation ? this.cachedSalesInformation.mailPhoneTransactions : '', Validators.required],
    }, {
      validators: totalSumValidator
    });

    this.restaurantSetUpForm = this.formBuilder.group({
      floorServersAmount: [this.cachedSalesInformation ? this.cachedSalesInformation.floorServersAmount : '', Validators.required],
      kitchenStationsCount: [this.cachedSalesInformation ? this.cachedSalesInformation.kitchenStationsCount : '', Validators.required],
      tablesCount: [this.cachedSalesInformation ? this.cachedSalesInformation.tablesCount : '', Validators.required]
    });
  }

  private getCachedData(): void {
    this.cachedSalesInformation = this.sharedDataService.salesInformation;
  }

  private getSalesFormData(): SalesInformation {
    return {
      ...this.salesInformationForm.getRawValue(),
      ...this.restaurantSetUpForm.getRawValue()
    };
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.sharedDataService.salesInformation = this.getSalesFormData();
  }

}
