<div class="rs-sign-up">
  <div class="rs-sign-up__logo">
    <img src="assets/img/rockspoon-title.svg">
  </div>
  <div class="rs-sign-up__title">{{'authentication.create-your-rockspoon-account' | translate}}</div>
  <div class="rs-sign-up__form" [formGroup]="signUpForm">
    <mat-form-field>
      <mat-label>{{'placeholders.first-name' | translate}}</mat-label>
      <input formControlName="firstName" matInput placeholder="{{'placeholders.first-name' | translate}}">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'placeholders.last-name' | translate}}</mat-label>
      <input formControlName="lastName" matInput placeholder="{{'placeholders.last-name' | translate}}">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'placeholders.email' | translate}}</mat-label>
      <input type="email" formControlName="email" matInput placeholder="{{'placeholders.email' | translate}}">
    </mat-form-field>
    <rs-phone-control [linear]="true" [rsPhoneControl]="signUpForm.controls.phone"></rs-phone-control>
    <mat-form-field>
      <mat-label>{{'placeholders.restaurant-name' | translate}}</mat-label>
      <input formControlName="restaurantName" matInput placeholder="{{'placeholders.restaurant-name' | translate}}">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'placeholders.job-position' | translate}}</mat-label>
      <input formControlName="jobPosition" matInput placeholder="{{'placeholders.job-position' | translate}}">
    </mat-form-field>
    <rs-password-control
      [rsPasswordControl]="signUpForm.controls.password"
      placeholder="{{'placeholders.password' | translate}}"
      [passwordStrength]="true"
    ></rs-password-control>
    <rs-password-control
      [rsPasswordControl]="signUpForm.controls.confirm"
      placeholder="{{'placeholders.confirm-password' | translate}}"
    ></rs-password-control>
    <div class="rs-sign-up__agreement">
      {{'authentication.by-creating' | translate}} <span class="rs-sign-up__agreement-link" (click)="openFile('Terms_of_Use')">{{'common.term-of-use' | translate}}</span> {{'common.and' | translate}} <span class="rs-sign-up__agreement-link" (click)="openFile('Privacy_Policy')">{{'common.privacy-policy' | translate}}</span>.
    </div>
    <button mat-raised-button color="primary" class="app__button" [disabled]="signUpForm.invalid" (click)="signUp()">{{'authentication.create-your-account' | translate}}</button>
  </div>
  <div class="rs-sign-up__sign-in-link">
    <div class="rs-sign-up__sign-in-link-title">{{'authentication.already-have-an-account' | translate}}</div>
    <div class="rs-sign-up__sign-in-link-button">
      <button mat-raised-button class="app__button" color="primary" routerLink="/sign-in">{{'authentication.login' | translate}}</button>
    </div>
  </div>
</div>
<rs-footer></rs-footer>
