<div class="rs-legal-entity-information">
  <div class="rs-legal-entity-information__title app__step-title">{{'titles.legal-entity-information' | translate}}</div>
  <div class="rs-legal-entity-information__content">
    <div class="rs-legal-entity-information__restaurant-info" [formGroup]="legalEntityForm">
      <div class="app__section-title">{{'legal-entity-information.restaurant-information' | translate}}</div>
      <div class="app__controls">
        <mat-form-field appearance="outline">
          <mat-label>{{'placeholders.dba-name' | translate}}*</mat-label>
          <input formControlName="name" matInput placeholder="{{'placeholders.dba-name' | translate}}">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{'placeholders.legal-entity-name' | translate}}*</mat-label>
          <input formControlName="legalEntityName" matInput placeholder="{{'placeholders.legal-entity-name' | translate}}">
        </mat-form-field>
      </div>
      <div class="app__controls">
        <rs-control-with-mask [rsFormControl]="legalEntityForm.controls.sein" [placeholder]="'placeholders.sein'" mask="00-0000000"></rs-control-with-mask>
        <rs-control-with-mask [rsFormControl]="legalEntityForm.controls.tin" [placeholder]="'placeholders.tin'" mask="000000000"></rs-control-with-mask>
      </div>
      <div class="app__controls">
        <mat-form-field appearance="outline">
          <mat-label>{{'placeholders.restaurant-url' | translate}}</mat-label>
          <input formControlName="url" matInput placeholder="{{'placeholders.restaurant-url' | translate}}">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{'placeholders.business-category' | translate}}*</mat-label>
          <mat-select formControlName="category">
            <mat-option *ngFor="let businessCategory of businessCategories" [value]="businessCategory">{{businessCategory}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="rs-legal-entity-information__address">
      <div class="app__section-title">{{'legal-entity-information.legal-address' | translate}}</div>
      <rs-address-form [rsFormGroup]="legalAddressForm"></rs-address-form>
    </div>
    <mat-checkbox [(ngModel)]="legalAddressIsDifferentFromBusinessAddress" color="primary" class="rs-sign-up__checkbox" color="primary">{{'legal-entity-information.different' | translate}}</mat-checkbox>
    <div *ngIf="legalAddressIsDifferentFromBusinessAddress" class="rs-legal-entity-information__address" [formGroup]="businessAddressForm">
      <div class="app__section-title">{{'legal-entity-information.business-address' | translate}}*</div>
      <rs-address-form [rsFormGroup]="businessAddressForm"></rs-address-form>
    </div>
  </div>
  <div class="rs-legal-entity-information__navigation">
    <rs-navigation-buttons [isNextDisabled]="!doFormsValid()" (clickNext)="next()" (clickDashboard)="goToDashboard()"></rs-navigation-buttons>
  </div>
</div>
