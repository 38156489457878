import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'rs-percent-control',
  templateUrl: 'percent-control.component.html',
  styleUrls: ['percent-control.component.scss']
})
export class PercentControlComponent implements OnInit {

  @Input() placeholder: string;
  @Input() isTouched: boolean = false;
  @Input() rsFormControl: FormControl;

  ngOnInit() {
    setTimeout(() => {
      this.rsFormControl.reset(this.rsFormControl.value);

      if (this.isTouched) {
        this.rsFormControl.markAsTouched();
      }

    });
  }

}
