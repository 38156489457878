<div class="rs-resume">
  <div class="app__step-title">{{'resume.review' | translate}}</div>
  <div class="rs-resume__subtitle">{{'resume.does-everything' | translate}}</div>
  <div class="rs-resume__content" *ngIf="contentVisibility">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>{{'titles.legal-entity-information' | translate}}</mat-panel-title>
        </mat-expansion-panel-header>
        <rs-resume-legal-information></rs-resume-legal-information>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>{{'titles.owners-information' | translate}}</mat-panel-title>
        </mat-expansion-panel-header>
        <rs-resume-owners-information></rs-resume-owners-information>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>{{'titles.banking-information' | translate}}</mat-panel-title>
        </mat-expansion-panel-header>
        <rs-resume-banking-information></rs-resume-banking-information>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>{{'titles.sales-information' | translate}}</mat-panel-title>
        </mat-expansion-panel-header>
        <rs-resume-sales-information></rs-resume-sales-information>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>{{'resume.application-information' | translate}}</mat-panel-title>
        </mat-expansion-panel-header>
        <rs-resume-leadership-information></rs-resume-leadership-information>
      </mat-expansion-panel>
      <mat-expansion-panel *ngIf="termsAndAgreementVisibility()">
        <mat-expansion-panel-header>
          <mat-panel-title>{{'titles.terms-and-agreement' | translate}}</mat-panel-title>
        </mat-expansion-panel-header>
        <rs-resume-terms-and-agreement></rs-resume-terms-and-agreement>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div class="rs-resume__actions" *ngIf="!isDashboardDisabled() && contentVisibility">
    <button mat-raised-button color="primary" class="app__button" routerLink="/dashboard">{{'buttons.edit' | translate}}</button>
    <button mat-raised-button color="primary" class="app__button" (click)="submit()">{{'buttons.submit' | translate}}</button>
  </div>
</div>
