import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxsModule } from '@ngxs/store';

import { SimpleNotificationsModule } from 'angular2-notifications';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

import { RootState } from './root/ngxs/root.state';
import { COMMON_CONSTANTS } from './shared/constants/common';
import { HttpLoaderFactory } from './shared/helpers';
import { AuthenticationState } from './shared/ngxs/authentication/authentication.state';

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: COMMON_CONSTANTS.DEFAULT_LANGUAGE
    }),
    NgxsModule.forRoot([
      RootState,
      AuthenticationState
    ]),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    SimpleNotificationsModule.forRoot({
      maxStack: 1
    })
  ],
  exports: [
    TranslateModule
  ]
})
export class PluginsModule {}
