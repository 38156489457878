<div class="rs-header">
  <div class="rs-header__logo" routerLink="/dashboard">
    <img src="assets/img/rockspoon-title.svg">
  </div>
  <div class="rs-header__icons">
    <!-- <div class="rs-header__icons-notifications" matRipple matRippleColor="#00a7ef10">
      <img src="assets/img/notifications.jpg">
    </div> -->
    <div class="rs-header__icons-user" matRipple matRippleColor="#00a7ef10" (click)="toggleDropdownVisibility(true)">
      <img src="assets/img/user.svg">
    </div>
    <div class="rs-header__dropdown" *ngIf="dropdownVisibility" @scale (clickOutside)="toggleDropdownVisibility(false)" [exclude]="'.rs-header__icons-user'">
      <div class="rs-header__dropdown-item" (click)="logout()">{{'authentication.logout' | translate}}</div>
    </div>
  </div>
</div>
