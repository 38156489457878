import { Injectable } from '@angular/core';

import { Action, State, Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { SetStepStatus } from 'src/modules/dashboard/ngxs/dashboard.actions';
import { Spinner } from 'src/modules/shared/classes/spinner.class';
import { PAGES } from 'src/modules/shared/constants/pages';
import { STEPS } from 'src/modules/shared/constants/steps';
import { DocuSignModes, StepStatuses } from 'src/modules/shared/enums';
import { BusinessLeader } from 'src/modules/shared/models/business-leader';
import { DocuSign } from 'src/modules/shared/models/docu-sign.model';
import { RefreshSession } from 'src/modules/shared/ngxs/authentication/authentication.actions';
import { RouterService } from 'src/modules/shared/services/router.service';
import { SharedDataService } from 'src/modules/shared/services/shared-data.service';
import { ApplicantInformationService } from '../applicant-information.service';
import { GetDocumentSignUrl, MakeMeAsBusinessLeader, SendInviteToBusinessLeader } from './applicant-information.actions';

@State({
  name: 'applicantInformationService'
})
@Injectable()
export class ApplicantInformationState extends Spinner {

  constructor(
    private applicantInformationService: ApplicantInformationService,
    private sharedDataService: SharedDataService,
    private routerService: RouterService,
    protected store: Store
  ) {
    super(store);
  }

  @Action(SendInviteToBusinessLeader)
  sendInviteToBusinessLeader(stateContext, {businessLeader}: SendInviteToBusinessLeader) {
    this.showSpinner();

    return this.applicantInformationService.createBusinessLeader(
      this.sharedDataService.businessEntityId,
      businessLeader
    )
      .pipe(
        tap({
          next: () => this.routerService.navigateToPage(PAGES.DASHBOARD),
          complete: () => this.hideSpinner()
        })
      );
  }

  @Action(MakeMeAsBusinessLeader)
  makeMeAsBusinessLeader() {
    this.showSpinner();
    let refreshSessionSubscription: Subscription;

    return this.applicantInformationService.createBusinessLeader(
      this.sharedDataService.businessEntityId,
      new BusinessLeader(this.sharedDataService.userProfile)
    )
      .pipe(
        tap({
          next: () => {
            this.sharedDataService.canISignDocuments = true;
            this.store.dispatch(new SetStepStatus(STEPS.APPLICANT_INFORMATION, StepStatuses.Done));
            this.store.dispatch(new SetStepStatus(STEPS.TERMS_AND_AGREEMENT, StepStatuses.InProgress));
            refreshSessionSubscription = this.store.dispatch(new RefreshSession())
              .subscribe(() => {
                refreshSessionSubscription.unsubscribe();
                this.store.dispatch(new GetDocumentSignUrl());
              });
          }
        })
      );
  }

  @Action(GetDocumentSignUrl)
  getDocumentSignUrl() {
    this.showSpinner();

    return this.applicantInformationService.getDocumentSignUrl(this.sharedDataService.businessEntityId, DocuSignModes.sign)
      .pipe(
        tap({
          next: (response: DocuSign) => {
            if (response.url) {
              document.location.replace(response.url);
            }
          }
        })
      );
  }

}
