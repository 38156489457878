import { Directive, ElementRef, HostListener } from '@angular/core';

import { ShowNotificationsService } from '../services/show-notifications.service';

@Directive({
  selector: '[rsEmail]'
})
export class EmailDirective {

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private showNotificationsService: ShowNotificationsService
  ) {}

  @HostListener('click')
  saveToClipboard(): void {
    navigator.clipboard.writeText(this.elementRef.nativeElement.innerText);
    this.showNotificationsService.showEmailWasCopied();
  }

}
