export class BusinessLeader {

  public email: string;
  public firstName: string;
  public lastName: string;

  constructor(businessLeader: BusinessLeader) {
    this.email = businessLeader.email;
    this.firstName = businessLeader.firstName;
    this.lastName = businessLeader.lastName;
  }

}
