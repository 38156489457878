import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NgxsModule } from '@ngxs/store';

import { GoToDocusignPopupModule } from '../shared/components/popups/go-to-docusign/go-to-docusign.module';
import { MaterialModule } from '../shared/modules/material.module';
import { SharedModule } from '../shared/modules/shared.module';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { StepComponent } from './components/step/step.component';
import { DashboardState } from './ngxs/dashboard.state';

@NgModule({
  imports: [
    SharedModule,
    MaterialModule,
    NgxsModule.forFeature([
      DashboardState
    ]),
    RouterModule,
    GoToDocusignPopupModule
  ],
  declarations: [
    DashboardComponent,
    StepComponent
  ],
  exports: [
    DashboardComponent
  ]
})
export class DashboardModule {}
