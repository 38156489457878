<div class="rs-who">
  <div class="rs-who__title app__step-title">{{'leadership.who-will-be-signing' | translate}}</div>
  <div class="rs-who__actions">
    <button mat-raised-button class="app__button" color="primary" rsOpenGoToDocusign (accepted)="me()">{{'leadership.me' | translate}}</button>
    <button mat-raised-button class="app__button" color="primary" (click)="notMe()">{{'leadership.not-me' | translate}}</button>
  </div>
  <rs-leader-email *ngIf="leaderEmailVisibility"></rs-leader-email>
  <div class="rs-who__navigation">
    <rs-navigation-buttons [dashboardOnly]="true" (clickDashboard)="goToDashboard()"></rs-navigation-buttons>
  </div>
</div>
