<div class="rs-resume-legal-information" *ngIf="legalEntityInformation">
  <div class="app__resume-row">
    <div class="app__resume-row-item">
      <div class="app__resume-row-key">{{'placeholders.dba-name' | translate}}*:</div>
      <div class="app__resume-row-value">{{legalEntityInformation.name}}</div>
    </div>
    <div class="app__resume-row-item">
      <div class="app__resume-row-key">{{'placeholders.legal-entity-name' | translate}}*:</div>
      <div class="app__resume-row-value">{{legalEntityInformation.businessInformation.legalEntityName}}</div>
    </div>
  </div>
  <div class="app__resume-row">
    <div class="app__resume-row-item">
      <div class="app__resume-row-key">{{'placeholders.sein' | translate}}:</div>
      <div class="app__resume-row-value">{{legalEntityInformation.businessInformation.sein}}</div>
    </div>
    <div class="app__resume-row-item">
      <div class="app__resume-row-key">{{'placeholders.tin' | translate}}*:</div>
      <div class="app__resume-row-value">{{legalEntityInformation.businessInformation.tin}}</div>
    </div>
  </div>
  <div class="app__resume-row">
    <div class="app__resume-row-item">
      <div class="app__resume-row-key">{{'placeholders.restaurant-url' | translate}}:</div>
      <div class="app__resume-row-value">{{legalEntityInformation.url}}</div>
    </div>
    <div class="app__resume-row-item">
      <div class="app__resume-row-key">{{'placeholders.business-category' | translate}}*:</div>
      <div class="app__resume-row-value">{{legalEntityInformation.businessInformation.category}}</div>
    </div>
  </div>
  <div class="app__resume-title">{{'legal-entity-information.legal-address' | translate}}*</div>
  <div class="app__resume-row">
    <div class="app__resume-row-item">
      <div class="app__resume-row-key">{{'placeholders.street-name' | translate}}:</div>
      <div class="app__resume-row-value">{{legalEntityInformation.businessInformation.legalAddress.address1}}</div>
    </div>
    <div class="app__resume-row-item">
      <div class="app__resume-row-key">{{'placeholders.zip-code' | translate}}:</div>
      <div class="app__resume-row-value">{{legalEntityInformation.businessInformation.legalAddress.zipcode}}</div>
    </div>
    <div class="app__resume-row-item">
      <div class="app__resume-row-key">{{'placeholders.country' | translate}}:</div>
      <div class="app__resume-row-value">{{legalEntityInformation.businessInformation.legalAddress.country}}</div>
    </div>
  </div>
  <div class="app__resume-row">
    <div class="app__resume-row-item">
      <div class="app__resume-row-key">{{'placeholders.state' | translate}}:</div>
      <div class="app__resume-row-value">{{legalEntityInformation.businessInformation.legalAddress.state}}</div>
    </div>
    <div class="app__resume-row-item">
      <div class="app__resume-row-key">{{'placeholders.city' | translate}}:</div>
      <div class="app__resume-row-value">{{legalEntityInformation.businessInformation.legalAddress.city}}</div>
    </div>
    <div class="app__resume-row-item app__resume-row-item_no-mobile-border"></div>
  </div>
  <div class="app__resume-title">{{'legal-entity-information.business-address' | translate}}*</div>
  <div class="app__resume-row">
    <div class="app__resume-row-item">
      <div class="app__resume-row-key">{{'placeholders.street-name' | translate}}:</div>
      <div class="app__resume-row-value">{{legalEntityInformation.businessInformation.businessAddress.address1}}</div>
    </div>
    <div class="app__resume-row-item">
      <div class="app__resume-row-key">{{'placeholders.zip-code' | translate}}:</div>
      <div class="app__resume-row-value">{{legalEntityInformation.businessInformation.businessAddress.zipcode}}</div>
    </div>
    <div class="app__resume-row-item">
      <div class="app__resume-row-key">{{'placeholders.country' | translate}}:</div>
      <div class="app__resume-row-value">{{legalEntityInformation.businessInformation.businessAddress.country}}</div>
    </div>
  </div>
  <div class="app__resume-row">
    <div class="app__resume-row-item">
      <div class="app__resume-row-key">{{'placeholders.state' | translate}}:</div>
      <div class="app__resume-row-value">{{legalEntityInformation.businessInformation.businessAddress.state}}</div>
    </div>
    <div class="app__resume-row-item">
      <div class="app__resume-row-key">{{'placeholders.city' | translate}}:</div>
      <div class="app__resume-row-value">{{legalEntityInformation.businessInformation.businessAddress.city}}</div>
    </div>
    <div class="app__resume-row-item app__resume-row-item_no-mobile-border"></div>
  </div>
</div>
