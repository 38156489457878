import { Component } from '@angular/core';

@Component({
  selector: 'rs-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['footer.component.scss']
})
export class FooterComponent {

  public openFile(fileName: string): void {
    window.open(`../../assets/files/${fileName}.pdf`);
  }

}
