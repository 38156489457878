import { ChangeDetectorRef, Component } from '@angular/core';

@Component({
  selector: 'rs-set-up-home',
  templateUrl: 'set-up-home.component.html',
  styleUrls: ['set-up-home.component.scss']
})
export class SetUpHomeComponent {

  public started: boolean;

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  public startGuide(): void {
    this.started = true;
    setTimeout(() => {
      this.changeDetectorRef.markForCheck();
    }, 100);
  }

}
