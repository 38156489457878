import { NgModule } from '@angular/core';

import { NgxsModule } from '@ngxs/store';

import { NavigationButtonsModule } from '../shared/components/navigation-buttons/navigation-buttons.module';
import { GoToDocusignPopupModule } from '../shared/components/popups/go-to-docusign/go-to-docusign.module';
import { MaterialModule } from '../shared/modules/material.module';
import { SharedModule } from '../shared/modules/shared.module';
import { ApplicantInformationComponent } from './components/applicant-information/applicant-information.component';
import { LeaderEmailComponent } from './components/leader-email/leader-email.component';
import { WhoSigningComponent } from './components/who-signing/who-signing.component';
import { ApplicantInformationState } from './ngxs/applicant-information.state';

@NgModule({
  imports: [
    SharedModule,
    MaterialModule,
    NavigationButtonsModule,
    NgxsModule.forFeature([ApplicantInformationState]),
    GoToDocusignPopupModule
  ],
  declarations: [
    ApplicantInformationComponent,
    WhoSigningComponent,
    LeaderEmailComponent
  ],
  exports: [
    ApplicantInformationComponent,
  ]
})
export class ApplicantInformationModule {}
