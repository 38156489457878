import { Component, Inject } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'rs-delete-owner',
  templateUrl: 'delete-owner.component.html',
  styleUrls: ['delete-owner.component.scss']
})
export class DeleteOwnerPopupComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public view: boolean
  ) {}

}
