import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { STEPS } from 'src/modules/shared/constants/steps';
import { BusinessEntityStatuses, ProfileRoles, StepStatuses } from 'src/modules/shared/enums';
import { Address } from 'src/modules/shared/models/address.model';
import { CompanyData } from 'src/modules/shared/models/forms/company.model';
import { LegalEntityInformationData } from 'src/modules/shared/models/forms/legal-entity-information.model';
import { SharedDataService } from 'src/modules/shared/services/shared-data.service';
import { SetStepStatus } from '../ngxs/dashboard.actions';
import { IndividualData } from 'src/modules/shared/models/forms/individual.model';
import { SalesInformation } from 'src/modules/shared/models/sales-information.model';
import { BankingInformation } from 'src/modules/shared/models/banking-information.model';
import { BusinessEntityExtended, Principals } from '../../shared/models/business-entity.model';
import { RouterService } from 'src/modules/shared/services/router.service';
import { PAGES } from 'src/modules/shared/constants/pages';
import { convertPriceToStandardView, getCountryByCode } from 'src/modules/shared/helpers';

@Injectable({
  providedIn: 'root'
})
export class DataRecoveringService {

  constructor(
    private store: Store,
    private sharedDataService: SharedDataService,
    private routerService: RouterService
  ) {}

  public recoverValidationState(businessEntity: BusinessEntityExtended): void {
    if (businessEntity.status !== BusinessEntityStatuses.notValidated) {
      this.sharedDataService.validationCompleted = true;
      this.store.dispatch(new SetStepStatus(STEPS.TERMS_AND_AGREEMENT, StepStatuses.Done));
      this.routerService.navigateToPage(PAGES.SUCCESS);
    }
  }

  public checkApplicantInformation(): void {
    if (this.sharedDataService.userProfile.businessEntities[this.sharedDataService.businessEntityId] === ProfileRoles.leader) {
      this.sharedDataService.canISignDocuments = true;
      this.store.dispatch(new SetStepStatus(STEPS.APPLICANT_INFORMATION, StepStatuses.Done));
    }
  }

  public checkSalesInformation(businessEntity: BusinessEntityExtended): void {
    if (businessEntity.salesInformation.monthlyTotalRevenue !== 0) {
      const salesInformation: SalesInformation = {
        monthlyTotalRevenue: convertPriceToStandardView(businessEntity.salesInformation.monthlyTotalRevenue),
        monthlyTotalCardRevenue: convertPriceToStandardView(businessEntity.salesInformation.monthlyTotalCardRevenue),
        cardPresentTransactions: businessEntity.salesInformation.cardPresentTransactions,
        floorServersAmount: businessEntity.salesInformation.floorServersAmount,
        highestCardTransactionYear: convertPriceToStandardView(businessEntity.salesInformation.highestCardTransactionYear),
        internetTransactions: businessEntity.salesInformation.internetTransactions,
        kitchenStationsCount: businessEntity.salesInformation.kitchenStationsCount,
        mailPhoneTransactions: businessEntity.salesInformation.mailPhoneTransactions,
        tablesCount: businessEntity.salesInformation.tablesCount,
        averageCheck: convertPriceToStandardView(businessEntity.salesInformation.averageCheck),
        newRestaurant: businessEntity.salesInformation.newRestaurant
      };

      this.sharedDataService.salesInformation = salesInformation;
      this.sharedDataService.newRestaurant = salesInformation.newRestaurant;
      this.store.dispatch(new SetStepStatus(STEPS.SALES_INFORMATION, StepStatuses.Done));
    }
  }

  public checkBankingInformation(businessEntity: BusinessEntityExtended): void {
    if (businessEntity.bankingInformation.accountName) {
      const bankingInformation: BankingInformation = {
        accountName: businessEntity.bankingInformation.accountName,
        accountNumber: businessEntity.bankingInformation.accountNumber,
        accountType: businessEntity.bankingInformation.accountType,
        routingNumber: businessEntity.bankingInformation.routingNumber
      };

      this.sharedDataService.bankingInformation = bankingInformation;
      this.store.dispatch(new SetStepStatus(STEPS.BANKING_INFORMATION, StepStatuses.Done));
    }
  }

  public checkLegalEntityInformation(businessEntity: BusinessEntityExtended): void {
    const legalEntityInformation: LegalEntityInformationData = {
      category: businessEntity.businessInformation.category,
      legalEntityName: businessEntity.businessInformation.legalEntityName,
      name: businessEntity.name,
      sein: businessEntity.businessInformation.sein,
      tin: businessEntity.businessInformation.tin,
      url: businessEntity.url
    };
    const legalAddress: Address = {
      address1: businessEntity.businessInformation.legalAddress.address1,
      address2: businessEntity.businessInformation.legalAddress.address2,
      city: businessEntity.businessInformation.legalAddress.city,
      country: businessEntity.businessInformation.legalAddress.country,
      state: businessEntity.businessInformation.legalAddress.state.split('-')[1],
      zipcode: businessEntity.businessInformation.legalAddress.zipcode
    };
    const businessAddress: Address = {
      address1: businessEntity.businessInformation.businessAddress?.address1,
      address2: businessEntity.businessInformation.businessAddress?.address2,
      city: businessEntity.businessInformation.businessAddress?.city,
      country: businessEntity.businessInformation.businessAddress?.country,
      state: businessEntity.businessInformation.businessAddress?.state.split('-')[1],
      zipcode: businessEntity.businessInformation.businessAddress?.zipcode
    };
    this.store.dispatch(new SetStepStatus(STEPS.LEGAL_ENTITY_INFORMATION, StepStatuses.Done));
    this.sharedDataService.legalEntityInformation = legalEntityInformation;
    this.sharedDataService.legalAddress = legalAddress;
    this.sharedDataService.businessAddress = businessAddress;
  }

  public checkOwnersInformation(businessEntity: BusinessEntityExtended): void {
    const businessEntityHasCompanies: boolean = !!businessEntity.principals?.companies?.length;
    const businessEntityHasIndividual: boolean = !!businessEntity.principals?.individual?.length;

    if (businessEntityHasCompanies || businessEntityHasIndividual) {
      this.store.dispatch(new SetStepStatus(STEPS.OWNERS_INFORMATION, StepStatuses.Done));

      if (businessEntityHasCompanies) {
        this.recoverCompanyOwners(businessEntity.principals);
      }
      if (businessEntityHasIndividual) {
        this.recoverIndividuals(businessEntity.principals);
      }
    }
  }

  private recoverIndividuals(principals: Principals): void {
    const individualOwners: IndividualData[] = [];

    principals.individual.forEach(individualOwnerFromBackend => {
      const individualData: IndividualData = {
        dateOfBirth: individualOwnerFromBackend.dateOfBirth,
        share: String(individualOwnerFromBackend.share),
        documentDetails: {
          ...individualOwnerFromBackend.documentDetails,
          issuingState: individualOwnerFromBackend.documentDetails.issuingState ? individualOwnerFromBackend.documentDetails.issuingState.split('-')[1] : ''
        },
        email: individualOwnerFromBackend.email,
        firstName: individualOwnerFromBackend.firstName,
        lastName: individualOwnerFromBackend.lastName,
        nationality: individualOwnerFromBackend.nationality,
        phoneNumber: individualOwnerFromBackend.phoneNumber,
        residingCountry: individualOwnerFromBackend.residingCountry,
        secondaryNationality: individualOwnerFromBackend.secondaryNationality,
        socialSecurityNumber: individualOwnerFromBackend.socialSecurityNumber,
        legalAddress: {
          ...individualOwnerFromBackend.legalAddress,
          country: getCountryByCode(individualOwnerFromBackend.legalAddress.country),
          state: individualOwnerFromBackend.legalAddress.state.split('-')[1]
        }
      };
      individualOwners.push(individualData);
    });

    this.sharedDataService.individualOwners = individualOwners;
  }

  private recoverCompanyOwners(principals: Principals): void {
    const companyOwners: CompanyData[] = [];

    principals.companies.forEach(companyOwnerFromBackend => {
      const companyData: CompanyData = {
        businessName: companyOwnerFromBackend.businessName,
        email: companyOwnerFromBackend.email,
        firstName: companyOwnerFromBackend.firstName,
        lastName: companyOwnerFromBackend.lastName,
        phone: companyOwnerFromBackend.phone,
        share: String(companyOwnerFromBackend.share),
        tin: companyOwnerFromBackend.tin,
        address: {
          ...companyOwnerFromBackend.address,
          country: getCountryByCode(companyOwnerFromBackend.address.country),
          state: companyOwnerFromBackend.address.state.split('-')[1]
        }
      };
      companyOwners.push(companyData);
    });

    this.sharedDataService.companyOwners = companyOwners;
  }

}
