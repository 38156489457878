import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { SharedDataService } from '../services/shared-data.service';
import { RouterService } from '../services/router.service';
import { PAGES } from '../constants/pages';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {

  constructor(
    private sharedDataService: SharedDataService,
    private routerService: RouterService
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const authentication = this.sharedDataService.authenticationData;

    if (authentication) {
      return true;
    } else {
      this.redirectToAuthenticationPage();

      return false;
    }
  }

  private redirectToAuthenticationPage(): void {
    this.routerService.navigateToPage(PAGES.SIGN_IN);
  }

}
