<div class="rs-sales-information">
  <div class="app__section" [formGroup]="salesInformationForm">
    <div class="app__step-title">{{'titles.sales-information' | translate}}</div>
    <mat-checkbox
      [checked]="newRestaurant"
      (change)="reactOnNewRestaurantChange($event)"
      color="primary"
    >
      {{'sales-information.new-restaurant' | translate}}
    </mat-checkbox>

    <div class="app__controls">
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.monthly-total-revenue' | translate}}*</mat-label>
        <input currencyMask formControlName="monthlyTotalRevenue" matInput placeholder="{{'placeholders.monthly-total-revenue' | translate}}">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.monthly-card-revenue' | translate}}*</mat-label>
        <input currencyMask formControlName="monthlyTotalCardRevenue" matInput placeholder="{{'placeholders.monthly-card-revenue' | translate}}">
      </mat-form-field>
    </div>
    <div class="app__controls">
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.average-check' | translate}}*</mat-label>
        <input currencyMask formControlName="averageCheck" matInput placeholder="{{'placeholders.average-check' | translate}}">
      </mat-form-field>
      <rs-percent-control
        [isTouched]="isTouched"
        placeholder="{{'placeholders.percentage-of-phone' | translate}}"
        [rsFormControl]="salesInformationForm.controls.mailPhoneTransactions"
      >
        <div class="app__controls-hint">
          <rs-hint content="{{'sales-information.customers-that-pay' | translate}}"></rs-hint>
        </div>
      </rs-percent-control>
    </div>
    <div class="app__controls">
      <rs-percent-control
        [isTouched]="isTouched"
        placeholder="{{'placeholders.percentage-of-internet' | translate}}"
        [rsFormControl]="salesInformationForm.controls.internetTransactions"
      >
        <div class="app__controls-hint">
          <rs-hint content="{{'sales-information.card-percentage' | translate}}"></rs-hint>
        </div>
      </rs-percent-control>
      <rs-percent-control
        [isTouched]="isTouched"
        placeholder="{{'placeholders.percentage-of-card' | translate}}"
        [rsFormControl]="salesInformationForm.controls.cardPresentTransactions"
      ></rs-percent-control>
    </div>
    <div class="app__controls">
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.highest-card' | translate}}*</mat-label>
        <input currencyMask formControlName="highestCardTransactionYear" matInput placeholder="{{'placeholders.highest-card' | translate}}">
      </mat-form-field>
      <div></div>
    </div>
  </div>
  <div class="app__section" [formGroup]="restaurantSetUpForm">
    <div class="app__step-title">{{'sales-information.restaurant-set-up' | translate}}</div>
    <div class="app__controls">
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.number-of-tables' | translate}}*</mat-label>
        <input rsNumericControl [onlyInt]="true" formControlName="tablesCount" matInput placeholder="{{'placeholders.number-of-tables' | translate}}">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.maximum-number' | translate}}*</mat-label>
        <input rsNumericControl [onlyInt]="true" formControlName="floorServersAmount" matInput placeholder="{{'placeholders.maximum-number' | translate}}">
      </mat-form-field>
    </div>
    <div class="app__controls">
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.number-of-kitchen' | translate}}*</mat-label>
        <input rsNumericControl [onlyInt]="true" formControlName="kitchenStationsCount" matInput placeholder="{{'placeholders.number-of-kitchen' | translate}}">
      </mat-form-field>
    </div>
  </div>
  <div class="rs-sales-information__navigation">
    <rs-navigation-buttons (clickDashboard)="goToDashboard()" (clickNext)="goNext()"></rs-navigation-buttons>
  </div>
</div>
