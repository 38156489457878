import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { Spinner } from 'src/modules/shared/classes/spinner.class';
import { DocuSignModes, StepStatuses } from 'src/modules/shared/enums';
import { copy, isFormTouched } from 'src/modules/shared/helpers';
import { DocuSign } from 'src/modules/shared/models/docu-sign.model';
import { UserProfile } from 'src/modules/shared/models/user-profile.model';
import { SharedDataService } from 'src/modules/shared/services/shared-data.service';
import { DashboardService } from '../services/dashboard.service';
import {
  ActivateProfile,
  CheckSigningState,
  CheckStepStatus,
  ClearStepStatuses,
  DataRecovering,
  GetUserInformation,
  InitCachedSteps,
  RedirectToSignedDocument,
  SetStepStatus,
  Submit,
  ViewSignedDocument
} from './dashboard.actions';
import { STEPS } from 'src/modules/shared/constants/steps';
import { BusinessEntityExtended, BusinessEntityReduced } from 'src/modules/shared/models/business-entity.model';
import { RouterService } from 'src/modules/shared/services/router.service';
import { PAGES } from 'src/modules/shared/constants/pages';
import { ApplicantInformationService } from 'src/modules/applicant-information/applicant-information.service';
import { DataRecoveringService } from '../services/data-recovering.service';
import { ShowNotificationsService } from 'src/modules/shared/services/show-notifications.service';

interface DashboardStateModel {
  legalEntityInformationStatus: StepStatuses;
  ownersInformationStatus: StepStatuses;
  bankingInformationStatus: StepStatuses;
  salesInformationStatus: StepStatuses;
  applicantInformationStatus: StepStatuses;
  termsAndAgreementStatus: StepStatuses;
  signUrl: string;
  viewSignedUrl: string;
}

const defaultDashboardState = {
  legalEntityInformationStatus: StepStatuses.Untouched,
  ownersInformationStatus: StepStatuses.Untouched,
  bankingInformationStatus: StepStatuses.Untouched,
  salesInformationStatus: StepStatuses.Untouched,
  applicantInformationStatus: StepStatuses.Untouched,
  termsAndAgreementStatus: StepStatuses.Untouched,
  signUrl: null,
  viewSignedUrl: null
}

@State<DashboardStateModel>({
  name: 'dashboard',
  defaults: copy(defaultDashboardState)
})
@Injectable()
export class DashboardState extends Spinner {

  constructor(
    protected store: Store,
    private dashboardService: DashboardService,
    private applicantInformationService: ApplicantInformationService,
    private sharedDataService: SharedDataService,
    private routerService: RouterService,
    private dataRecoveringService: DataRecoveringService,
    private showNotificationsService: ShowNotificationsService
  ) {
    super(store);
  }

  @Selector()
  static legalEntityInformationStatus({legalEntityInformationStatus}: DashboardStateModel): StepStatuses {
    return legalEntityInformationStatus;
  }

  @Selector()
  static bankingInformationStatus({bankingInformationStatus}: DashboardStateModel): StepStatuses {
    return bankingInformationStatus;
  }

  @Selector()
  static salesInformationStatus({salesInformationStatus}: DashboardStateModel): StepStatuses {
    return salesInformationStatus;
  }

  @Selector()
  static viewSignedUrl({viewSignedUrl}: DashboardStateModel): string {
    return viewSignedUrl;
  }

  @Action(ActivateProfile)
  activateProfile(stateContext, {token}: ActivateProfile) {
    this.showSpinner();

    return this.dashboardService.activateProfile(token)
      .pipe(
        tap({
          next: () => {
            this.showNotificationsService.showProfileWasActivated();
            this.routerService.navigateToPage(PAGES.SIGN_IN);
          },
          complete: () => this.hideSpinner()
        })
      );
  }

  @Action(DataRecovering)
  dataRecovering() {
    if (!this.sharedDataService.businessEntityId) {
      this.showSpinner();

      return this.dashboardService.getBusinessEntities()
        .pipe(
          switchMap((businessEntities: BusinessEntityReduced[]) => {
            if (businessEntities.length) {
              this.sharedDataService.businessEntityId = businessEntities.reverse()[0].id;

              return this.dashboardService.getBusinessEntityById(this.sharedDataService.businessEntityId);
            }

            return of(null);
          }),
          tap({
            next: (businessEntity: BusinessEntityExtended) => {
              if (businessEntity) {
                this.dataRecoveringService.checkLegalEntityInformation(businessEntity);
                this.dataRecoveringService.checkOwnersInformation(businessEntity);
                this.dataRecoveringService.checkBankingInformation(businessEntity);
                this.dataRecoveringService.checkSalesInformation(businessEntity);
                this.dataRecoveringService.checkApplicantInformation();
                this.dataRecoveringService.recoverValidationState(businessEntity);
              }
            },
            complete: () => this.hideSpinner()
          })
        );
    }
  }

  @Action(Submit)
  submit() {
    this.showSpinner();

    return this.dashboardService.submit(this.sharedDataService.businessEntityId)
      .pipe(
        tap({
          next: () => {
            this.routerService.navigateToPage(PAGES.SUCCESS);
            this.sharedDataService.validationCompleted = true;
          },
          complete: () => this.hideSpinner()
        })
      );
  }

  @Action(ViewSignedDocument)
  viewSignedDocument({patchState, getState}: StateContext<DashboardStateModel>) {
    this.showSpinner();

    if (this.sharedDataService.businessEntityId) {
      return this.applicantInformationService.getDocumentSignUrl(this.sharedDataService.businessEntityId, DocuSignModes.view)
        .pipe(
          tap({
            next: (response: DocuSign) => {
              if (response.signed) {
                if (this.areAllStepsCompleted(getState())) {
                  this.routerService.navigateToPage(PAGES.RESUME);
                }

                this.store.dispatch(new SetStepStatus(STEPS.TERMS_AND_AGREEMENT, StepStatuses.Done));
              }

              patchState({
                viewSignedUrl: response.url
              });
            },
            complete: () => this.hideSpinner()
          })
        );
    } else {
      this.hideSpinner();
    }
  }

  @Action(RedirectToSignedDocument)
  redirectToSignedDocument() {
    this.showSpinner();
    const url = this.store.selectSnapshot(DashboardState.viewSignedUrl);
    url ? document.location.replace(url) : this.hideSpinner();
  }

  @Action(CheckSigningState)
  checkSigningState({patchState}: StateContext<DashboardStateModel>) {
    if (this.sharedDataService.businessEntityId) {
      this.showSpinner();

      return this.applicantInformationService.getDocumentSignUrl(this.sharedDataService.businessEntityId, DocuSignModes.sign)
        .pipe(
          tap({
            next: (signingUrl: DocuSign) => {
              this.hideSpinner();
              patchState({
                signUrl: signingUrl.url
              });
            }
          })
        );
    }
  }

  @Action(GetUserInformation)
  getUserInformation() {
    return this.dashboardService.getUserInformation()
      .pipe(
        tap({
          next: (userProfile: UserProfile) => {
            this.sharedDataService.userProfile = userProfile;
          }
        })
      );
  }

  @Action(InitCachedSteps)
  initCachedSteps({patchState}: StateContext<DashboardStateModel>) {
    patchState({
      ...this.sharedDataService.steps
    });
  }

  @Action(CheckStepStatus)
  checkStepStatus(stateContext, {forms, stepName}: CheckStepStatus) {
    if (forms.every(form => form.valid)) {
      this.store.dispatch(new SetStepStatus(stepName, StepStatuses.Done));
    } else
    if (forms.some(form => isFormTouched(form))) {
      this.store.dispatch(new SetStepStatus(stepName, StepStatuses.InProgress));
    } else {
      this.store.dispatch(new SetStepStatus(stepName, StepStatuses.Untouched));
    }
  }

  @Action(SetStepStatus)
  setStepStatus({patchState, getState}: StateContext<DashboardStateModel>, {stepName, stepStatus}: SetStepStatus) {
    patchState({
      ...this.sharedDataService.steps
    });
    patchState({
      [`${stepName}Status`]: stepStatus
    });
    this.sharedDataService.steps = getState();
  }

  @Action(ClearStepStatuses)
  clearStepStatuses({patchState, getState}: StateContext<DashboardStateModel>) {
    patchState(copy(defaultDashboardState));
  }

  private areAllStepsCompleted(state: DashboardStateModel): boolean {
    return state.applicantInformationStatus === StepStatuses.Done &&
           state.bankingInformationStatus === StepStatuses.Done &&
           state.legalEntityInformationStatus === StepStatuses.Done &&
           state.ownersInformationStatus === StepStatuses.Done &&
           state.salesInformationStatus === StepStatuses.Done &&
           state.termsAndAgreementStatus !== StepStatuses.Done;
  }

}
