import { NgModule } from '@angular/core';

import { SharedModule } from '../../modules/shared.module';
import { PasswordStrengthComponent } from './password-strength.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    PasswordStrengthComponent
  ],
  exports: [
    PasswordStrengthComponent
  ]
})
export class PasswordStrengthModule {}
