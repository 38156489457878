export const REGEX = {
  LINK: /^[a-z0-9\-_+:/]+\.[a-z0-9. \-_+]+$/i,
  EMAIL: /^[a-z0-9.\-_+]+@[a-z0-9\-_+]+\.[a-z0-9. \-_+]+$/i,
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  UPPER_CASE: /(?=.*[A-Z])/,
  LOWER_CASE: /(?=.*[a-z])/,
  NINE_CHARACTERS: /[a-zA-Z\d@$#!%*?&^()-=_+]{9,}/,
  NUMBER: /(?=.*\d)/,
  SPECIAL_SYMBOL: /[!@#$%^&*(),.?":{}|<>]/
};
