import { Component } from '@angular/core';
import { SharedDataService } from 'src/modules/shared/services/shared-data.service';

@Component({
  selector: 'rs-resume-leadership-information',
  templateUrl: 'resume-leadership-information.component.html',
  styleUrls: ['resume-leadership-information.component.scss']
})
export class ResumeLeadershipInformationComponent {

  constructor(
    private sharedDataService: SharedDataService
  ) {}

  public me(): boolean {
    return this.sharedDataService.canISignDocuments !== null && this.sharedDataService.canISignDocuments;
  }

}
