import { NgModule } from '@angular/core';

import { ClickAndHoldDirective } from './click-and-hold.directive';
import { EmailDirective } from './email.directive';
import { NumericControlDirective } from './numeric-control.directive';

@NgModule({
  declarations: [
    ClickAndHoldDirective,
    NumericControlDirective,
    EmailDirective
  ],
  exports: [
    ClickAndHoldDirective,
    NumericControlDirective,
    EmailDirective
  ]
})
export class DirectivesModule {}
