import { NgModule } from '@angular/core';
import { MaterialModule } from '../../modules/material.module';

import { SharedModule } from '../../modules/shared.module';
import { ControlWithMaskModule } from '../control-with-mask/control-with-mask.module';
import { AddressFormComponent } from './address-form.component';

@NgModule({
  imports: [
    SharedModule,
    MaterialModule,
    ControlWithMaskModule
  ],
  declarations: [
    AddressFormComponent
  ],
  exports: [
    AddressFormComponent
  ]
})
export class AddressFormModule {}
