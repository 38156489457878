<div class="rs-owner-actions">
  <button
    mat-raised-button
    color="primary"
    class="app__button"
    (click)="cancel()"
  >
    {{'buttons.cancel' | translate}}
  </button>
  <button
    mat-raised-button
    color="primary"
    class="app__button"
    (click)="add()"
    [disabled]="isAddButtonDisabled"
  >
    {{getAcceptButtonTitle() | translate}}
  </button>
</div>
