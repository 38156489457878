import { FormGroup } from '@angular/forms';

export const confirmPasswordValidator = (form: FormGroup) => {
  const { password, confirm } = form.controls;

  if (password.value !== confirm.value) {
    confirm.setErrors({
      match: 'Passwords don`t match!'
    });
  }

  return;
};
