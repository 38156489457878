import { NgModule } from '@angular/core';

import { DirectivesModule } from 'src/modules/shared/directives/directives.module';
import { MaterialModule } from 'src/modules/shared/modules/material.module';
import { SharedModule } from 'src/modules/shared/modules/shared.module';
import { PopupModule } from '../base-popup/popup.module';
import { ProfileNotActiveComponent } from './profile-not-active.component';

@NgModule({
  imports: [
    SharedModule,
    PopupModule,
    MaterialModule,
    DirectivesModule
  ],
  declarations: [
    ProfileNotActiveComponent
  ],
  exports: [
    ProfileNotActiveComponent
  ]
})
export class ProfileNotActiveModule {}
