<div class="rs-email">
  <div class="rs-email__header">
    <div class="rs-email__header-logo">
      <img src="assets/img/rockspoon-title-white.svg">
    </div>
  </div>
  <div class="rs-email__content">
    <div class="rs-email__greeting">
      <div class="rs-email__greeting-item">{{'devices.main.hello' | translate}} <b>Mario</b>!</div>
      <div class="rs-email__greeting-item">{{'email.good-news' | translate}}</div>
      <div class="rs-email__greeting-item">{{'email.set-up' | translate}}</div>
    </div>
    <div class="rs-email__guide">
      <div class="rs-email__guide-item">
        <div class="rs-email__guide-item-images">
          <div class="rs-email__guide-item-image">
            <img src="../../../assets/img/router.svg">
          </div>
        </div>
        <div class="rs-email__guide-item-content">
          <div class="rs-email__guide-item-content-title">1. {{'devices.turn-on-router.title' | translate}}</div>
          <div class="rs-email__guide-item-content-description">{{'devices.turn-on-router.content' | translate}}</div>
        </div>
      </div>
      <div class="rs-email__guide-item">
        <div class="rs-email__guide-item-images">
          <div class="rs-email__guide-item-image">
            <img src="../../../assets/img/routers.png">
          </div>
        </div>
        <div class="rs-email__guide-item-content">
          <div class="rs-email__guide-item-content-title">2. {{'devices.connect-isp.connect' | translate}}</div>
          <div class="rs-email__guide-item-content-description">{{'devices.connect-isp.subtitle' | translate}}</div>
        </div>
      </div>
      <div class="rs-email__guide-item">
        <div class="rs-email__guide-item-images">
          <div class="rs-email__guide-item-image">
            <img src="../../../assets/img/tablet.png">
          </div>
        </div>
        <div class="rs-email__guide-item-content">
          <div class="rs-email__guide-item-content-title">3. {{'devices.turn-on-mobile' | translate}}</div>
        </div>
      </div>
      <div class="rs-email__guide-item">
        <div class="rs-email__guide-item-images">
          <div class="rs-email__guide-item-image">
            <img src="../../../assets/img/tablet-hand.png">
          </div>
        </div>
        <div class="rs-email__guide-item-content">
          <div class="rs-email__guide-item-content-title">4. {{'devices.click-app' | translate}}</div>
        </div>
      </div>
    </div>
    <div class="rs-email__qr-code">
      <div class="rs-email__qr-code-title">{{'devices.qr.title' | translate}}</div>
      <div class="rs-email__qr-code-value">
        <img src="../../../assets/img/qr-code.png">
      </div>
      <div class="rs-email__qr-code-description">{{'devices.qr.content' | translate}}</div>
    </div>
    <div class="rs-email__link">
      {{'email.or-go-to' | translate}} <a href="#">rs.io/3456</a> {{'email.to-get-started' | translate}}
    </div>
    <div class="rs-email__logo">
      <img src="assets/img/rockspoon-title-gray.png">
    </div>
    <div class="rs-email__address">www.rockspoon.com</div>
  </div>
</div>
