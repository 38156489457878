import { Component, OnDestroy } from '@angular/core';

import { Store } from '@ngxs/store';

import { SetStepStatus } from 'src/modules/dashboard/ngxs/dashboard.actions';
import { Spinner } from 'src/modules/shared/classes/spinner.class';
import { PAGES } from 'src/modules/shared/constants/pages';
import { STEPS } from 'src/modules/shared/constants/steps';
import { StepStatuses } from 'src/modules/shared/enums';
import { RouterService } from 'src/modules/shared/services/router.service';
import { SharedDataService } from 'src/modules/shared/services/shared-data.service';
import { MakeMeAsBusinessLeader } from '../../ngxs/applicant-information.actions';

@Component({
  selector: 'rs-who-signing',
  templateUrl: 'who-signing.component.html',
  styleUrls: ['who-signing.component.scss']
})
export class WhoSigningComponent extends Spinner implements OnDestroy {

  public leaderEmailVisibility: boolean;

  constructor(
    private routerService: RouterService,
    public store: Store,
    private sharedDataService: SharedDataService
  ) {
    super(store);
  }

  public me(): void {
    this.store.dispatch(new MakeMeAsBusinessLeader());
  }

  public notMe(): void {
    this.leaderEmailVisibility = true;
    this.sharedDataService.canISignDocuments = false;
    this.store.dispatch(new SetStepStatus(STEPS.APPLICANT_INFORMATION, StepStatuses.Done));
    this.store.dispatch(new SetStepStatus(STEPS.TERMS_AND_AGREEMENT, StepStatuses.Blocked));
  }

  public goToDashboard(): void {
    this.routerService.navigateToPage(PAGES.DASHBOARD);
  }

  public ngOnDestroy(): void {
    this.hideSpinner();
  }

}
