import { Component, OnInit } from '@angular/core';

import { BankingInformation } from 'src/modules/shared/models/banking-information.model';
import { SharedDataService } from 'src/modules/shared/services/shared-data.service';

@Component({
  selector: 'rs-resume-banking-information',
  templateUrl: 'resume-banking-information.component.html',
  styleUrls: ['resume-banking-information.component.scss']
})
export class ResumeBankingInformationComponent implements OnInit {

  public cachedBankingInformation: BankingInformation;

  constructor(
    private sharedDataService: SharedDataService
  ) {}

  ngOnInit() {
    this.getCachedBankingInformation();
  }

  private getCachedBankingInformation() {
    this.cachedBankingInformation = this.sharedDataService.bankingInformation;
  }

}
