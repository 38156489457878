export class SalesInformation {

  public monthlyTotalCardRevenue: number;
  public monthlyTotalRevenue: number;
  public cardPresentTransactions: number;
  public floorServersAmount: number;
  public highestCardTransactionYear: number;
  public internetTransactions: number;
  public kitchenStationsCount: number;
  public mailPhoneTransactions: number;
  public tablesCount: number;
  public averageCheck: number;
  public newRestaurant: boolean;

  constructor(salesInformation: SalesInformation) {
    if (salesInformation) {
      this.monthlyTotalCardRevenue = Number(salesInformation.monthlyTotalCardRevenue);
      this.monthlyTotalRevenue = Number(salesInformation.monthlyTotalRevenue);
      this.cardPresentTransactions = Number(salesInformation.cardPresentTransactions);
      this.floorServersAmount = Number(salesInformation.floorServersAmount);
      this.highestCardTransactionYear = Number(salesInformation.highestCardTransactionYear);
      this.internetTransactions = Number(salesInformation.internetTransactions);
      this.kitchenStationsCount = Number(salesInformation.kitchenStationsCount);
      this.mailPhoneTransactions = Number(salesInformation.mailPhoneTransactions);
      this.tablesCount = Number(salesInformation.tablesCount);
      this.averageCheck = Number(salesInformation.averageCheck);
    } else {
      this.monthlyTotalCardRevenue = 0;
      this.monthlyTotalRevenue = 0;
      this.cardPresentTransactions = 0;
      this.floorServersAmount = 0;
      this.highestCardTransactionYear = 0;
      this.internetTransactions = 0;
      this.kitchenStationsCount = 0;
      this.mailPhoneTransactions = 0;
      this.tablesCount = 0;
      this.averageCheck = 0;
    }
  }

}
