import { NgModule } from '@angular/core';

import { NgxsModule } from '@ngxs/store';

import { NavigationButtonsModule } from '../shared/components/navigation-buttons/navigation-buttons.module';
import { DirectivesModule } from '../shared/directives/directives.module';
import { MaterialModule } from '../shared/modules/material.module';
import { SharedModule } from '../shared/modules/shared.module';
import { BankingInformationComponent } from './component/banking-information.component';
import { BankingInformationState } from './ngxs/banking-information.state';

@NgModule({
  imports: [
    SharedModule,
    MaterialModule,
    NavigationButtonsModule,
    NgxsModule.forFeature([BankingInformationState]),
    DirectivesModule
  ],
  declarations: [
    BankingInformationComponent
  ],
  exports: [
    BankingInformationComponent
  ]
})
export class BankingInformationModule {}
