import { NgModule } from '@angular/core';

import { ProfileActivationComponent } from './profile-activation.component';

@NgModule({
  declarations: [
    ProfileActivationComponent
  ],
  exports: [
    ProfileActivationComponent
  ]
})
export class ProfileActivationModule {}
