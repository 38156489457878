import { Injectable, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouterService {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ngZone: NgZone
  ) {}

  public navigateToPage(path: string): void {
    this.ngZone.run(() => {
      this.router.navigate([path]);
    });
  }

  public navigateToPageWithSameQueryParams(path: string): void {
    const queryParams = this.route.snapshot.queryParams;

    this.ngZone.run(() => {
      this.router.navigate([path], {queryParams});
    });
  }

  public navigateToPageWithQueryParams(path: string, queryParams: any): void {
    this.ngZone.run(() => {
      this.router.navigate([path], {queryParams});
    });
  }

  public navigateToPageWithState(path: string, stateData: any): void {
    this.ngZone.run(() => {
      this.router.navigate([path], {state: {stateData}});
    });
  }

}
