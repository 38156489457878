import { NgModule } from '@angular/core';

import { ClickOutsideModule } from 'ng-click-outside';

import { MaterialModule } from 'src/modules/shared/modules/material.module';
import { SharedModule } from '../../shared/modules/shared.module';
import { HeaderComponent } from '../components/header/header.component';
import { LayoutComponent } from '../components/layout/layout.component';
import { LayoutRoutingModule } from './layout-routing.module';
import { OwnersModule } from 'src/modules/owners/owners.module';
import { BankingInformationModule } from 'src/modules/banking-information/banking-information.module';
import { SalesInformationModule } from 'src/modules/sales-information/sales-information.module';
import { ResumeModule } from 'src/modules/resume/resume.module';
import { DashboardModule } from 'src/modules/dashboard/dashboard.module';
import { LegalEntityInformationModule } from 'src/modules/legal-entity-information/legal-entity-information.module';
import { ResultModule } from 'src/modules/result/result.module';
import { ApplicantInformationModule } from 'src/modules/applicant-information/applicant-information.module';

@NgModule({
  imports: [
    SharedModule,
    LayoutRoutingModule,
    MaterialModule,
    ClickOutsideModule,
    DashboardModule,
    LegalEntityInformationModule,
    OwnersModule,
    BankingInformationModule,
    SalesInformationModule,
    ApplicantInformationModule,
    ResumeModule,
    ResultModule
  ],
  declarations: [
    LayoutComponent,
    HeaderComponent
  ],
  exports: [
    LayoutComponent
  ]
})
export class LayoutModule {}
