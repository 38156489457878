import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslationsService {

  constructor(
    private translateService: TranslateService
  ) {}

  public get(key: string): string {
    return this.translateService.instant(key);
  }

}
