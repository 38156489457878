import { Pipe, PipeTransform } from '@angular/core';

interface NamedEntity {
  firstName: string;
  lastName: string;
}

@Pipe({
  name: 'rsFullName'
})
export class FullNamePipe implements PipeTransform {

  transform(namedEntity: NamedEntity) {
    return `${namedEntity.firstName} ${namedEntity.lastName}`;
  }

}
