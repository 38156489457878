<div class="rs-individual" [formGroup]="individualForm">
  <div class="rs-individual__section">
    <div class="app__controls">
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.first-name' | translate}}*</mat-label>
        <input formControlName="firstName" matInput placeholder="{{'placeholders.first-name' | translate}}*">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.last-name' | translate}}*</mat-label>
        <input formControlName="lastName" matInput placeholder="{{'placeholders.last-name' | translate}}*">
      </mat-form-field>
    </div>
    <div class="app__controls">
      <rs-phone-control [rsPhoneControl]="individualForm.controls.phoneNumber"></rs-phone-control>
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.email' | translate}}*</mat-label>
        <input formControlName="email" matInput placeholder="{{'placeholders.email' | translate}}*">
      </mat-form-field>
    </div>
    <div class="app__controls">
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.nationality' | translate}}*</mat-label>
        <mat-select formControlName="nationality">
          <mat-option *ngFor="let nationality of nationalities" [value]="nationality">{{nationality}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.secondary-nationality' | translate}}</mat-label>
        <mat-select formControlName="secondaryNationality">
          <mat-option [value]="null">None</mat-option>
          <mat-option *ngFor="let nationality of nationalities" [value]="nationality">{{nationality}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="app__controls">
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.residing-country' | translate}}*</mat-label>
        <mat-select formControlName="residingCountry">
          <mat-option *ngFor="let country of countries" [value]="country.name">{{country.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.birth-date' | translate}}*</mat-label>
        <input [matDatepickerFilter]="mustBeInPast" formControlName="dateOfBirth" matInput type="text" placeholder="{{'placeholders.birth-date' | translate}}*" (click)="picker.open()" [matDatepicker]="picker">
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="app__controls">
      <rs-control-with-mask [rsFormControl]="individualForm.controls.socialSecurityNumber" [placeholder]="'placeholders.social-security-number'" mask="000-00-0000"></rs-control-with-mask>
      <rs-percent-control placeholder="{{'placeholders.share' | translate}}" [rsFormControl]="individualForm.controls.share"></rs-percent-control>
    </div>
    <rs-address-form [rsFormGroup]="individualForm.controls.legalAddress"></rs-address-form>
  </div>
  <div class="app__section" [formGroup]="individualForm.controls.documentDetails">
    <div class="app__section-title">{{'owners.document-info' | translate}}</div>
    <div class="app__controls">
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.document-type' | translate}}*</mat-label>
        <mat-select (selectionChange)="reactOnChooseDocumentType($event)" formControlName="documentType">
          <mat-option *ngFor="let documentType of documentTypes" [value]="documentType.value">{{documentType.key}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="app__controls">
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.expire-date' | translate}}*</mat-label>
        <input [matDatepickerFilter]="mustBeInFuture" formControlName="expireDate" matInput type="text" placeholder="{{'placeholders.expire-date' | translate}}" (click)="picker2.open()" [matDatepicker]="picker2">
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.issue-date' | translate}}*</mat-label>
        <input [matDatepickerFilter]="mustBeInPast" formControlName="issueDate" matInput type="text" placeholder="{{'placeholders.issue-date' | translate}}" (click)="picker3.open()" [matDatepicker]="picker3">
        <mat-datepicker #picker3></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="app__controls">
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.document-issuer' | translate}}*</mat-label>
        <input formControlName="documentIssuer" matInput placeholder="{{'placeholders.document-issuer' | translate}}">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.id-number' | translate}}*</mat-label>
        <input formControlName="idNumber" matInput placeholder="{{'placeholders.id-number' | translate}}*">
      </mat-form-field>
    </div>
    <div class="app__controls">
      <mat-form-field appearance="outline">
        <mat-label>{{'placeholders.issuing-state' | translate}}*</mat-label>
        <mat-select formControlName="issuingState">
          <mat-option *ngFor="let state of states" [value]="state.code">{{state.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <rs-owner-actions [isEdit]="selectedOwner" [isAddButtonDisabled]="isAddButtonDisabled()" (cancelClick)="reactOnCancelClick()" (addClick)="accept()"></rs-owner-actions>
</div>
