<div class="rs-leader-email" [formGroup]="leadInvitationForm">
  <div class="rs-leader-email__control">
    <mat-form-field appearance="outline">
      <mat-label>{{'placeholders.email' | translate}}</mat-label>
      <input formControlName="email" type="email" matInput placeholder="{{'placeholders.email' | translate}}">
    </mat-form-field>
  </div>
  <div class="rs-leader-email__control">
    <mat-form-field appearance="outline">
      <mat-label>{{'placeholders.first-name' | translate}}</mat-label>
      <input formControlName="firstName" type="text" matInput placeholder="{{'placeholders.first-name' | translate}}">
    </mat-form-field>
  </div>
  <div class="rs-leader-email__control">
    <mat-form-field appearance="outline">
      <mat-label>{{'placeholders.last-name' | translate}}</mat-label>
      <input formControlName="lastName" type="text" matInput placeholder="{{'placeholders.last-name' | translate}}">
    </mat-form-field>
  </div>
  <div class="rs-leader-email__send">
    <button mat-raised-button color="primary" class="app__button" [disabled]="leadInvitationForm.invalid" (click)="sendInvite()">{{'buttons.send' | translate}}</button>
  </div>
</div>
