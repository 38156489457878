import { Component, OnInit } from '@angular/core';

import { SalesInformation } from 'src/modules/shared/models/sales-information.model';
import { SharedDataService } from 'src/modules/shared/services/shared-data.service';

@Component({
  selector: 'rs-resume-sales-information',
  templateUrl: 'resume-sales-information.component.html',
  styleUrls: ['resume-sales-information.component.scss']
})
export class ResumeSalesInformationComponent implements OnInit {

  public cachedSalesInformation: SalesInformation;

  constructor(
    private sharedDataService: SharedDataService
  ) {}

  public ngOnInit(): void {
    this.getCachedSalesInformation();
  }

  public isRestaurantNew(): boolean {
    return this.sharedDataService.newRestaurant;
  }

  private getCachedSalesInformation(): void {
    this.cachedSalesInformation = new SalesInformation(this.sharedDataService.salesInformation);
  }

}
