import { Injectable } from '@angular/core';

import { ENDPOINTS } from '../shared/constants/endpoints';
import { SalesInformation } from '../shared/models/sales-information.model';
import { ApiService } from '../shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class SalesInformationService {

  constructor(
    private apiService: ApiService
  ) {}

  public updateSalesInformation(businessEntityId: string, salesInformation: SalesInformation) {
    return this.apiService.put(ENDPOINTS.SALES_INFORMATION(businessEntityId), salesInformation);
  }

}
