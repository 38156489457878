<div class="app__result">
  <div class="app__result-logo">
    <img src="../../../../assets/img/rockspoon-icon-big.png">
  </div>
  <div class="app__result-title">{{'success.title' | translate}}</div>
  <div class="app__result-subtitle">{{'success.subtitle' | translate}}</div>
  <div class="app__result-subtitle">
    {{'success.questions_contacts_info' | translate: {phone: phone, email: email} }}
  </div>
  <div class="app__result-actions" *ngIf="backOfficeButtonVisibility">
    <button mat-raised-button class="app__button" color="primary" (click)="goToBackOffice()">{{'buttons.go-to-backoffice' | translate}}</button>
  </div>
</div>
