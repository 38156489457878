<div class="rs-reset-password">
  <div class="rs-reset-password__logo">
    <img src="assets/img/rockspoon-title.svg">
  </div>
  <div class="rs-reset-password__title">{{'reset-password.title' | translate}}</div>
  <div class="rs-reset-password__form">
    <rs-password-control
      [rsPasswordControl]="resetPasswordForm.controls.password"
      placeholder="{{'placeholders.new-password' | translate}}"
      [passwordStrength]="true"
      (enterPressed)="resetPassword()"
    ></rs-password-control>
    <rs-password-control
      [rsPasswordControl]="resetPasswordForm.controls.confirm"
      placeholder="{{'placeholders.confirm-new-password' | translate}}"
      (enterPressed)="resetPassword()"
    ></rs-password-control>
  </div>
  <div class="rs-reset-password__action">
    <button [disabled]="resetPasswordForm.invalid" mat-raised-button color="primary" class="app__button" (click)="resetPassword()">{{'reset-password.reset' | translate}}</button>
  </div>
</div>
<rs-footer></rs-footer>
