import countries from '../../../assets/files/countries.json';

export class Address {

  public address1: string;
  public address2: string;
  public city: string;
  public country: string;
  public state: string;
  public zipcode: string;

  constructor(address: Address) {
    const country = countries.find(currentCountry => currentCountry.name === address.country);

    this.address1 = address.address1;
    this.address2 = address.address2;
    this.city = address.city;
    this.country = country ? country.code3 : '';
    this.state = country ? `${country.code2}-${address.state}` : '';
    this.zipcode = address.zipcode;
  }

}
