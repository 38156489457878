import { NgModule } from '@angular/core';

import { SharedModule } from '../../modules/shared.module';
import { HintComponent } from './hint.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    HintComponent
  ],
  exports: [
    HintComponent
  ]
})
export class HintModule {}
