<div class="rs-need-any-help" [class.rs-need-any-help_expanded]="needAnyHelpVisibility">
  <div class="rs-need-any-help__header" matRipple matRippleColor="#00a7ef10" (click)="toggleNeedAnyHelpVisibility()">
    <div class="rs-need-any-help__header-icon">
      <img src="assets/img/rockspoon-icon.png">
    </div>
    <div class="rs-need-any-help__header-title">{{'need-any-help.title' | translate}}</div>
    <div class="rs-need-any-help__header-arrow">
      <mat-icon>expand_less</mat-icon>
    </div>
  </div>
  <div class="rs-need-any-help__content" *ngIf="needAnyHelpVisibility" @height>
    <div class="rs-need-any-help__item">{{'need-any-help.why-do-i-need' | translate}}</div>
    <div class="rs-need-any-help__item">{{'need-any-help.why-rockspoon-needs' | translate}}</div>
    <div class="rs-need-any-help__item">{{'need-any-help.need-call' | translate}}</div>
  </div>
</div>
