import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'rs-navigation-buttons',
  templateUrl: 'navigation-buttons.component.html',
  styleUrls: ['navigation-buttons.component.scss']
})
export class NavigationButtonsComponent {

  @Output() clickNext = new EventEmitter<void>();
  @Output() clickDashboard = new EventEmitter<void>();

  @Input() dashboardOnly: boolean;
  @Input() isNextDisabled: boolean;

  public reactOnClickNext(): void {
    this.clickNext.emit();
  }

  public reactOnDashboardClick(): void {
    this.clickDashboard.emit();
  }

}
