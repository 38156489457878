import { Injectable, Injector, NgZone } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
​
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
​
import { SetSpinnerVisibility } from '../../root/ngxs/root.actions';
import { Logout } from '../ngxs/authentication/authentication.actions';
import { NotificationService } from '../services/notification.service';
import { ERROR_CODES, ERROR_STATUSES } from '../constants/errors';
import { getErrorContentByCode, getErrorTitleByCode } from '../helpers';
import { RouterService } from '../services/router.service';
import { SharedDataService } from '../services/shared-data.service';
import { PAGES } from '../constants/pages';
import { DIALOG_SIZES } from '../constants/common';
import { ProfileNotActiveComponent } from '../components/popups/profile-not-active/profile-not-active.component';
​
@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
​
  private store: Store;
  private notificationService: NotificationService;
  private sharedDataService: SharedDataService;
  private routerService: RouterService;
  private dialog: MatDialog;
  private ngZone: NgZone;
​
  constructor(
    private injector: Injector
  ) {}
​
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.store = this.injector.get(Store);
    this.notificationService = this.injector.get(NotificationService);
    this.sharedDataService = this.injector.get(SharedDataService);
    this.routerService = this.injector.get(RouterService);
    this.dialog = this.injector.get(MatDialog);
    this.ngZone = this.injector.get(NgZone);
​
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse, caught: Observable<HttpEvent<any>>) => {
          this.store.dispatch(new SetSpinnerVisibility(false));
​
          if (error.status === ERROR_STATUSES.UNAUTHORIZED && !error.error.code) {
            this.store.dispatch(new Logout());
          } else
          if (error.error.code === ERROR_CODES.PROFILE_IS_NOT_ACTIVE) {
            this.openProfileNotActivePopup();
          } else
          if (error.error.code === ERROR_CODES.PROFILE_IS_ALREADY_ACTIVATED) {
            this.store.dispatch(new Logout());
          } else
          if (error.error.code === ERROR_CODES.DOCUMENT_IS_NOT_SIGNED) {
          } else {
            this.notificationService.showErrorMessage(
              this.injector.get(TranslateService).instant(getErrorTitleByCode(error.error.code) || 'ERROR'),
              this.injector.get(TranslateService).instant(getErrorContentByCode(error.error) || 'ERROR')
            );
          }
​
          throw error;
        })
      );
  }
​
  private openProfileNotActivePopup(): void {
    this.ngZone.run(() => {
      this.dialog.open(ProfileNotActiveComponent, {
        width: DIALOG_SIZES.SMALL
      });
    });
  }
​
}
