<div class="rs-resume-banking-information">
  <div class="app__resume-row">
    <div class="app__resume-row-item">
      <div class="app__resume-row-key">{{'placeholders.account-type' | translate}}*</div>
      <div class="app__resume-row-value">{{cachedBankingInformation.accountType}}</div>
    </div>
    <div class="app__resume-row-item">
      <div class="app__resume-row-key">{{'placeholders.routing-number' | translate}}*</div>
      <div class="app__resume-row-value">{{cachedBankingInformation.routingNumber}}</div>
    </div>
    <div class="app__resume-row-item">
      <div class="app__resume-row-key">{{'placeholders.account-number' | translate}}*</div>
      <div class="app__resume-row-value">{{cachedBankingInformation.accountNumber}}</div>
    </div>
  </div>
</div>
