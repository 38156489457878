import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SimpleNotificationsModule } from 'angular2-notifications';

import { MainModulesModule } from 'src/modules/main-modules.module';
import { PluginsModule } from 'src/modules/plugins.module';
import { ErrorHandlerInterceptor } from 'src/modules/shared/interceptors/error-handler.interceptor';
import { MaterialModule } from 'src/modules/shared/modules/material.module';
import { NeedAnyHelpComponent } from '../components/need-any-help/need-any-help.component';
import { RootComponent } from '../components/root/root.component';
import { SpinnerComponent } from '../components/spinner/spinner.component';
import { RootRoutingModule } from './root-routing.module';
import { AccessTokenInterceptor } from 'src/modules/shared/interceptors/access-token.interceptor';
import { ClientInterceptor } from 'src/modules/shared/interceptors/client.interceptor';
import { ProfileActivationModule } from 'src/modules/shared/components/profile-activation/profile-activation.module';
import { ProfileNotActiveModule } from 'src/modules/shared/components/popups/profile-not-active/profile-not-active.module';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    PluginsModule,
    RootRoutingModule,
    MainModulesModule,
    MaterialModule,
    SimpleNotificationsModule,
    NgbModule,
    ProfileActivationModule,
    ProfileNotActiveModule,
  ],
  declarations: [
    RootComponent,
    NeedAnyHelpComponent,
    SpinnerComponent
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AccessTokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ClientInterceptor, multi: true}
  ],
  bootstrap: [
    RootComponent
  ]
})
export class RootModule {}
