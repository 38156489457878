<div class="rs-set-up-guide">
  <mat-carousel
    timings="250ms ease-in"
    [autoplay]="false"
    slides="5"
    [loop]="false"
    [hideArrows]="true"
    [hideIndicators]="false"
    #matCarousel
  >
    <mat-carousel-slide #matCarouselSlide>
      <rs-turn-on-router></rs-turn-on-router>
    </mat-carousel-slide>
    <mat-carousel-slide #matCarouselSlide>
      <rs-connect-isp></rs-connect-isp>
    </mat-carousel-slide>
    <mat-carousel-slide #matCarouselSlide>
      <rs-turn-on-mobile></rs-turn-on-mobile>
    </mat-carousel-slide>
    <mat-carousel-slide #matCarouselSlide>
      <rs-click-app></rs-click-app>
    </mat-carousel-slide>
    <mat-carousel-slide #matCarouselSlide>
      <rs-qr-code></rs-qr-code>
    </mat-carousel-slide>
  </mat-carousel>
</div>
