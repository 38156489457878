import { NgModule } from '@angular/core';

import { MaterialModule } from '../../modules/material.module';
import { SharedModule } from '../../modules/shared.module';
import { PipesModule } from '../../pipes/pipes.module';
import { DeleteOwnerPopupModule } from '../popups/delete-owner/delete-owner.module';
import { OwnerComponent } from './owner.component';

@NgModule({
  imports: [
    SharedModule,
    MaterialModule,
    PipesModule,
    DeleteOwnerPopupModule
  ],
  declarations: [
    OwnerComponent
  ],
  exports: [
    OwnerComponent
  ]
})
export class OwnerModule {}
