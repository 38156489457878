import { Component, QueryList, ViewChildren } from '@angular/core';

import { MatCarouselSlideComponent } from '@ngbmodule/material-carousel';

@Component({
  selector: 'rs-set-up-guide',
  templateUrl: 'set-up-guide.component.html',
  styleUrls: ['set-up-guide.component.scss']
})
export class SetUpGuideComponent {

  @ViewChildren(MatCarouselSlideComponent) public carouselSlides: QueryList<MatCarouselSlideComponent>;

}
