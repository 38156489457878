import { Component, Input } from '@angular/core';

@Component({
  selector: 'rs-hint',
  templateUrl: 'hint.component.html',
  styleUrls: ['hint.component.scss']
})
export class HintComponent {

  @Input() content: string;

}
