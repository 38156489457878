import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { ResetPassword } from 'src/modules/shared/ngxs/authentication/authentication.actions';
import { REGEX } from 'src/modules/shared/regular-expressions';

@Component({
  selector: 'rs-forgot-password',
  templateUrl: 'forgot-password.component.html',
  styleUrls: ['forgot-password.component.scss']
})
export class ForgotPasswordComponent {

  public emailControl = new FormControl('', [Validators.required, Validators.email, Validators.pattern(REGEX.EMAIL)]);

  constructor(
    private store: Store
  ) {}

  public sendEmail(): void {
    if (this.emailControl.valid) {
      this.store.dispatch(new ResetPassword(this.emailControl.value));
    }
  }

}
