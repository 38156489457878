import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MaterialModule } from '../../modules/material.module';
import { SharedModule } from '../../modules/shared.module';
import { NavigationButtonsComponent } from './navigation-buttons.component';

@NgModule({
  imports: [
    SharedModule,
    MaterialModule,
    RouterModule
  ],
  declarations: [
    NavigationButtonsComponent
  ],
  exports: [
    NavigationButtonsComponent
  ]
})
export class NavigationButtonsModule {}
