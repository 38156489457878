import { Injectable } from '@angular/core';

import { ENDPOINTS } from '../shared/constants/endpoints';
import { BankingInformation } from '../shared/models/banking-information.model';
import { ApiService } from '../shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class BankingInformationService {

  constructor(
    private apiService: ApiService
  ) {}

  public updateBankingInformation(businessEntityId: string, bankingInformation: BankingInformation) {
    return this.apiService.put(ENDPOINTS.BANKING_INFORMATION(businessEntityId), bankingInformation);
  }

}
