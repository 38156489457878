import { NgModule } from '@angular/core';

import { NgxsModule } from '@ngxs/store';

import { AddressFormModule } from '../shared/components/address-form/address-form.module';
import { ControlWithMaskModule } from '../shared/components/control-with-mask/control-with-mask.module';
import { NavigationButtonsModule } from '../shared/components/navigation-buttons/navigation-buttons.module';
import { MaterialModule } from '../shared/modules/material.module';
import { SharedModule } from '../shared/modules/shared.module';
import { LegalEntityInformationComponent } from './component/legal-entity-information.component';
import { LegalEntityInformationState } from './ngxs/legal-entity-information.state';

@NgModule({
  imports: [
    SharedModule,
    NavigationButtonsModule,
    MaterialModule,
    AddressFormModule,
    ControlWithMaskModule,
    NgxsModule.forFeature([LegalEntityInformationState])
  ],
  declarations: [
    LegalEntityInformationComponent
  ],
  exports: [
    LegalEntityInformationComponent
  ]
})
export class LegalEntityInformationModule {}
