import { Injectable } from '@angular/core';

import { ENDPOINTS } from '../shared/constants/endpoints';
import { CompanyOwner } from '../shared/models/company-owner.model';
import { IndividualOwner } from '../shared/models/individual-owner.model';
import { ApiService } from '../shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class OwnersService {

  constructor(
    private apiService: ApiService
  ) {}

  public updateIndividual(businessEntityId: string, individuals: IndividualOwner[]) {
    return this.apiService.put(ENDPOINTS.INDIVIDUALS(businessEntityId), individuals);
  }

  public updateCompany(businessEntityId: string, companies: CompanyOwner[]) {
    return this.apiService.put(ENDPOINTS.COMPANIES(businessEntityId), companies);
  }

}
