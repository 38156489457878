import { getValidProneNumber } from '../helpers';
import { Address } from './address.model';
import { CompanyData } from './forms/company.model';

export class CompanyOwner {

  public businessName: string;
  public email: string;
  public firstName: string;
  public lastName: string;
  public hubSpotContactID: string;
  public phone: string;
  public share: number;
  public tin: string;
  public address: Address;

  constructor(companyData: CompanyData) {
    this.businessName = companyData.businessName;
    this.email = companyData.email;
    this.firstName = companyData.firstName;
    this.lastName = companyData.lastName;
    this.hubSpotContactID = '';
    this.phone = getValidProneNumber(companyData.phone);
    this.share = Number(companyData.share);
    this.tin = companyData.tin;
    this.address = new Address(companyData.address);
  }

}
