import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ProfileActivationComponent } from 'src/modules/shared/components/profile-activation/profile-activation.component';
import { NotAuthenticationGuard } from 'src/modules/shared/guards/not-auth.guard';
import { ForgotPasswordComponent } from 'src/modules/sign-in/components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from 'src/modules/sign-in/components/reset-password/reset-password.component';
import { SignInComponent } from 'src/modules/sign-in/components/sign-in/sign-in.component';
import { ActivateYourAccountComponent } from 'src/modules/sign-up/components/activate-your-account/activate-your-account.component';
import { SignUpComponent } from 'src/modules/sign-up/components/sign-up/sign-up.component';
import { SetUpHomeComponent } from 'src/modules/set-up-devices/components/set-up-home/set-up-home.component';
import { EmailComponent } from 'src/modules/email/component/email.component';

@NgModule({
  imports: [
    RouterModule.forRoot([
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
      { path: 'sign-in', component: SignInComponent, canActivate: [NotAuthenticationGuard] },
      { path: 'sign-up', component: SignUpComponent, canActivate: [NotAuthenticationGuard]  },
      { path: 'activate-account', component: ActivateYourAccountComponent, canActivate: [NotAuthenticationGuard]  },
      { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [NotAuthenticationGuard]  },
      { path: 'resetPassword', component: ResetPasswordComponent, canActivate: [NotAuthenticationGuard]  },
      { path: 'join/validate', component: ProfileActivationComponent },
      { path: 'devices', component: SetUpHomeComponent },
      { path: 'email', component: EmailComponent }
    ])
  ],
  exports: [
    RouterModule
  ]
})
export class RootRoutingModule {}
