import { Injectable, NgZone } from '@angular/core';

import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Action, State, StateContext, Store } from '@ngxs/store';

import { MatDialog } from '@angular/material/dialog';

import { Spinner } from 'src/modules/shared/classes/spinner.class';
import { ShareWarningPopupComponent } from 'src/modules/shared/components/popups/show-share-warning/share-warning.component';
import { DIALOG_SIZES } from 'src/modules/shared/constants/common';
import { OwnerTypes } from 'src/modules/shared/enums';
import { CompanyOwner } from 'src/modules/shared/models/company-owner.model';
import { CompanyData } from 'src/modules/shared/models/forms/company.model';
import { IndividualData } from 'src/modules/shared/models/forms/individual.model';
import { IndividualOwner } from 'src/modules/shared/models/individual-owner.model';
import { SharedDataService } from 'src/modules/shared/services/shared-data.service';
import { OwnersService } from '../owners.service';
import { AddOwner, RemoveNewOwner, UpdateOwnersInformation } from './owners.actions';

interface OwnersStateModel {
  newOwner: IndividualData | CompanyData;
}

@State<OwnersStateModel>({
  name: 'owners',
  defaults: {
    newOwner: null
  }
})
@Injectable()
export class OwnersState extends Spinner {

  constructor(
    private ownersService: OwnersService,
    private sharedDataService: SharedDataService,
    protected store: Store,
    private dialog: MatDialog,
    private ngZone: NgZone
  ) {
    super(store);
  }

  @Action(UpdateOwnersInformation)
  updateOwnersInformation() {
    this.showSpinner();

    return forkJoin([
      this.ownersService
        .updateCompany(this.sharedDataService.businessEntityId, (this.sharedDataService.companyOwners || [])
        .map(companyOwner => new CompanyOwner(companyOwner))),
      this.ownersService
        .updateIndividual(this.sharedDataService.businessEntityId, (this.sharedDataService.individualOwners || [])
        .map(individualOwner => new IndividualOwner(individualOwner)))
    ])
      .pipe(
        tap({
          complete: () => this.hideSpinner()
        })
      );
  }

  @Action(AddOwner)
  addOwner({ patchState }: StateContext<OwnersStateModel>, {ownerType, owner}) {
    const individualOwners =  this.sharedDataService.individualOwners || [];
    const companyOwners = this.sharedDataService.companyOwners || [];
    const owners = [owner, ...individualOwners, ...companyOwners];
    const overallShare = owners.reduce((acc, {share}) => acc + (+share), 0);

    if (overallShare > 100 ) {
      this.showShareErrorPopup();
    } else {
      ownerType === OwnerTypes.INDIVIDUAL ?
        this.sharedDataService.addOwner<IndividualData>(owner, true) :
        this.sharedDataService.addOwner<CompanyData>(owner, false);

      patchState({
        newOwner: owner
      });
    }

  }

  @Action(RemoveNewOwner)
  removeNewOwner({ patchState }: StateContext<OwnersStateModel>) {
    patchState({
      newOwner: null
    });
  }

  private showShareErrorPopup(): void {
    this.ngZone.run(() => {
      this.dialog.open(ShareWarningPopupComponent, {
        width: DIALOG_SIZES.MIDDLE
      });
    });
  }

}
