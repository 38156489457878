import { Injectable } from '@angular/core';

import { Action, State, Store } from '@ngxs/store';
import { switchMap } from 'rxjs/operators';

import { Spinner } from 'src/modules/shared/classes/spinner.class';
import { IdResponse } from 'src/modules/shared/models/id-response.model';
import { RefreshSession } from 'src/modules/shared/ngxs/authentication/authentication.actions';
import { SharedDataService } from 'src/modules/shared/services/shared-data.service';
import { LegalEntityInformationService } from '../legal-entity-information.service';
import { SaveLegalEntityInformation } from './legal-entity-information.actions';

@State({
  name: 'legalEntityInformation'
})
@Injectable()
export class LegalEntityInformationState extends Spinner {

  constructor(
    private sharedDataService: SharedDataService,
    private legalEntityInformationService: LegalEntityInformationService,
    protected store: Store
  ) {
    super(store);
  }

  @Action(SaveLegalEntityInformation)
  saveLegalEntityInformation(stateContext, {businessEntity}: SaveLegalEntityInformation) {
    this.showSpinner();

    const action = this.sharedDataService.businessEntityId ?
      this.legalEntityInformationService.updateBusinessEntity(businessEntity, this.sharedDataService.businessEntityId) :
      this.legalEntityInformationService.createBusinessEntity(businessEntity);

    return action
      .pipe(
        switchMap((idResponse: IdResponse) => {
          if (idResponse && idResponse.id) {
            this.sharedDataService.businessEntityId = idResponse.id;
          }
          this.hideSpinner();

          return this.store.dispatch(new RefreshSession());
        })
      );
  }

}
