import { Component, OnInit } from '@angular/core';

import { CompanyData } from 'src/modules/shared/models/forms/company.model';
import { IndividualData } from 'src/modules/shared/models/forms/individual.model';
import { SharedDataService } from 'src/modules/shared/services/shared-data.service';

@Component({
  selector: 'rs-resume-owners-information',
  templateUrl: 'resume-owners-information.component.html',
  styleUrls: ['resume-owners-information.component.scss']
})
export class ResumeOwnersInformationComponent implements OnInit {

  public cachedIndividuals: IndividualData[];
  public cachedCompanies: CompanyData[];

  constructor(
    private sharedDataService: SharedDataService
  ) {}

  ngOnInit() {
    this.getCachedPrincipals();
  }

  private getCachedPrincipals(): void {
    this.cachedCompanies = this.sharedDataService.companyOwners;
    this.cachedIndividuals = this.sharedDataService.individualOwners;
  }

}
